import {IMAGE_BASE_URL} from "@frontend/env"

export const getImageUrl = (url: string): string => {
  try {
    new URL(url)
    return url
  } catch {
    if (!url.startsWith("/")) {
      throw new Error("Invalid image url")
    }

    return `${IMAGE_BASE_URL}${url}`
  }
}
