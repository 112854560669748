import {FC} from "react"

import {css, cx} from "@styled-system/css"

interface Props {
  title: string
  onClick: () => void
  className?: string
}

export const TextChip: FC<Props> = ({title, onClick, className}) => (
  <button
    onClick={onClick}
    className={cx(
      css({
        overflow: "hidden",
        backgroundColor: "white",
        color: "fontBlack",
        textStyle: "button2",
        borderRadius: 32,
        borderWidth: 1,
        borderColor: "lineGrey",
        cursor: "pointer",
        paddingX: {base: 16, desktop: 24},
        paddingY: {base: 8, desktop: 16},
      }),
      className,
    )}
  >
    {title}
  </button>
)
