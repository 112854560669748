import {FC, useEffect, useRef} from "react"

import marker from "@frontend/design/icons/marker.svg"
import {css} from "@styled-system/css"

const silverStyle = [
  // Land color
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#fbfbfb",
      },
    ],
  },
  // Water color
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#eaeaea",
      },
    ],
  },
  // Division between countries
  {
    featureType: "administrative.country",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#5a5a5a",
      },
    ],
  },
  // Division between states
  {
    featureType: "administrative.province",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#a0a0a0",
      },
      {
        weight: 1,
      },
    ],
  },
  // Main roads color
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#f2f2f2",
      },
    ],
  },
  // Secondary roads color
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  // Color of street names for all roads
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#d3d3d3", // Street names color updated to #d3d3d3
      },
    ],
  },
  // Adjust local roads color
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
]

export const Map: FC<{
  zipCode: string
  zoom: number
  setResults: (value: google.maps.places.PlaceResult[]) => void
}> = ({zipCode, zoom, setResults}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const map = new window.google.maps.Map(ref.current!, {
      zoom,
      center: {lat: 42.826, lng: -96.522},
      styles: silverStyle,
      mapTypeControl: false,
      fullscreenControl: false,
    })
    if (zipCode) {
      searchByZipCode(map)(zipCode)
        .then(searchByLocation(map))
        .then(addMarkers(map))
        .then(addPlaceDetails(map))
        .then((results) => setResults(results))
    }
  }, [ref, setResults, zipCode, zoom])

  return (
    <div
      ref={ref}
      id="map"
      className={css({height: "100%", minWidth: "100%", borderRadius: 12})}
    />
  )
}

const searchByZipCode =
  (map: google.maps.Map) =>
  (zipCode: string): Promise<google.maps.LatLng> => {
    const geocoder = new google.maps.Geocoder()

    return new Promise((resolve, reject) => {
      geocoder.geocode({address: zipCode}, (results, status) => {
        if (status === "OK") {
          const location = results?.[0].geometry.location
          if (location) {
            map.setCenter(location)
            resolve(location)
          } else {
            reject("location not found")
          }
        } else {
          reject("geocoder failed")
        }
      })
    })
  }

const searchByLocation =
  (map: google.maps.Map) =>
  (location: google.maps.LatLng): Promise<google.maps.places.PlaceResult[]> => {
    const service = new google.maps.places.PlacesService(map)
    const request: google.maps.places.PlaceSearchRequest = {
      location,
      radius: 10000,
      keyword: "appliance repair",
    }

    return new Promise((resolve) => {
      service.nearbySearch(request, (results, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
          resolve(results)
        } else {
          resolve([])
        }
      })
    })
  }

const addPlaceDetails =
  (map: google.maps.Map) =>
  (
    results: google.maps.places.PlaceResult[],
  ): Promise<google.maps.places.PlaceResult[]> =>
    Promise.all(
      results.map((place) => {
        const service = new google.maps.places.PlacesService(map)
        return new Promise<google.maps.places.PlaceResult>((resolve) => {
          const placeId = place.place_id
          if (placeId) {
            service.getDetails({placeId}, (details) => {
              resolve({...details})
            })
          } else {
            resolve({...place})
          }
        })
      }),
    )
const addMarkers =
  (map: google.maps.Map) =>
  (
    results: google.maps.places.PlaceResult[],
  ): google.maps.places.PlaceResult[] => {
    for (let i = 0; i < results.length; i++) {
      createMarker(results[i], map)
    }
    return results
  }

const createMarker = (
  place: google.maps.places.PlaceResult,
  map: google.maps.Map,
): void => {
  new google.maps.Marker({
    map,
    position: place?.geometry?.location,
    icon: marker,
  })
}
