import {
  addDays as dateFnsAddDays,
  differenceInCalendarDays as dateFnsDifferenceInCalendarDays,
  differenceInYears as dateFnsDifferenceInYears,
} from "date-fns"

export interface CalendarDate {
  year: number
  month: number
  day: number
}

export const calendarDateNow = (): CalendarDate =>
  calendarDateFromDbDate(new Date())

export const calendarDateFromDbDate = (date: Date): CalendarDate => ({
  year: date.getUTCFullYear(),
  month: date.getUTCMonth() + 1,
  day: date.getUTCDate(),
})

export const dateFromCalendarDate = (date: CalendarDate): Date =>
  new Date(date.year, date.month - 1, date.day)

export const differenceInCalendarDays = (
  date1: CalendarDate,
  date2: CalendarDate,
): number => {
  const date1Date = dateFromCalendarDate(date1)
  const date2Date = dateFromCalendarDate(date2)

  return dateFnsDifferenceInCalendarDays(date1Date, date2Date)
}

export const differenceInYears = (
  date1: CalendarDate,
  date2: CalendarDate,
): number => {
  const date1Date = dateFromCalendarDate(date1)
  const date2Date = dateFromCalendarDate(date2)

  return dateFnsDifferenceInYears(date1Date, date2Date)
}

export const addDays = (date: CalendarDate, days: number): CalendarDate =>
  calendarDateFromDbDate(dateFnsAddDays(dateFromCalendarDate(date), days))
