import {FC, PropsWithChildren, ReactNode} from "react"

import {Styles, css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {BaseModal} from "./base-modal"
import {CloseButton} from "./close-button"

interface Props {
  title: string | ReactNode
  subtitle?: string | ReactNode
  message?: string | ReactNode
  show: boolean
  onDismiss: () => void
  width?: number
  icon?: ReactNode
  containerCss?: Styles
  bottomSheet?: boolean
}

export const Modal: FC<PropsWithChildren<Props>> = ({
  title,
  subtitle,
  message,
  show,
  onDismiss,
  width,
  icon,
  bottomSheet,
  children,
  containerCss,
}) => (
  <BaseModal
    show={show}
    width={width}
    bottomSheet={bottomSheet}
    onDismiss={onDismiss}
    containerCss={containerCss}
  >
    <div
      className={vstack({
        alignItems: "stretch",
        gap: 24,
      })}
    >
      <div
        className={hstack({
          justifyContent: "space-between",
          gap: {
            base: "modalPaddingMobile",
            desktop: "modalPaddingDesktop",
          },
          paddingX: {
            base: "modalPaddingMobile",
            desktop: "modalPaddingDesktop",
          },
          alignItems: "flex-start",
        })}
      >
        <div>
          <div
            className={css({
              display: "flex",
              gap: 8,
              alignItems: "center",
            })}
          >
            {icon}
            <h5
              className={css({
                textStyle: "bodyLargeMedium",
                color: "text.primary",
              })}
            >
              {title}
            </h5>
          </div>
          {subtitle && (
            <div
              className={css({
                textStyle: "caption",
                color: "fontGrey",
                desktop: {
                  textStyle: "body",
                },
              })}
            >
              {subtitle}
            </div>
          )}
        </div>
        <CloseButton onClick={onDismiss} />
      </div>
      {message && (
        <div
          className={css({
            color: "fontGrey",
            textStyle: "body",
            paddingX: {
              base: "modalPaddingMobile",
              desktop: "modalPaddingDesktop",
            },
          })}
        >
          {message}
        </div>
      )}
    </div>
    {children && (
      <div
        className={vstack({
          gap: {
            base: "modalPaddingMobile",
            desktop: "modalPaddingDesktop",
          },
          paddingX: {
            base: "modalPaddingMobile",
            desktop: "modalPaddingDesktop",
          },
          alignItems: "stretch",
        })}
      >
        {children}
      </div>
    )}
  </BaseModal>
)
