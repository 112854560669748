const tokens = {
  "colors.bgBlue": {
    "value": "#E7F0FB",
    "variable": "var(--colors-bg-blue)"
  },
  "colors.bgGreen": {
    "value": "#E5F6EB",
    "variable": "var(--colors-bg-green)"
  },
  "colors.bgRed": {
    "value": "#FFE5E8",
    "variable": "var(--colors-bg-red)"
  },
  "colors.bgYellow": {
    "value": "#FDF3DD",
    "variable": "var(--colors-bg-yellow)"
  },
  "colors.fontBlack": {
    "value": "#000000",
    "variable": "var(--colors-font-black)"
  },
  "colors.fontGreen": {
    "value": "#23B558",
    "variable": "var(--colors-font-green)"
  },
  "colors.fontGrey": {
    "value": "#757575",
    "variable": "var(--colors-font-grey)"
  },
  "colors.fontRed": {
    "value": "rgb(207, 39, 39)",
    "variable": "var(--colors-font-red)"
  },
  "colors.fontLightRed": {
    "value": "#FF4F68",
    "variable": "var(--colors-font-light-red)"
  },
  "colors.blue": {
    "value": "#0041C2",
    "variable": "var(--colors-blue)"
  },
  "colors.darkGrey": {
    "value": "rgb(200, 200, 200)",
    "variable": "var(--colors-dark-grey)"
  },
  "colors.green": {
    "value": "#0A8035",
    "variable": "var(--colors-green)"
  },
  "colors.grey": {
    "value": "#F0F0F0",
    "variable": "var(--colors-grey)"
  },
  "colors.lightBlue": {
    "value": "#126CD6",
    "variable": "var(--colors-light-blue)"
  },
  "colors.lightGrey": {
    "value": "rgb(248, 248, 248)",
    "variable": "var(--colors-light-grey)"
  },
  "colors.lineGrey": {
    "value": "#E6E6E6",
    "variable": "var(--colors-line-grey)"
  },
  "colors.turquoise": {
    "value": "rgb(0, 163, 224)",
    "variable": "var(--colors-turquoise)"
  },
  "colors.white": {
    "value": "#FFFFFF",
    "variable": "var(--colors-white)"
  },
  "colors.darkBlue": {
    "value": "#082065",
    "variable": "var(--colors-dark-blue)"
  },
  "colors.royalBlue": {
    "value": "#1740BB",
    "variable": "var(--colors-royal-blue)"
  },
  "colors.lightSkyBlue": {
    "value": "#C0D2EB",
    "variable": "var(--colors-light-sky-blue)"
  },
  "colors.slateGray": {
    "value": "#595C60",
    "variable": "var(--colors-slate-gray)"
  },
  "colors.transparentWhite": {
    "value": "rgba(255, 255, 255, 0.2)",
    "variable": "var(--colors-transparent-white)"
  },
  "colors.lightBlack": {
    "value": "#0C111D",
    "variable": "var(--colors-light-black)"
  },
  "colors.neutral.50": {
    "value": "#fafafa",
    "variable": "var(--colors-neutral-50)"
  },
  "colors.neutral.100": {
    "value": "#f4f5f6",
    "variable": "var(--colors-neutral-100)"
  },
  "colors.neutral.200": {
    "value": "#e9eaec",
    "variable": "var(--colors-neutral-200)"
  },
  "colors.neutral.300": {
    "value": "#cbcdd0",
    "variable": "var(--colors-neutral-300)"
  },
  "colors.neutral.400": {
    "value": "#a5a8ab",
    "variable": "var(--colors-neutral-400)"
  },
  "colors.neutral.500": {
    "value": "#717174",
    "variable": "var(--colors-neutral-500)"
  },
  "colors.neutral.600": {
    "value": "#4e4f56",
    "variable": "var(--colors-neutral-600)"
  },
  "colors.neutral.700": {
    "value": "#2e2f33",
    "variable": "var(--colors-neutral-700)"
  },
  "colors.neutral.800": {
    "value": "#27272a",
    "variable": "var(--colors-neutral-800)"
  },
  "colors.neutral.900": {
    "value": "#1f1f22",
    "variable": "var(--colors-neutral-900)"
  },
  "colors.neutral.950": {
    "value": "#161618",
    "variable": "var(--colors-neutral-950)"
  },
  "colors.brandAccent.50": {
    "value": "#feece4",
    "variable": "var(--colors-brand-accent-50)"
  },
  "colors.brandAccent.100": {
    "value": "#fce0d3",
    "variable": "var(--colors-brand-accent-100)"
  },
  "colors.brandAccent.200": {
    "value": "#eec4b1",
    "variable": "var(--colors-brand-accent-200)"
  },
  "colors.brandAccent.300": {
    "value": "#e7a688",
    "variable": "var(--colors-brand-accent-300)"
  },
  "colors.brandAccent.400": {
    "value": "#ea8d66",
    "variable": "var(--colors-brand-accent-400)"
  },
  "colors.brandAccent.500": {
    "value": "#f26e3a",
    "variable": "var(--colors-brand-accent-500)"
  },
  "colors.brandAccent.600": {
    "value": "#ff4902",
    "variable": "var(--colors-brand-accent-600)"
  },
  "colors.brandAccent.700": {
    "value": "#d64105",
    "variable": "var(--colors-brand-accent-700)"
  },
  "colors.brandAccent.800": {
    "value": "#852a05",
    "variable": "var(--colors-brand-accent-800)"
  },
  "colors.brandAccent.900": {
    "value": "#431a0a",
    "variable": "var(--colors-brand-accent-900)"
  },
  "colors.brandAccent.950": {
    "value": "#2b1208",
    "variable": "var(--colors-brand-accent-950)"
  },
  "colors.base.black": {
    "value": "#000000",
    "variable": "var(--colors-base-black)"
  },
  "colors.base.white": {
    "value": "#ffffff",
    "variable": "var(--colors-base-white)"
  },
  "colors.brand.50": {
    "value": "#e9f5f9",
    "variable": "var(--colors-brand-50)"
  },
  "colors.brand.100": {
    "value": "#d6e6eb",
    "variable": "var(--colors-brand-100)"
  },
  "colors.brand.200": {
    "value": "#baced4",
    "variable": "var(--colors-brand-200)"
  },
  "colors.brand.300": {
    "value": "#9eb2b8",
    "variable": "var(--colors-brand-300)"
  },
  "colors.brand.400": {
    "value": "#79939a",
    "variable": "var(--colors-brand-400)"
  },
  "colors.brand.500": {
    "value": "#537179",
    "variable": "var(--colors-brand-500)"
  },
  "colors.brand.600": {
    "value": "#3a5b64",
    "variable": "var(--colors-brand-600)"
  },
  "colors.brand.700": {
    "value": "#264750",
    "variable": "var(--colors-brand-700)"
  },
  "colors.brand.800": {
    "value": "#17333a",
    "variable": "var(--colors-brand-800)"
  },
  "colors.brand.900": {
    "value": "#0f2429",
    "variable": "var(--colors-brand-900)"
  },
  "colors.brand.950": {
    "value": "#09171a",
    "variable": "var(--colors-brand-950)"
  },
  "colors.steelFog.50": {
    "value": "#161618",
    "variable": "var(--colors-steel-fog-50)"
  },
  "colors.steelFog.100": {
    "value": "#d1d4d1",
    "variable": "var(--colors-steel-fog-100)"
  },
  "colors.steelFog.200": {
    "value": "#bbc0ba",
    "variable": "var(--colors-steel-fog-200)"
  },
  "colors.steelFog.300": {
    "value": "#a5aba4",
    "variable": "var(--colors-steel-fog-300)"
  },
  "colors.steelFog.400": {
    "value": "#8f978e",
    "variable": "var(--colors-steel-fog-400)"
  },
  "colors.steelFog.500": {
    "value": "#707c6e",
    "variable": "var(--colors-steel-fog-500)"
  },
  "colors.steelFog.600": {
    "value": "#546052",
    "variable": "var(--colors-steel-fog-600)"
  },
  "colors.steelFog.700": {
    "value": "#3e483c",
    "variable": "var(--colors-steel-fog-700)"
  },
  "colors.steelFog.800": {
    "value": "#2d352c",
    "variable": "var(--colors-steel-fog-800)"
  },
  "colors.steelFog.900": {
    "value": "#1f241e",
    "variable": "var(--colors-steel-fog-900)"
  },
  "colors.steelFog.950": {
    "value": "#151915",
    "variable": "var(--colors-steel-fog-950)"
  },
  "colors.copperTaupe.50": {
    "value": "#ede9e9",
    "variable": "var(--colors-copper-taupe-50)"
  },
  "colors.copperTaupe.100": {
    "value": "#ddd6d5",
    "variable": "var(--colors-copper-taupe-100)"
  },
  "colors.copperTaupe.200": {
    "value": "#c7bcba",
    "variable": "var(--colors-copper-taupe-200)"
  },
  "colors.copperTaupe.300": {
    "value": "#ac9b99",
    "variable": "var(--colors-copper-taupe-300)"
  },
  "colors.copperTaupe.400": {
    "value": "#917d79",
    "variable": "var(--colors-copper-taupe-400)"
  },
  "colors.copperTaupe.500": {
    "value": "#745d59",
    "variable": "var(--colors-copper-taupe-500)"
  },
  "colors.copperTaupe.600": {
    "value": "#574542",
    "variable": "var(--colors-copper-taupe-600)"
  },
  "colors.copperTaupe.700": {
    "value": "#423533",
    "variable": "var(--colors-copper-taupe-700)"
  },
  "colors.copperTaupe.800": {
    "value": "#312826",
    "variable": "var(--colors-copper-taupe-800)"
  },
  "colors.copperTaupe.900": {
    "value": "#1f1f22",
    "variable": "var(--colors-copper-taupe-900)"
  },
  "colors.copperTaupe.950": {
    "value": "#171312",
    "variable": "var(--colors-copper-taupe-950)"
  },
  "colors.error.50": {
    "value": "#fef0f7",
    "variable": "var(--colors-error-50)"
  },
  "colors.error.100": {
    "value": "#fde3ea",
    "variable": "var(--colors-error-100)"
  },
  "colors.error.200": {
    "value": "#efb4c5",
    "variable": "var(--colors-error-200)"
  },
  "colors.error.300": {
    "value": "#e788a4",
    "variable": "var(--colors-error-300)"
  },
  "colors.error.400": {
    "value": "#ea668e",
    "variable": "var(--colors-error-400)"
  },
  "colors.error.500": {
    "value": "#f3447b",
    "variable": "var(--colors-error-500)"
  },
  "colors.error.600": {
    "value": "#d81354",
    "variable": "var(--colors-error-600)"
  },
  "colors.error.700": {
    "value": "#9d0c37",
    "variable": "var(--colors-error-700)"
  },
  "colors.error.800": {
    "value": "#760527",
    "variable": "var(--colors-error-800)"
  },
  "colors.error.900": {
    "value": "#430a1b",
    "variable": "var(--colors-error-900)"
  },
  "colors.error.950": {
    "value": "#2b0812",
    "variable": "var(--colors-error-950)"
  },
  "colors.yellow.50": {
    "value": "#fff3db",
    "variable": "var(--colors-yellow-50)"
  },
  "colors.yellow.100": {
    "value": "#fdebc9",
    "variable": "var(--colors-yellow-100)"
  },
  "colors.yellow.200": {
    "value": "#f7cf85",
    "variable": "var(--colors-yellow-200)"
  },
  "colors.yellow.300": {
    "value": "#f9bd4d",
    "variable": "var(--colors-yellow-300)"
  },
  "colors.yellow.400": {
    "value": "#e6991e",
    "variable": "var(--colors-yellow-400)"
  },
  "colors.yellow.500": {
    "value": "#d17a00",
    "variable": "var(--colors-yellow-500)"
  },
  "colors.yellow.600": {
    "value": "#a86200",
    "variable": "var(--colors-yellow-600)"
  },
  "colors.yellow.700": {
    "value": "#855200",
    "variable": "var(--colors-yellow-700)"
  },
  "colors.yellow.800": {
    "value": "#663f00",
    "variable": "var(--colors-yellow-800)"
  },
  "colors.yellow.900": {
    "value": "#1e2113",
    "variable": "var(--colors-yellow-900)"
  },
  "colors.yellow.950": {
    "value": "#14160c",
    "variable": "var(--colors-yellow-950)"
  },
  "colors.gray.600": {
    "value": "#344054",
    "variable": "var(--colors-gray-600)"
  },
  "colors.gray.700": {
    "value": "#182230",
    "variable": "var(--colors-gray-700)"
  },
  "zIndex.appBackdrop": {
    "value": 1000,
    "variable": "var(--z-index-app-backdrop)"
  },
  "zIndex.appModal": {
    "value": 1001,
    "variable": "var(--z-index-app-modal)"
  },
  "zIndex.sidebarBackdrop": {
    "value": 51,
    "variable": "var(--z-index-sidebar-backdrop)"
  },
  "zIndex.sidebar": {
    "value": 52,
    "variable": "var(--z-index-sidebar)"
  },
  "zIndex.modalBackdrop": {
    "value": 100,
    "variable": "var(--z-index-modal-backdrop)"
  },
  "zIndex.modal": {
    "value": 101,
    "variable": "var(--z-index-modal)"
  },
  "zIndex.splashScreen": {
    "value": 111,
    "variable": "var(--z-index-splash-screen)"
  },
  "zIndex.mobileMoreButton": {
    "value": 34,
    "variable": "var(--z-index-mobile-more-button)"
  },
  "zIndex.caseListsDisclaimer": {
    "value": 35,
    "variable": "var(--z-index-case-lists-disclaimer)"
  },
  "zIndex.mapSearchInputWrapper": {
    "value": 2,
    "variable": "var(--z-index-map-search-input-wrapper)"
  },
  "zIndex.tooltipPositioner": {
    "value": 100,
    "variable": "var(--z-index-tooltip-positioner)"
  },
  "zIndex.tooltipArrow": {
    "value": -1,
    "variable": "var(--z-index-tooltip-arrow)"
  },
  "zIndex.chatCausesSwitch": {
    "value": 50,
    "variable": "var(--z-index-chat-causes-switch)"
  },
  "zIndex.causes.bg": {
    "value": 1,
    "variable": "var(--z-index-causes-bg)"
  },
  "zIndex.causes.content": {
    "value": 3,
    "variable": "var(--z-index-causes-content)"
  },
  "zIndex.causes.container": {
    "value": 1,
    "variable": "var(--z-index-causes-container)"
  },
  "zIndex.causes.sparkle": {
    "value": 5,
    "variable": "var(--z-index-causes-sparkle)"
  },
  "zIndex.bgPattern.base": {
    "value": 0,
    "variable": "var(--z-index-bg-pattern-base)"
  },
  "zIndex.bgPattern.middle": {
    "value": 1,
    "variable": "var(--z-index-bg-pattern-middle)"
  },
  "zIndex.bgPattern.top": {
    "value": 2,
    "variable": "var(--z-index-bg-pattern-top)"
  },
  "zIndex.remediations.backButton": {
    "value": 1,
    "variable": "var(--z-index-remediations-back-button)"
  },
  "spacing.modalPaddingMobile": {
    "value": "16px",
    "variable": "var(--spacing-modal-padding-mobile)"
  },
  "spacing.modalPaddingDesktop": {
    "value": "32px",
    "variable": "var(--spacing-modal-padding-desktop)"
  },
  "gradients.orangeGradient": {
    "value": "linear-gradient(231.14deg, rgba(255, 73, 2, 0.4) 0%, rgba(250, 250, 250, 0.4) 92.75%)",
    "variable": "var(--gradients-orange-gradient)"
  },
  "gradients.orangeBorderGradient": {
    "value": "linear-gradient(232.29deg, #FF4902 1.1%, #FFFFFF 87.26%)",
    "variable": "var(--gradients-orange-border-gradient)"
  },
  "gradients.whiteLinearGradient": {
    "value": "linear-gradient(180deg, rgba(214, 230, 235, 0) 0%, #D6E6EB 100%)",
    "variable": "var(--gradients-white-linear-gradient)"
  },
  "breakpoints.desktop": {
    "value": "768px",
    "variable": "var(--breakpoints-desktop)"
  },
  "breakpoints.largeDesktop": {
    "value": "1280px",
    "variable": "var(--breakpoints-large-desktop)"
  },
  "sizes.breakpoint-desktop": {
    "value": "768px",
    "variable": "var(--sizes-breakpoint-desktop)"
  },
  "sizes.breakpoint-largeDesktop": {
    "value": "1280px",
    "variable": "var(--sizes-breakpoint-large-desktop)"
  },
  "colors.background.primary": {
    "value": "var(--colors-base-white)",
    "variable": "var(--colors-background-primary)"
  },
  "colors.background.primaryOnSolid": {
    "value": "var(--colors-light-black)",
    "variable": "var(--colors-background-primary-on-solid)"
  },
  "colors.background.secondary": {
    "value": "var(--colors-neutral-100)",
    "variable": "var(--colors-background-secondary)"
  },
  "colors.background.tertiary": {
    "value": "var(--colors-neutral-200)",
    "variable": "var(--colors-background-tertiary)"
  },
  "colors.background.quaternary": {
    "value": "var(--colors-neutral-300)",
    "variable": "var(--colors-background-quaternary)"
  },
  "colors.background.subtle.primary": {
    "value": "var(--colors-neutral-50)",
    "variable": "var(--colors-background-subtle-primary)"
  },
  "colors.background.subtle.brand": {
    "value": "var(--colors-brand-accent-50)",
    "variable": "var(--colors-background-subtle-brand)"
  },
  "colors.background.brand.primary": {
    "value": "var(--colors-brand-100)",
    "variable": "var(--colors-background-brand-primary)"
  },
  "colors.background.brand.primarySubtle": {
    "value": "var(--colors-brand-50)",
    "variable": "var(--colors-background-brand-primary-subtle)"
  },
  "colors.background.brand.secondary": {
    "value": "var(--colors-brand-200)",
    "variable": "var(--colors-background-brand-secondary)"
  },
  "colors.background.brand.solid": {
    "value": "var(--colors-brand-700)",
    "variable": "var(--colors-background-brand-solid)"
  },
  "colors.background.brand.solidSubtle": {
    "value": "var(--colors-brand-600)",
    "variable": "var(--colors-background-brand-solid-subtle)"
  },
  "colors.background.brand.accent": {
    "value": "var(--colors-brand-accent-600)",
    "variable": "var(--colors-background-brand-accent)"
  },
  "colors.background.brand.accentSolid": {
    "value": "var(--colors-brand-accent-600)",
    "variable": "var(--colors-background-brand-accent-solid)"
  },
  "colors.background.brand.accentSolidHover": {
    "value": "var(--colors-brand-accent-700)",
    "variable": "var(--colors-background-brand-accent-solid-hover)"
  },
  "colors.background.brand.utility": {
    "value": "var(--colors-brand-800)",
    "variable": "var(--colors-background-brand-utility)"
  },
  "colors.background.error": {
    "value": "var(--colors-error-100)",
    "variable": "var(--colors-background-error)"
  },
  "colors.background.warn": {
    "value": "var(--colors-yellow-50)",
    "variable": "var(--colors-background-warn)"
  },
  "colors.background.disabled": {
    "value": "var(--colors-neutral-100)",
    "variable": "var(--colors-background-disabled)"
  },
  "colors.background.tagTransparent": {
    "value": "#FFFFFF14",
    "variable": "var(--colors-background-tag-transparent)"
  },
  "colors.foreground.tertiary": {
    "value": "var(--colors-neutral-600)",
    "variable": "var(--colors-foreground-tertiary)"
  },
  "colors.foreground.primaryOnSolid": {
    "value": "var(--colors-base-white)",
    "variable": "var(--colors-foreground-primary-on-solid)"
  },
  "colors.foreground.brand.primary": {
    "value": "var(--colors-brand-700)",
    "variable": "var(--colors-foreground-brand-primary)"
  },
  "colors.foreground.brand.secondary": {
    "value": "var(--colors-brand-600)",
    "variable": "var(--colors-foreground-brand-secondary)"
  },
  "colors.foreground.brand.accent": {
    "value": "var(--colors-brand-accent-700)",
    "variable": "var(--colors-foreground-brand-accent)"
  },
  "colors.foreground.secondaryOnSolid": {
    "value": "var(--colors-brand-300)",
    "variable": "var(--colors-foreground-secondary-on-solid)"
  },
  "colors.foreground.disabled": {
    "value": "var(--colors-neutral-400)",
    "variable": "var(--colors-foreground-disabled)"
  },
  "colors.text.brand.primary": {
    "value": "var(--colors-brand-700)",
    "variable": "var(--colors-text-brand-primary)"
  },
  "colors.text.brand.accent": {
    "value": "var(--colors-brand-accent-700)",
    "variable": "var(--colors-text-brand-accent)"
  },
  "colors.text.brand.secondary": {
    "value": "var(--colors-brand-600)",
    "variable": "var(--colors-text-brand-secondary)"
  },
  "colors.text.warning.primary": {
    "value": "var(--colors-yellow-200)",
    "variable": "var(--colors-text-warning-primary)"
  },
  "colors.text.quaternary": {
    "value": "var(--colors-neutral-500)",
    "variable": "var(--colors-text-quaternary)"
  },
  "colors.text.tertiary": {
    "value": "var(--colors-neutral-600)",
    "variable": "var(--colors-text-tertiary)"
  },
  "colors.text.secondary": {
    "value": "var(--colors-neutral-800)",
    "variable": "var(--colors-text-secondary)"
  },
  "colors.text.secondary.onSolid": {
    "value": "var(--colors-brand-300)",
    "variable": "var(--colors-text-secondary-on-solid)"
  },
  "colors.text.error.primary": {
    "value": "var(--colors-error-600)",
    "variable": "var(--colors-text-error-primary)"
  },
  "colors.text.primary": {
    "value": "var(--colors-base-black)",
    "variable": "var(--colors-text-primary)"
  },
  "colors.text.primary.onSolid": {
    "value": "var(--colors-base-white)",
    "variable": "var(--colors-text-primary-on-solid)"
  },
  "colors.text.disabled": {
    "value": "var(--colors-neutral-400)",
    "variable": "var(--colors-text-disabled)"
  },
  "colors.border.brand.primary": {
    "value": "var(--colors-brand-300)",
    "variable": "var(--colors-border-brand-primary)"
  },
  "colors.border.brand.secondarySolid": {
    "value": "var(--colors-brand-600)",
    "variable": "var(--colors-border-brand-secondary-solid)"
  },
  "colors.border.brand.secondary": {
    "value": "var(--colors-brand-200)",
    "variable": "var(--colors-border-brand-secondary)"
  },
  "colors.border.brand.accent": {
    "value": "var(--colors-brand-accent-600)",
    "variable": "var(--colors-border-brand-accent)"
  },
  "colors.border.secondary.200": {
    "value": "var(--colors-neutral-200)",
    "variable": "var(--colors-border-secondary-200)"
  },
  "colors.border.secondary.solid": {
    "value": "var(--colors-neutral-600)",
    "variable": "var(--colors-border-secondary-solid)"
  },
  "colors.border.primary.300": {
    "value": "var(--colors-neutral-300)",
    "variable": "var(--colors-border-primary-300)"
  },
  "colors.border.error": {
    "value": "var(--colors-error-600)",
    "variable": "var(--colors-border-error)"
  },
  "colors.border.disabled": {
    "value": "var(--colors-neutral-300)",
    "variable": "var(--colors-border-disabled)"
  },
  "colors.border.errorSubtle": {
    "value": "var(--colors-error-300)",
    "variable": "var(--colors-border-error-subtle)"
  },
  "colors.confirmedIconsBackground.100": {
    "value": "var(--colors-brand-500)",
    "variable": "var(--colors-confirmed-icons-background-100)"
  },
  "colors.confirmedIconsBackground.200": {
    "value": "var(--colors-brand-700)",
    "variable": "var(--colors-confirmed-icons-background-200)"
  },
  "colors.confirmedIconsBackground.300": {
    "value": "var(--colors-brand-800)",
    "variable": "var(--colors-confirmed-icons-background-300)"
  },
  "colors.confirmedIconsBackground.400": {
    "value": "var(--colors-steel-fog-500)",
    "variable": "var(--colors-confirmed-icons-background-400)"
  },
  "colors.confirmedIconsBackground.500": {
    "value": "var(--colors-steel-fog-600)",
    "variable": "var(--colors-confirmed-icons-background-500)"
  },
  "colors.confirmedIconsBackground.600": {
    "value": "var(--colors-steel-fog-700)",
    "variable": "var(--colors-confirmed-icons-background-600)"
  },
  "colors.confirmedIconsBackground.700": {
    "value": "var(--colors-copper-taupe-500)",
    "variable": "var(--colors-confirmed-icons-background-700)"
  },
  "colors.confirmedIconsBackground.800": {
    "value": "var(--colors-copper-taupe-600)",
    "variable": "var(--colors-confirmed-icons-background-800)"
  },
  "colors.confirmedIconsBackground.900": {
    "value": "var(--colors-copper-taupe-700)",
    "variable": "var(--colors-confirmed-icons-background-900)"
  },
  "gradients.causesHoverGradient": {
    "value": "var(--gradients-orange-gradient)",
    "variable": "var(--gradients-causes-hover-gradient)"
  },
  "gradients.causesHoverBorderGradient": {
    "value": "var(--gradients-orange-border-gradient)",
    "variable": "var(--gradients-causes-hover-border-gradient)"
  },
  "gradients.conversationFade": {
    "value": "var(--gradients-white-linear-gradient)",
    "variable": "var(--gradients-conversation-fade)"
  },
  "spacing.-modalPaddingMobile": {
    "value": "calc(var(--spacing-modal-padding-mobile) * -1)",
    "variable": "var(--spacing-modal-padding-mobile)"
  },
  "spacing.-modalPaddingDesktop": {
    "value": "calc(var(--spacing-modal-padding-desktop) * -1)",
    "variable": "var(--spacing-modal-padding-desktop)"
  },
  "colors.colorPalette": {
    "value": "var(--colors-color-palette)",
    "variable": "var(--colors-color-palette)"
  },
  "colors.colorPalette.50": {
    "value": "var(--colors-color-palette-50)",
    "variable": "var(--colors-color-palette-50)"
  },
  "colors.colorPalette.100": {
    "value": "var(--colors-color-palette-100)",
    "variable": "var(--colors-color-palette-100)"
  },
  "colors.colorPalette.200": {
    "value": "var(--colors-color-palette-200)",
    "variable": "var(--colors-color-palette-200)"
  },
  "colors.colorPalette.300": {
    "value": "var(--colors-color-palette-300)",
    "variable": "var(--colors-color-palette-300)"
  },
  "colors.colorPalette.400": {
    "value": "var(--colors-color-palette-400)",
    "variable": "var(--colors-color-palette-400)"
  },
  "colors.colorPalette.500": {
    "value": "var(--colors-color-palette-500)",
    "variable": "var(--colors-color-palette-500)"
  },
  "colors.colorPalette.600": {
    "value": "var(--colors-color-palette-600)",
    "variable": "var(--colors-color-palette-600)"
  },
  "colors.colorPalette.700": {
    "value": "var(--colors-color-palette-700)",
    "variable": "var(--colors-color-palette-700)"
  },
  "colors.colorPalette.800": {
    "value": "var(--colors-color-palette-800)",
    "variable": "var(--colors-color-palette-800)"
  },
  "colors.colorPalette.900": {
    "value": "var(--colors-color-palette-900)",
    "variable": "var(--colors-color-palette-900)"
  },
  "colors.colorPalette.950": {
    "value": "var(--colors-color-palette-950)",
    "variable": "var(--colors-color-palette-950)"
  },
  "colors.colorPalette.black": {
    "value": "var(--colors-color-palette-black)",
    "variable": "var(--colors-color-palette-black)"
  },
  "colors.colorPalette.white": {
    "value": "var(--colors-color-palette-white)",
    "variable": "var(--colors-color-palette-white)"
  },
  "colors.colorPalette.primary": {
    "value": "var(--colors-color-palette-primary)",
    "variable": "var(--colors-color-palette-primary)"
  },
  "colors.colorPalette.primaryOnSolid": {
    "value": "var(--colors-color-palette-primary-on-solid)",
    "variable": "var(--colors-color-palette-primary-on-solid)"
  },
  "colors.colorPalette.secondary": {
    "value": "var(--colors-color-palette-secondary)",
    "variable": "var(--colors-color-palette-secondary)"
  },
  "colors.colorPalette.tertiary": {
    "value": "var(--colors-color-palette-tertiary)",
    "variable": "var(--colors-color-palette-tertiary)"
  },
  "colors.colorPalette.quaternary": {
    "value": "var(--colors-color-palette-quaternary)",
    "variable": "var(--colors-color-palette-quaternary)"
  },
  "colors.colorPalette.subtle.primary": {
    "value": "var(--colors-color-palette-subtle-primary)",
    "variable": "var(--colors-color-palette-subtle-primary)"
  },
  "colors.colorPalette.subtle.brand": {
    "value": "var(--colors-color-palette-subtle-brand)",
    "variable": "var(--colors-color-palette-subtle-brand)"
  },
  "colors.colorPalette.brand": {
    "value": "var(--colors-color-palette-brand)",
    "variable": "var(--colors-color-palette-brand)"
  },
  "colors.colorPalette.brand.primary": {
    "value": "var(--colors-color-palette-brand-primary)",
    "variable": "var(--colors-color-palette-brand-primary)"
  },
  "colors.colorPalette.brand.primarySubtle": {
    "value": "var(--colors-color-palette-brand-primary-subtle)",
    "variable": "var(--colors-color-palette-brand-primary-subtle)"
  },
  "colors.colorPalette.primarySubtle": {
    "value": "var(--colors-color-palette-primary-subtle)",
    "variable": "var(--colors-color-palette-primary-subtle)"
  },
  "colors.colorPalette.brand.secondary": {
    "value": "var(--colors-color-palette-brand-secondary)",
    "variable": "var(--colors-color-palette-brand-secondary)"
  },
  "colors.colorPalette.brand.solid": {
    "value": "var(--colors-color-palette-brand-solid)",
    "variable": "var(--colors-color-palette-brand-solid)"
  },
  "colors.colorPalette.solid": {
    "value": "var(--colors-color-palette-solid)",
    "variable": "var(--colors-color-palette-solid)"
  },
  "colors.colorPalette.brand.solidSubtle": {
    "value": "var(--colors-color-palette-brand-solid-subtle)",
    "variable": "var(--colors-color-palette-brand-solid-subtle)"
  },
  "colors.colorPalette.solidSubtle": {
    "value": "var(--colors-color-palette-solid-subtle)",
    "variable": "var(--colors-color-palette-solid-subtle)"
  },
  "colors.colorPalette.brand.accent": {
    "value": "var(--colors-color-palette-brand-accent)",
    "variable": "var(--colors-color-palette-brand-accent)"
  },
  "colors.colorPalette.accent": {
    "value": "var(--colors-color-palette-accent)",
    "variable": "var(--colors-color-palette-accent)"
  },
  "colors.colorPalette.brand.accentSolid": {
    "value": "var(--colors-color-palette-brand-accent-solid)",
    "variable": "var(--colors-color-palette-brand-accent-solid)"
  },
  "colors.colorPalette.accentSolid": {
    "value": "var(--colors-color-palette-accent-solid)",
    "variable": "var(--colors-color-palette-accent-solid)"
  },
  "colors.colorPalette.brand.accentSolidHover": {
    "value": "var(--colors-color-palette-brand-accent-solid-hover)",
    "variable": "var(--colors-color-palette-brand-accent-solid-hover)"
  },
  "colors.colorPalette.accentSolidHover": {
    "value": "var(--colors-color-palette-accent-solid-hover)",
    "variable": "var(--colors-color-palette-accent-solid-hover)"
  },
  "colors.colorPalette.brand.utility": {
    "value": "var(--colors-color-palette-brand-utility)",
    "variable": "var(--colors-color-palette-brand-utility)"
  },
  "colors.colorPalette.utility": {
    "value": "var(--colors-color-palette-utility)",
    "variable": "var(--colors-color-palette-utility)"
  },
  "colors.colorPalette.error": {
    "value": "var(--colors-color-palette-error)",
    "variable": "var(--colors-color-palette-error)"
  },
  "colors.colorPalette.warn": {
    "value": "var(--colors-color-palette-warn)",
    "variable": "var(--colors-color-palette-warn)"
  },
  "colors.colorPalette.success": {
    "value": "var(--colors-color-palette-success)",
    "variable": "var(--colors-color-palette-success)"
  },
  "colors.colorPalette.disabled": {
    "value": "var(--colors-color-palette-disabled)",
    "variable": "var(--colors-color-palette-disabled)"
  },
  "colors.colorPalette.tagTransparent": {
    "value": "var(--colors-color-palette-tag-transparent)",
    "variable": "var(--colors-color-palette-tag-transparent)"
  },
  "colors.colorPalette.error.primary": {
    "value": "var(--colors-color-palette-error-primary)",
    "variable": "var(--colors-color-palette-error-primary)"
  },
  "colors.colorPalette.warning.primary": {
    "value": "var(--colors-color-palette-warning-primary)",
    "variable": "var(--colors-color-palette-warning-primary)"
  },
  "colors.colorPalette.secondaryOnSolid": {
    "value": "var(--colors-color-palette-secondary-on-solid)",
    "variable": "var(--colors-color-palette-secondary-on-solid)"
  },
  "colors.colorPalette.secondary.onSolid": {
    "value": "var(--colors-color-palette-secondary-on-solid)",
    "variable": "var(--colors-color-palette-secondary-on-solid)"
  },
  "colors.colorPalette.onSolid": {
    "value": "var(--colors-color-palette-on-solid)",
    "variable": "var(--colors-color-palette-on-solid)"
  },
  "colors.colorPalette.primary.subtle": {
    "value": "var(--colors-color-palette-primary-subtle)",
    "variable": "var(--colors-color-palette-primary-subtle)"
  },
  "colors.colorPalette.subtle": {
    "value": "var(--colors-color-palette-subtle)",
    "variable": "var(--colors-color-palette-subtle)"
  },
  "colors.colorPalette.primary.onSolid": {
    "value": "var(--colors-color-palette-primary-on-solid)",
    "variable": "var(--colors-color-palette-primary-on-solid)"
  },
  "colors.colorPalette.brand.primarySolid": {
    "value": "var(--colors-color-palette-brand-primary-solid)",
    "variable": "var(--colors-color-palette-brand-primary-solid)"
  },
  "colors.colorPalette.primarySolid": {
    "value": "var(--colors-color-palette-primary-solid)",
    "variable": "var(--colors-color-palette-primary-solid)"
  },
  "colors.colorPalette.brand.secondarySolid": {
    "value": "var(--colors-color-palette-brand-secondary-solid)",
    "variable": "var(--colors-color-palette-brand-secondary-solid)"
  },
  "colors.colorPalette.secondarySolid": {
    "value": "var(--colors-color-palette-secondary-solid)",
    "variable": "var(--colors-color-palette-secondary-solid)"
  },
  "colors.colorPalette.brand.accentHover": {
    "value": "var(--colors-color-palette-brand-accent-hover)",
    "variable": "var(--colors-color-palette-brand-accent-hover)"
  },
  "colors.colorPalette.accentHover": {
    "value": "var(--colors-color-palette-accent-hover)",
    "variable": "var(--colors-color-palette-accent-hover)"
  },
  "colors.colorPalette.secondary.200": {
    "value": "var(--colors-color-palette-secondary-200)",
    "variable": "var(--colors-color-palette-secondary-200)"
  },
  "colors.colorPalette.secondary.solid": {
    "value": "var(--colors-color-palette-secondary-solid)",
    "variable": "var(--colors-color-palette-secondary-solid)"
  },
  "colors.colorPalette.primary.300": {
    "value": "var(--colors-color-palette-primary-300)",
    "variable": "var(--colors-color-palette-primary-300)"
  },
  "colors.colorPalette.primary.solid.700": {
    "value": "var(--colors-color-palette-primary-solid-700)",
    "variable": "var(--colors-color-palette-primary-solid-700)"
  },
  "colors.colorPalette.solid.700": {
    "value": "var(--colors-color-palette-solid-700)",
    "variable": "var(--colors-color-palette-solid-700)"
  },
  "colors.colorPalette.onWarning": {
    "value": "var(--colors-color-palette-on-warning)",
    "variable": "var(--colors-color-palette-on-warning)"
  },
  "colors.colorPalette.successSubtle": {
    "value": "var(--colors-color-palette-success-subtle)",
    "variable": "var(--colors-color-palette-success-subtle)"
  },
  "colors.colorPalette.errorSubtle": {
    "value": "var(--colors-color-palette-error-subtle)",
    "variable": "var(--colors-color-palette-error-subtle)"
  }
}

export function token(path, fallback) {
  return tokens[path]?.value || fallback
}

function tokenVar(path, fallback) {
  return tokens[path]?.variable || fallback
}

token.var = tokenVar