import {useState} from "react"
import {useNavigate} from "react-router-dom"

import {API_BASE_URL} from "@frontend/env"
import {useBooleanState} from "@frontend/utils/useBooleanState"

type Field = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
}

export const useLogin = (): {
  password: Field
  email: Field
  loading: boolean
  error: boolean
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>
} => {
  const navigate = useNavigate()
  const {state: error, setTrue: setError} = useBooleanState(false)
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [loading, setLoading] = useState(false)

  const login = async (): Promise<void> => {
    if (!email || !password) return
    setLoading(true)

    const response = await fetch(`${API_BASE_URL}/login`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({email, password}),
    })

    if (response?.ok) {
      navigate("/")
    } else {
      setError()
    }

    setLoading(false)
  }

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault()
    await login()
  }

  return {
    email: {
      onChange: (e: React.ChangeEvent<HTMLInputElement>): void =>
        setEmail(e.target.value),
      value: email,
    },
    password: {
      onChange: (e: React.ChangeEvent<HTMLInputElement>): void =>
        setPassword(e.target.value),
      value: password,
    },
    loading,
    handleSubmit,
    error,
  }
}
