import {FC} from "react"
import Markdown from "react-markdown"
import {ReactSVG} from "react-svg"

import infoIcon from "@frontend/design/icons/info-bold.svg"
import {useTranslation} from "@frontend/i18n"
import {useBooleanState} from "@frontend/utils/useBooleanState"
import {
  MessageMedia,
  MessagePartImages,
  MessageVideo,
  isMessageVideo,
} from "@ri2/db/client"
import {css} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {Media} from "./media"

const ALLOWED_LINK_URLS = [
  "https://www.repairclinic.com",
  "https://www.youtube.com",
  "https://www.rcappliancepartsimages.com",
]

const isAllowedLinkUrl = (url: string): boolean =>
  !!ALLOWED_LINK_URLS.find(
    (allowedUrl) =>
      url.startsWith(allowedUrl) || url.startsWith(`${allowedUrl}/`),
  )

interface MessageContentProps {
  message: string
  isThinkingOrAi: boolean
  questionMedia?: MessageMedia[]
  disassemblyVideos?: MessageVideo[]
  partImages?: MessagePartImages
  onSelectMedia: (url: string) => void
  isAskModelNumberMessage?: boolean
}

export const MessageContent: FC<MessageContentProps> = ({
  isThinkingOrAi,
  message,
  questionMedia = [],
  disassemblyVideos = [],
  partImages,
  onSelectMedia,
  isAskModelNumberMessage,
}) => {
  const t = useTranslation()

  const hasMixedMedia =
    questionMedia.some(isMessageVideo) &&
    questionMedia.some((media) => !isMessageVideo(media))

  const questionMediaDescription =
    questionMedia.length > 0
      ? t(
          hasMixedMedia
            ? "conversation.questionMediaMixed"
            : isMessageVideo(questionMedia[0])
              ? "conversation.questionMediaVideo"
              : "conversation.questionMediaImage",
          {count: questionMedia.length},
        )
      : ""

  if (isThinkingOrAi) {
    return (
      <>
        <Markdown
          allowedElements={["a", "em", "img", "li", "ol", "p", "strong", "ul"]}
          components={{
            a: ({href, children}) =>
              href && isAllowedLinkUrl(href) ? (
                <a
                  href={href}
                  target="_blank"
                  className={css({
                    color: "turquoise",
                    textDecoration: "underline",
                  })}
                  rel="noreferrer"
                >
                  {children}
                </a>
              ) : (
                <span>{children}</span>
              ),
          }}
          className={css({
            "& > * + *": {
              marginTop: 16,
            },
            "& strong": {
              fontWeight: "500",
            },
            "& li": {
              textStyle: "bodyLargeLight",
            },
            "& ol": {
              listStyleType: "decimal",
              paddingLeft: 24,
            },
            "& ol li": {
              paddingLeft: 8,
            },
            "& ul": {
              listStyleType: "disc",
              paddingLeft: 24,
            },
            "& ul li": {
              paddingLeft: 8,
            },
            "& p": {
              textStyle: "bodyLargeLight",
            },
          })}
        >
          {message}
        </Markdown>
        {!isAskModelNumberMessage && questionMedia.length > 0 && (
          <div>
            {questionMediaDescription}
            <div
              className={hstack({
                flexWrap: "wrap",
                gap: 15,
                marginY: 15,
              })}
            >
              {questionMedia.map((media) => (
                <Media
                  media={media}
                  onClick={() => {
                    onSelectMedia(
                      isMessageVideo(media) ? media.thumbnailUrl : media.url,
                    )
                  }}
                  key={media.url}
                />
              ))}
            </div>
          </div>
        )}
        {isAskModelNumberMessage && (
          <FindModelNumberInstructions
            messageMedia={questionMedia}
            onSelectMedia={onSelectMedia}
            questionMediaDescription={questionMediaDescription}
          />
        )}
        {disassemblyVideos.length > 0 && (
          <div>
            {t("conversation.disassemblyVideos", {
              count: disassemblyVideos.length,
            })}
            <div
              className={hstack({
                flexWrap: "wrap",
                gap: 15,
                marginY: 15,
              })}
            >
              {disassemblyVideos.map((video) => (
                <Media
                  media={video}
                  onClick={() => {
                    onSelectMedia(video.thumbnailUrl)
                  }}
                  key={video.url}
                />
              ))}
            </div>
          </div>
        )}
        {partImages && partImages.images.length > 0 && (
          <div>
            {t("conversation.partImages", {
              partType: partImages.partType.toLowerCase(),
              count: partImages.images.length,
            })}
            <div
              className={hstack({
                flexWrap: "wrap",
                gap: 15,
                marginY: 15,
              })}
            >
              {partImages.images.map((image) => (
                <Media
                  media={image}
                  onClick={() => {
                    onSelectMedia(image.url)
                  }}
                  key={image.url}
                />
              ))}
            </div>
          </div>
        )}
      </>
    )
  }

  return (
    <span
      className={css({
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
        width: "100%",
        textStyle: "bodyLargeLight",
      })}
    >
      {message}
    </span>
  )
}

const FindModelNumberInstructions: FC<{
  messageMedia: MessageMedia[]
  onSelectMedia: (url: string) => void
  questionMediaDescription: string
}> = ({messageMedia, onSelectMedia, questionMediaDescription}) => {
  const {
    state: isDisplayingInstructions,
    setTrue: displayInstructions,
    setFalse: hideInstructions,
  } = useBooleanState(false)
  const t = useTranslation()
  return (
    <>
      <div
        className={css({
          width: 79,
          borderTopWidth: 1,
          borderTopStyle: "dashed",
          borderTopColor: "border.brand.secondary",
        })}
      />
      <div
        className={hstack({
          alignItems: "center",
          paddingY: 10,
          paddingX: 12,
          width: "fit-content",
          gap: 4,
          minHeight: 36,
          borderRadius: 999,
          fontSize: 13,
          backgroundColor: "background.brand.primarySubtle",
          borderStyle: "solid",
          borderWidth: 1,
          borderColor: "border.brand.primary",
        })}
      >
        <ReactSVG
          src={infoIcon}
          className={
            isDisplayingInstructions
              ? css({
                  "& *": {
                    stroke: "black",
                  },
                })
              : ""
          }
        />
        <span
          className={css({
            textStyle: "captionRegular",
            color: "text.primary",
            paddingLeft: 2,
            paddingRight: 12,
          })}
        >
          {t(
            isDisplayingInstructions
              ? "conversation.modelNumberTip"
              : "conversation.whereIsMyModelNumber",
          )}
        </span>
        <button
          onClick={
            isDisplayingInstructions ? hideInstructions : displayInstructions
          }
          className={css({
            color: "text.brand.secondary",
            height: 20,
            paddingX: 2,
            textStyle: "captionMedium",
            alignItems: "center",
            cursor: "pointer",
          })}
        >
          {t(
            isDisplayingInstructions
              ? "conversation.hide"
              : "conversation.reveal",
          )}
        </button>
      </div>
      {isDisplayingInstructions && (
        <>
          <p
            className={css({
              color: "text.primary",
              textStyle: "bodyLight",
            })}
          >
            {t("conversation.modelNumberLocationInfo")}
          </p>
          {messageMedia.length > 0 && (
            <div
              className={css({
                color: "text.secondary",
                textStyle: "captionMedium",
              })}
            >
              {questionMediaDescription}
              <div
                className={hstack({
                  flexWrap: "wrap",
                  gap: 15,
                  marginY: 15,
                })}
              >
                {messageMedia.map((media) => (
                  <Media
                    media={media}
                    onClick={() => {
                      onSelectMedia(
                        isMessageVideo(media) ? media.thumbnailUrl : media.url,
                      )
                    }}
                    key={media.url}
                  />
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}
