import {FC} from "react"
import {BrowserRouter} from "react-router-dom"
import "@frontend/routes/globals.css"

import {Router} from "./Router"

export const LandingPage: FC<{open: () => void}> = ({open}) => (
  <BrowserRouter>
    <Router open={open} />
  </BrowserRouter>
)
