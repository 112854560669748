import {FC} from "react"

import feedbackIcon from "@frontend/design/icons/feedback.svg"
import {useTranslation} from "@frontend/i18n"
import {useBooleanState} from "@frontend/utils/useBooleanState"

import {SidebarButton} from "./sidebar-button"
import {FeedbackModal} from "../feedback-modal"

interface Props {
  isSidebarOpen: boolean
  afterClick: () => void
}

export const FeedbackButton: FC<Props> = ({isSidebarOpen, afterClick}) => {
  const {
    state: isShowingModal,
    setTrue: onShowModal,
    setFalse: onCloseModal,
  } = useBooleanState(false)

  const onClick = (): void => {
    onShowModal()
    afterClick()
  }
  const t = useTranslation()

  return (
    <>
      <SidebarButton
        icon={feedbackIcon}
        title={t("appContainer.header.feedback")}
        onClick={onClick}
        isSidebarOpen={isSidebarOpen}
      />
      {isShowingModal && <FeedbackModal onClose={onCloseModal} />}
    </>
  )
}
