import {FC} from "react"

import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

export const ConsiderationItem: FC<{
  icon: string
  name: string
  content: string
}> = ({name, icon, content}) => (
  <div
    className={vstack({
      gap: 16,
      width: {
        base: "100%",
        largeDesktop: "276px",
      },
      height: {
        largeDesktop: "auto",
      },
      minWidth: {
        base: "276px",
        largeDesktop: "auto",
      },
      borderRadius: 12,
      borderWidth: 1,
      borderColor: "lineGrey",
    })}
  >
    <div
      className={hstack({
        padding: 8,
        backgroundColor: "lightGrey",
        width: "100%",
        borderTopRadius: 12,
      })}
    >
      <div
        className={hstack({
          gap: 8,
          alignItems: "center",
          borderRadius: 8,
          padding: "4px 8px 4px 4px",
        })}
      >
        <img src={icon} alt="" className={css({width: 12, height: 12})} />
        <span
          className={css({
            textStyle: "captionRegular",
            color: "text.brand.primary",
          })}
        >
          {name}
        </span>
      </div>
    </div>

    <div className={css({paddingX: 16, paddingBottom: 24})}>
      <p
        className={css({
          textStyle: "body2",
          color: "fontGrey",
        })}
      >
        {content}
      </p>
    </div>
  </div>
)
