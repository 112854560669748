import {useContext} from "react"

import {useStabilizedValue} from "@frontend/utils/useStabilizedValue"

import {SessionContext} from "./context"

export interface Session {
  userRcId: string
}

export const useSession = (): Session => {
  const {userRcId} = useContext(SessionContext)

  return useStabilizedValue({userRcId})
}
