import {FC} from "react"
import {Trans} from "react-i18next"
import {ReactSVG} from "react-svg"

import {Button, Modal} from "@frontend/components"
import info from "@frontend/design/icons/info.svg"
import {useTranslation} from "@frontend/i18n"
import {css, cx} from "@styled-system/css"
import {hstack, iconsColor} from "@styled-system/patterns"

interface Props {
  show: boolean
  onClose: () => void
}

export const ConsentModal: FC<Props> = ({show, onClose}) => {
  const t = useTranslation()

  return (
    <Modal
      title={t("cases.consentModal.title")}
      message={
        <Trans t={t} i18nKey="cases.consentModal.description">
          <a
            href="https://www.repairclinic.com/Privacy-Policy"
            target="_blank"
            className={css({color: "text.brand.accent", cursor: "pointer"})}
            rel="noreferrer"
          />
        </Trans>
      }
      containerCss={css.raw({
        paddingBottom: 16,
        paddingTop: 24,
        gap: 16,
      })}
      show={show}
      onDismiss={onClose}
      width={610}
      icon={
        <div
          className={hstack({
            borderRadius: 8,
            backgroundColor: "background.brand.primarySubtle",
            padding: 6,
            minWidth: 36,
            minHeight: 36,
            maxHeight: 36,
            justifyContent: "center",
            alignItems: "center",
          })}
        >
          <ReactSVG
            src={info}
            className={cx(
              iconsColor({color: "foreground.brand.primary"}),
              css({
                "& svg": {
                  width: 24,
                  height: 24,
                },
              }),
            )}
          />
        </div>
      }
    >
      <div className={hstack({justifyContent: "flex-end"})}>
        <Button
          size="large"
          variant="primary"
          onClick={onClose}
          css={css.raw({
            width: 120,
            height: {
              base: 48,
              desktop: 40,
            },
          })}
          title={t("cases.consentModal.action")}
        />
      </div>
    </Modal>
  )
}
