import {FC} from "react"
import {ReactSVG} from "react-svg"

import rightChevron from "@frontend/design/icons/chevron-right.svg"
import slashCircleIcon from "@frontend/design/icons/slash-circle.svg"
import {useTranslation} from "@frontend/i18n"
import {useAddModelNumberToCase} from "@frontend/utils/trpc"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {Button} from "../ui/button"

interface Props {
  caseId: string
  modelNumber: string
  onEdit: () => void
  onClose: () => void
}

export const Unmatched: FC<Props> = ({
  caseId,
  modelNumber,
  onEdit,
  onClose,
}) => {
  const addModelNumber = useAddModelNumberToCase(onClose)

  const onAdd = (): void => {
    addModelNumber.mutate({
      caseId,
      modelNumber,
    })
  }

  const t = useTranslation()

  return (
    <div
      className={vstack({
        gap: 16,
        alignItems: "stretch",
      })}
    >
      <div
        className={vstack({
          gap: 8,
          alignItems: "flex-start",
        })}
      >
        <div
          className={css({
            color: "rgba(78, 79, 86, 1)",
            fontWeight: 500,
            fontSize: 13,
            lineHeight: "16px",
          })}
        >
          {t("addModelNumberModal.unmatched.message")}
        </div>
        <div
          className={hstack({
            padding: 16,
            borderWidth: 1,
            borderColor: "border.brand.primary",
            borderRadius: 16,
            width: "100%",
            backgroundColor: "background.brand.primarySubtle",
          })}
        >
          <ReactSVG src={slashCircleIcon} />
          <div
            className={vstack({
              gap: 0,
              alignItems: "flex-start",
              flexGrow: 1,
            })}
          >
            <p
              className={css({
                color: "text.primary",
                textStyle: "bodyLargeMedium",
              })}
            >
              {t("addModelNumberModal.unmatched.unknownModel")}
            </p>
            <span className={css({textStyle: "bodyLight"})}>{modelNumber}</span>
          </div>
          <Button
            variant="tertiaryBrandAccent"
            title={t("addModelNumberModal.unmatched.edit")}
            onClick={onEdit}
          />
        </div>
      </div>
      <div
        className={hstack({
          justifyContent: "flex-end",
          gap: 8,
        })}
      >
        <Button
          variant="secondaryGray"
          size="large"
          onClick={onEdit}
          title={t("addModelNumberModal.unmatched.cancel")}
        />
        <Button
          variant="primary"
          size="large"
          onClick={onAdd}
          rightElement={<ReactSVG src={rightChevron} />}
          title={t("addModelNumberModal.unmatched.add")}
        />
      </div>
    </div>
  )
}
