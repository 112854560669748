import {useEffect} from "react"

export const useCloseWhenClickOutsideOrEsc = (
  className: string,
  callback: () => void,
): void => {
  useEffect(() => {
    const handleClick = (e: MouseEvent): void => {
      if (!(e.target as HTMLElement).classList.contains(className)) {
        callback()
      }
    }
    const handleEsc = (e: KeyboardEvent): void => {
      if (e.key === "Escape") {
        callback()
      }
    }
    window.addEventListener("click", handleClick)
    window.addEventListener("keydown", handleEsc)
    return (): void => {
      window.removeEventListener("click", handleClick)
      window.removeEventListener("keydown", handleEsc)
    }
  }, [callback, className])
}
