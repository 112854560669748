import {FC, PropsWithChildren} from "react"

import {cva, cx} from "@styled-system/css"

import {ButtonProps} from "./buttonProps"
import {useHandleClick} from "./useHandleClick"

export interface TabProps extends PropsWithChildren {
  title: string
  active: boolean
  className?: string
  navigateTo?: string
  onClick?: () => void
}

const tabButtonStyles = cva({
  base: {
    borderBottomWidth: 4,
    paddingBottom: 6,
    textStyle: "bodyMedium",
    _enabled: {
      cursor: "pointer",
    },
  },
  variants: {
    active: {
      true: {
        color: "text.brand.accent",
        borderBottomColor: "border.brand.accent",
      },
      false: {
        color: "#505050",
        borderBottomColor: "transparent",
      },
    },
  },
})

export const TabButton: FC<TabProps & ButtonProps> = ({
  title,
  disabled,
  active,
  className,
  id,
  children,
  navigateTo,
  onClick,
}) => {
  const handleClick = useHandleClick({onClick, navigateTo})
  return (
    <button
      id={id}
      onClick={handleClick}
      disabled={disabled}
      className={cx(tabButtonStyles({active}), className)}
    >
      {title}
      {children}
    </button>
  )
}
