import {FC} from "react"
import {Outlet} from "react-router-dom"

import {useCase} from "@frontend/app-container/context"

import {BaseLayout} from "./components/base-layout"
import {useDataFromParams} from "./params"

export const CaseLayout: FC = () => {
  const {
    casePageData: {caseModel},
  } = useDataFromParams()

  useCase(caseModel)

  return (
    <BaseLayout>
      <Outlet />
    </BaseLayout>
  )
}
