import {FC} from "react"

import {useNavigateUpBreadcrumbs} from "@frontend/app-container/context"
import {ReactionsWithForm} from "@frontend/components"
import chevron from "@frontend/design/icons/chevron.svg"
import {useTranslation} from "@frontend/i18n"
import {
  FeedbackOnRemediationHashType,
  RemediationWithHashIdAndFeedback,
} from "@ri2/db/client"
import {hstack} from "@styled-system/patterns"

type Props =
  | {
      remediation: undefined
      feedbackType: undefined
    }
  | {
      remediation: Pick<RemediationWithHashIdAndFeedback, "hashId" | "feedback">
      feedbackType?: FeedbackOnRemediationHashType
    }

export const MobileBackHeader: FC<Props> = ({remediation, feedbackType}) => {
  const onBack = useNavigateUpBreadcrumbs()
  const t = useTranslation()

  return (
    <div
      className={hstack({
        justifyContent: "space-between",
        marginTop: 24,
        marginBottom: 28,
        paddingX: 16,
        display: {
          base: "flex",
          desktop: "none",
        },
      })}
    >
      <button
        onClick={onBack}
        className={hstack({
          gap: 4,
          textStyle: "button",
          color: "fontBlack",
        })}
      >
        <img src={chevron} alt="" />
        {t("general.back")}
      </button>
      {remediation && feedbackType && (
        <ReactionsWithForm
          on={{
            on: "remediation",
            remediationHashId: remediation.hashId,
            type: feedbackType,
          }}
          currentFeedback={remediation.feedback[feedbackType]}
        />
      )}
    </div>
  )
}
