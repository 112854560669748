import {FC} from "react"
import {ReactSVG} from "react-svg"

import {iconsColor} from "@frontend/design"
import libraryIcon from "@frontend/design/icons/library.svg"
import {css, cx} from "@styled-system/css"

interface Props {
  onToggleSidebar: () => void
  className?: string
}

export const ToggleSidebarButton: FC<Props> = ({
  onToggleSidebar,
  className,
}) => (
  <button
    onClick={onToggleSidebar}
    className={cx(
      css({
        width: 44,
        minHeight: 44,
        padding: 8,

        marginRight: {
          base: 4,
          desktop: 16,
          largeDesktop: 0,
        },
      }),
      className,
    )}
  >
    <ReactSVG
      src={libraryIcon}
      className={cx(
        iconsColor({
          color: "black",
        }),
        css({"& svg": {width: 32, height: 32}}),
      )}
    />
  </button>
)
