import {coloringIconsCss} from "@frontend/design"
import {RecipeVariant, cva} from "@styled-system/css"

export const buttonColors = cva({
  base: {},
  variants: {
    variant: {
      unstyled: {},
      primary: {
        backgroundColor: "background.brand.accent",
        color: "base.white",
        border: "1px solid transparent",
        borderImageSource:
          "linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 100%)",
        boxShadow: "0px -2px 0px 0px #1018280D inset",
        _hover: {
          backgroundColor: "background.brand.accentSolidHover",
        },
        _focus: {
          backgroundColor: "background.brand.accentSolid",
          boxShadow:
            "0px 0px 0px 6px token(colors.background.subtle.brand), inset 0px -2px 0px rgba(16, 24, 40, 0.05)",
        },
        ...coloringIconsCss("foreground.primaryOnSolid"),
      },
      secondary: {
        backgroundColor: "transparent",
        color: "text.brand.accent",
        border: "1px solid token(colors.border.brand.accent)",
        _hover: {
          backgroundColor: "background.subtle.brand",
        },
        _focus: {
          backgroundColor: "background.primary",
          boxShadow:
            "0px 0px 0px 6px token(colors.background.subtle.brand), inset 0px -2px 0px rgba(16, 24, 40, 0.05)",
        },
        ...coloringIconsCss("foreground.brand.accent"),
      },
      secondaryOnBrand: {
        backgroundColor: "transparent",
        color: "text.secondary.onSolid",
        border: "1px solid token(colors.foreground.secondaryOnSolid)",
        _hover: {
          backgroundColor: "background.brand.solidSubtle",
          color: "text.primary.onSolid",
        },
        _focus: {
          backgroundColor: "background.brand.solidSubtle",
          color: "text.primary.onSolid",
          boxShadow:
            "0px 0px 0px 6px token(colors.background.brand.solidSubtle), inset 0px -2px 0px rgba(16, 24, 40, 0.05)",
        },
        ...coloringIconsCss("foreground.secondaryOnSolid"),
      },
      secondaryGray: {
        backgroundColor: "transparent",
        color: "gray.600",
        border: "1px solid token(colors.border.secondary.solid)",
        _hover: {
          backgroundColor: "background.secondary",
        },
        _focus: {
          backgroundColor: "background.primary",
          boxShadow: "0px 0px 0px 6px token(colors.background.tertiary)",
        },
        ...coloringIconsCss("gray.700"),
      },
      tertiaryBrand: {
        backgroundColor: "transparent",
        color: "text.brand.secondary",
        _hover: {
          backgroundColor: "background.brand.primarySubtle",
          color: "text.brand.primary",
        },
        _focus: {
          backgroundColor: "background.primary",
          color: "text.brand.secondary",
          boxShadow: "0px 0px 0px 6px token(colors.background.brand.primary)",
        },
        ...coloringIconsCss("border.brand.secondarySolid"),
      },
      tertiaryBrandAccent: {
        backgroundColor: "transparent",
        color: "text.brand.accent",
        _hover: {
          backgroundColor: "background.subtle.brand",
        },
        _focus: {
          backgroundColor: "background.primary",
          boxShadow: "0px 0px 0px 6px token(colors.background.subtle.brand)",
        },
        ...coloringIconsCss("foreground.brand.accent"),
      },
    },
  },
  // We can use coloringIconsCss here, but no in the variants because it will be overwritten
  compoundVariants: [
    {
      variant: "secondaryOnBrand",
      css: {
        _hover: coloringIconsCss("foreground.primaryOnSolid"),
        _focus: coloringIconsCss("foreground.primaryOnSolid"),
      },
    },
    {
      variant: "tertiaryBrand",
      css: {
        _hover: coloringIconsCss("foreground.brand.primary"),
      },
    },
  ],
  defaultVariants: {
    variant: "primary",
  },
})

export type ButtonVariantsProps = RecipeVariant<typeof buttonColors>

export const disabledStyles = cva({
  base: {
    pointerEvents: "none",
  },
  variants: {
    variant: {
      unstyled: {},
      primary: {
        backgroundColor: "background.disabled",
        color: "text.disabled",
        border: "1px solid token(colors.border.disabled)",
        boxShadow: "none",
        ...coloringIconsCss("foreground.disabled"),
      },
      secondary: {
        backgroundColor: "background.primary",
        color: "text.disabled",
        border: "1px solid token(colors.border.disabled)",
        boxShadow: "none",
        ...coloringIconsCss("foreground.disabled"),
      },
      secondaryGray: {
        backgroundColor: "background.primary",
        color: "text.disabled",
        border: "1px solid token(colors.border.disabled)",
        boxShadow: "none",
        ...coloringIconsCss("foreground.disabled"),
      },
      secondaryOnBrand: {
        backgroundColor: "background.primary",
        color: "text.disabled",
        border: "1px solid token(colors.border.disabled)",
        boxShadow: "none",
        ...coloringIconsCss("foreground.disabled"),
      },
      tertiaryBrand: {
        backgroundColor: "transparent",
        color: "text.disabled",
        boxShadow: "none",
        ...coloringIconsCss("foreground.disabled"),
      },
      tertiaryBrandAccent: {
        backgroundColor: "transparent",
        color: "text.disabled",
        boxShadow: "none",
        ...coloringIconsCss("foreground.disabled"),
      },
    },
  },
})
