import {useCallback, useState} from "react"

export const useBooleanState = (
  initialState: boolean,
): {
  state: boolean
  setTrue: () => void
  setFalse: () => void
  toggle: () => void
} => {
  const [state, setState] = useState(initialState)
  const setTrue = useCallback((): void => setState(true), [setState])
  const setFalse = useCallback((): void => setState(false), [setState])
  const toggle = useCallback(
    (): void => setState((state) => !state),
    [setState],
  )

  return {state, setTrue, setFalse, toggle}
}
