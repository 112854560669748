export class NotFoundError extends Error {}

export const notFound = (): never => {
  throw new NotFoundError()
}

// eslint-disable-next-line prefer-arrow-functions/prefer-arrow-functions
export function assertOrNotFound<T>(value: T): asserts value {
  if (!value) {
    notFound()
  }
}
