import type {Product} from "@ri2/rc-db"
import {compact} from "lodash"
import {FC} from "react"
import {ReactSVG} from "react-svg"

import checkCircleIcon from "@frontend/design/icons/check-circle.svg"
import rightChevron from "@frontend/design/icons/chevron-right.svg"
import {useTranslation} from "@frontend/i18n"
import {useAddModelNumberToCase} from "@frontend/utils/trpc"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {Button} from "../ui/button"

interface Props {
  caseId: string
  modelNumber: string
  product: Product
  onEdit: () => void
  onClose: () => void
}

export const Matched: FC<Props> = ({
  caseId,
  modelNumber,
  product,
  onEdit,
  onClose,
}) => {
  const name = getProductName(product)

  const addModelNumber = useAddModelNumberToCase(onClose)

  const onAdd = (): void => {
    addModelNumber.mutate({
      caseId,
      modelNumber,
      productId: product.id,
    })
  }

  const t = useTranslation()

  return (
    <div
      className={vstack({
        gap: 16,
        alignItems: "stretch",
      })}
    >
      <div
        className={vstack({
          gap: 8,
          alignItems: "stretch",
        })}
      >
        <div
          className={css({
            color: "rgba(78, 79, 86, 1)",
            fontWeight: 500,
            fontSize: 13,
            lineHeight: "16px",
          })}
        >
          {t("addModelNumberModal.matched.message")}
        </div>
        <div
          className={hstack({
            paddingY: 24,
            paddingX: 16,
            borderWidth: 1,
            borderColor: "border.brand.primary",
            borderRadius: 16,
            gap: 16,
            width: "100%",
            backgroundColor: "background.primary",
            boxShadow:
              "0px 3px 3px 0px rgba(28, 37, 72, 0.06), 0px 9px 20px 0px rgba(28, 37, 72, 0.14)",
          })}
        >
          <ReactSVG src={checkCircleIcon} />
          <div
            className={vstack({
              alignItems: "flex-start",
              gap: 0,
              flexGrow: 1,
              flexShrink: 1,
            })}
          >
            <p
              className={css({
                textStyle: "bodyLargeMedium",
                color: "text.primary",
              })}
            >
              {name}
            </p>
            <span
              className={css({
                textStyle: "bodyLight",
                color: "text.tertiary",
              })}
            >
              {modelNumber}
            </span>
          </div>
          <Button
            variant="tertiaryBrandAccent"
            title={t("addModelNumberModal.matched.edit")}
            onClick={onEdit}
          />
        </div>
      </div>
      <div
        className={hstack({
          justifyContent: "flex-end",
          gap: 8,
        })}
      >
        <Button
          variant="secondaryGray"
          size="large"
          onClick={onEdit}
          title={t("addModelNumberModal.matched.cancel")}
        />
        <Button
          variant="primary"
          size="large"
          onClick={onAdd}
          rightElement={<ReactSVG src={rightChevron} />}
          title={t("addModelNumberModal.matched.add")}
        />
      </div>
    </div>
  )
}

const getProductName = ({
  brand,
  productType,
  productSubType,
  fuelType,
}: Product): string =>
  compact([
    brand.name,
    productSubType?.name,
    fuelType?.name,
    productType.name,
  ]).join(" ")
