import {FC} from "react"
import {ReactSVG} from "react-svg"

import {Button, IconButton} from "@frontend/components"
import {
  DesktopAndLargeDesktop,
  MobileOnly,
} from "@frontend/components/responsive"
import homeVector from "@frontend/design/icons/home-vector.svg"
import logo from "@frontend/design/icons/logo.svg"
import riIcon from "@frontend/design/icons/ri-logo.svg"
import logOutIcon from "@frontend/entry-app/assets/icons/log-out.svg"
import bannerGradient from "@frontend/entry-app/assets/images/banner-gradient.png"
import bannerImage from "@frontend/entry-app/assets/images/banner-image.png"
import landingPagePattern1 from "@frontend/entry-app/assets/patterns/landing-page-pattern-1.svg"
import landingPagePattern2 from "@frontend/entry-app/assets/patterns/landing-page-pattern-2.svg"
import landingPagePattern from "@frontend/entry-app/assets/patterns/landing-page-pattern.svg"
import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {useSignOut} from "./useSignOut"

export const Banner: FC<{open: () => void}> = ({open}) => {
  const t = useTranslation()
  const {signOut} = useSignOut()

  return (
    <div
      className={css({
        width: "100%",
        maxHeight: 808,
        backgroundColor: "background.brand.primary",
        position: "relative",
        overflow: "hidden",
      })}
    >
      <div
        className={hstack({
          zIndex: "bgPattern.middle",
          position: "absolute",
        })}
      >
        <ReactSVG src={landingPagePattern} />
        <ReactSVG src={landingPagePattern1} />
        <ReactSVG src={landingPagePattern2} />
      </div>
      <div
        className={hstack({
          width: "100%",
          justifyContent: "space-between",
          paddingTop: 38,
          paddingLeft: {
            base: 24,
            desktop: 45,
          },
          paddingRight: {
            base: 16,
            desktop: 32,
          },
          position: "relative",
          zIndex: "bgPattern.top",
        })}
      >
        <DesktopAndLargeDesktop>
          <img src={logo} alt="logo" className={css({height: 61})} />
        </DesktopAndLargeDesktop>
        <MobileOnly>
          <img
            src={riIcon}
            alt="logo"
            className={css({width: 39, height: 34})}
          />
        </MobileOnly>
        <div className={hstack()}>
          <Button
            variant="primary"
            size="large"
            onClick={open}
            title={t("entryApp.landingPage.getStarted")}
          />
          <DesktopAndLargeDesktop>
            <Button
              variant="tertiaryBrand"
              onClick={signOut}
              title={t("entryApp.landingPage.signOut")}
            />
          </DesktopAndLargeDesktop>
          <MobileOnly>
            <IconButton
              variant="unstyled"
              icon={<ReactSVG src={logOutIcon} />}
              onClick={signOut}
            />
          </MobileOnly>
        </div>
      </div>
      <div
        className={hstack({
          width: "100%",
          paddingTop: 33,
          justifyContent: "center",
          paddingX: 32,
        })}
      >
        <h1
          className={css({
            textStyle: {
              base: "header.s",
              desktop: "header.l",
            },
            maxWidth: 765,
            textAlign: {
              base: "left",
              desktop: "center",
            },
          })}
        >
          {t("entryApp.landingPage.title")}
        </h1>
      </div>
      <div
        className={hstack({
          width: "100%",
          justifyContent: "center",
          zIndex: "bgPattern.base",
          position: "relative",
        })}
      >
        <div
          className={css({
            position: "relative",
            paddingTop: 65,
            paddingX: 40,
          })}
        >
          <div
            className={css({
              position: "relative",
              maxWidth: 867,
              width: "100%",
            })}
          >
            <img
              src={bannerImage}
              alt="landing-page"
              className={css({
                maxWidth: 867,
                width: "100%",
              })}
              loading="lazy"
            />
            <img
              src={bannerGradient}
              className={css({
                position: "absolute",
                zIndex: "bgPattern.middle",
                maxWidth: 867,
                width: "100%",
                height: "100%",
                top: 0,
                bottom: 0,
              })}
            />
            <ReactSVG
              src={homeVector}
              className={css({
                position: "absolute",
                zIndex: "bgPattern.top",
                top: 0,
                right: 0,
                transform: "translate(50%, -50%)",
                "& svg": {
                  width: 62,
                  height: 62,
                  largeDesktop: {
                    width: 147,
                    height: 147,
                  },
                },
              })}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
