import {FC} from "react"
import {ReactSVG} from "react-svg"

import {Button} from "@frontend/components"
import rightChevron from "@frontend/design/icons/chevron-right.svg"
import riIcon from "@frontend/design/icons/ri-icon.svg"
import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"
import {vstack} from "@styled-system/patterns"

export const Footer: FC<{open: () => void}> = ({open}) => {
  const t = useTranslation()
  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "center",
        paddingY: 96,
        paddingX: 32,
        desktop: {
          paddingTop: 137,
          paddingBottom: 188,
        },
        backgroundColor: "background.brand.solid",
      })}
    >
      <div
        className={css({
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
          maxWidth: 1004,
          width: "100%",
          desktopDown: {
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 56,
          },
        })}
      >
        <div className={vstack({gap: 25, alignItems: "flex-start"})}>
          <ReactSVG
            src={riIcon}
            className={css({
              "& svg": {
                width: 81,
                height: 69,
              },
              desktopDown: {
                "& svg": {
                  width: 67,
                  height: 57,
                },
              },
            })}
          />
          <div>
            <h1
              className={css({
                textStyle: "header.xl",
                color: "text.primary.onSolid",
                desktopDown: {
                  textStyle: "header.m",
                },
              })}
            >
              {t("entryApp.landingPage.footerTitleTop")}
            </h1>
            <h1
              className={css({
                textStyle: "header.xl",
                color: "text.primary.onSolid",
                desktopDown: {
                  textStyle: "header.m",
                },
              })}
            >
              {t("entryApp.landingPage.footerTitleBottom")}
            </h1>
          </div>
        </div>
        <Button
          variant="primary"
          size="large"
          onClick={open}
          title={t("entryApp.landingPage.getStarted")}
          rightElement={
            <ReactSVG
              src={rightChevron}
              className={css({
                "& svg": {
                  width: 20,
                  height: 20,
                },
              })}
            />
          }
          css={css.raw({
            marginBottom: 51,
            fontSize: 20,
            paddingY: 16,
            paddingX: 24,
          })}
        />
      </div>
    </div>
  )
}
