export const CASE_MOBILE_TAB_KEY = "case-mobile-tab"

export const usePersistedValue = (
  key: string,
): {
  setValue: (value: string) => void
  getValue: () => string
} => {
  const setValue = (value: string): void => {
    localStorage.setItem(key, value)
  }

  const getValue = (): string => localStorage.getItem(key) ?? ""

  return {
    setValue,
    getValue,
  }
}
