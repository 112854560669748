import {useParams} from "react-router-dom"
import {z} from "zod"

/* eslint-disable @typescript-eslint/no-explicit-any */

export const useTypedParams = <T extends z.ZodType<any, any>>(
  schema: T,
): z.infer<T> => schema.parse(useParams())

/* eslint-disable @typescript-eslint/no-explicit-any */
