import {FC, HTMLProps} from "react"

import {Styles, css, cx} from "@styled-system/css"

export const Input: FC<HTMLProps<HTMLInputElement> & {css?: Styles}> = ({
  css: cssProp,
  className,
  ...props
}) => (
  <input
    {...props}
    className={cx(
      css(
        {
          width: "100%",
          height: 56,
          backgroundColor: "background.subtle.primary",
          borderRadius: 8,
          padding: 16,
          color: "text.primary",
          textStyle: "bodyLight",
          borderWidth: 1,
          borderColor: "border.secondary.200",
          borderStyle: "solid",
        },
        cssProp,
      ),
      className,
    )}
  />
)
