import {FC} from "react"
import {ReactSVG} from "react-svg"

import {FeedbackModal} from "@frontend/app-container/feedback-modal"
import feedbackIcon from "@frontend/design/icons/feedback.svg"
import {useTranslation} from "@frontend/i18n"
import {useBooleanState} from "@frontend/utils/useBooleanState"
import {Styles, css} from "@styled-system/css"

import {Button} from "./ui"

interface FeedbackButtonProps {
  css?: Styles
}

export const FeedbackButton: FC<FeedbackButtonProps> = ({css: cssProp}) => {
  const {
    state: isShowingFeedbackModal,
    setFalse: onHideFeedbackModal,
    setTrue: onShowFeedbackModal,
  } = useBooleanState(false)

  const t = useTranslation()

  return (
    <>
      <Button
        variant="tertiaryBrand"
        size="large"
        onClick={onShowFeedbackModal}
        leftElement={<ReactSVG src={feedbackIcon} />}
        title={t("appContainer.header.feedback")}
        css={css.raw(cssProp)}
      />

      {isShowingFeedbackModal && (
        <FeedbackModal onClose={onHideFeedbackModal} />
      )}
    </>
  )
}
