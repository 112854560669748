import {
  Feedback as BaseFeedback,
  Message as BaseMessage,
  FeedbackOnMessage,
  MessageMode,
} from "@prisma/client"
import {last} from "lodash"
import {z} from "zod"

import {CauseOnMessageWithCauseAndPart} from "../causeOnMessage/client"
import {Feedback} from "../feedback"
import {findLastMap} from "../findMap"

export const ASK_FOR_MODEL_NUMBER: typeof MessageMode.ASK_FOR_MODEL_NUMBER =
  "ASK_FOR_MODEL_NUMBER"

export const HumanMessageContent = z.object({
  content: z.string(),
  answers: z
    .tuple([z.number(), z.number()])
    .array()
    .default([])
    .transform((arr) => new Map(arr)),
  isMultipleChoiceAnswer: z.boolean().default(false),
})
export type HumanMessageContent = z.infer<typeof HumanMessageContent>

export type HumanMessage = {
  type: "human"
  content: HumanMessageContent
  mode: null
}

export const MessageImage = z.object({
  url: z.string(),
  title: z.string().nullish(),
})

export type MessageImage = z.infer<typeof MessageImage>

export const MessageVideo = z.object({
  url: z.string(),
  thumbnailUrl: z.string(),
  title: z.string().nullish(),
})

export type MessageVideo = z.infer<typeof MessageVideo>

export type MessageMedia = MessageImage | MessageVideo

const MessagePartImages = z.object({
  partType: z.string(),
  images: z.array(MessageImage),
})

export type MessagePartImages = z.infer<typeof MessagePartImages>

export const MultipleChoiceAnswer = z.object({
  text: z.string(),
  causeIdsToConfirm: z.string().array(),
  causeIdsToRuleOut: z.string().array(),
})
export type MultipleChoiceAnswer = z.infer<typeof MultipleChoiceAnswer>

export const AIMessageContent = z.object({
  question: z.string(),
  caseName: z.string().optional(),
  summary: z.string().optional(),
  decisionTreeQuestionId: z.number().optional(),
  askedDiagnosticQuestion: z.boolean().optional(),
  multipleChoiceAnswers: z.optional(
    z.union([
      MultipleChoiceAnswer.array(),
      // for backwards compatibility
      z.string().array(),
    ]),
  ),
  questionImages: z.optional(z.array(MessageImage)),
  questionVideos: z.optional(z.array(MessageVideo)),
  disassemblyVideos: z.optional(z.array(MessageVideo)),
  partImages: z.optional(MessagePartImages),
  partTestingVideos: z.optional(z.array(MessageVideo)),
})

export type AIMessageContent = z.infer<typeof AIMessageContent>

export type AIMessage = {
  type: "ai"
  content: AIMessageContent
  mode: MessageMode | null
  causes: MessageCauses
  feedback?: Feedback
}

export interface MessageCauses {
  confirmed: CauseOnMessageWithCauseAndPart[]
  veryLikely: CauseOnMessageWithCauseAndPart[]
  moreLikely: CauseOnMessageWithCauseAndPart[]
  possible: CauseOnMessageWithCauseAndPart[]
  unlikely: CauseOnMessageWithCauseAndPart[]
}

export type Message = Pick<
  BaseMessage,
  "id" | "caseId" | "createdAt" | "basedOnMessageId"
> &
  (AIMessage | HumanMessage)

export type BaseMessageWithCausesAndFeedback = BaseMessage & {
  causes: CauseOnMessageWithCauseAndPart[]
  feedbackOnMessages: (FeedbackOnMessage & {feedback: BaseFeedback})[]
}

export const isHumanMessage = (
  message: Message,
): message is Pick<
  BaseMessage,
  "id" | "caseId" | "createdAt" | "basedOnMessageId"
> &
  HumanMessage => message.type === "human"

export const isAIMessage = (
  message: Message,
): message is Pick<
  BaseMessage,
  "id" | "caseId" | "createdAt" | "basedOnMessageId"
> &
  AIMessage => message.type === "ai"

export const getCaseName = (
  messages: (
    | Pick<AIMessage, "type" | "content">
    | Pick<HumanMessage, "type">
  )[],
): string => getCaseNameIfMessages(messages) || "New Case"

export const getCaseNameIfMessages = (
  messages: (
    | Pick<AIMessage, "type" | "content">
    | Pick<HumanMessage, "type">
  )[],
): string | null =>
  findLastMap(messages, (message) =>
    message.type === "ai" ? message.content.caseName : undefined,
  ) || null

export const getCausesForMessage = (
  message: Message,
): MessageCauses | undefined =>
  isAIMessage(message) ? message.causes : undefined

export const getCausesFromMessages = (messages: Message[]): MessageCauses =>
  findLastMap(messages, (message) =>
    isAIMessage(message) ? message.causes : undefined,
  ) ?? {
    confirmed: [],
    veryLikely: [],
    moreLikely: [],
    possible: [],
    unlikely: [],
  }

export const getContentFromMessage = (message: Message): string =>
  isAIMessage(message) ? message.content.question : message.content.content

export const getContentFromLastMessage = (
  messages: Message[],
): string | null => {
  const lastMsg = last(messages)

  if (!lastMsg) return null

  return getContentFromMessage(lastMsg)
}

export const getMediaFromMessage = (message: Message): MessageMedia[] =>
  isAIMessage(message)
    ? [
        ...(message.content.questionImages ?? []),
        ...(message.content.questionVideos ?? []),
        ...(message.content.partTestingVideos ?? []),
        ...(message.content.disassemblyVideos ?? []),
        ...(message.content.partImages?.images ?? []),
      ]
    : []

export const isMessageVideo = (media: MessageMedia): media is MessageVideo =>
  (media as MessageVideo).thumbnailUrl !== undefined
