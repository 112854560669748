import {FC} from "react"

import littleStarBlue from "@frontend/design/icons/little-star-blue-gradient.svg"
import littleStarGrey from "@frontend/design/icons/little-star-grey.svg"
import {hstack} from "@styled-system/patterns"

export const Rating: FC<{
  rating: number | undefined
  reviewsNumber: number
}> = ({rating, reviewsNumber}) => {
  const rateNumber = Math.floor(rating ?? 0)

  const blueStars = new Array(rateNumber)
    .fill(rateNumber)
    .map((_, index) => <img key={index} src={littleStarBlue} alt="" />)
  const greyStars = new Array(5 - rateNumber)
    .fill(0)
    .map((_, index) => <img key={index} src={littleStarGrey} alt="" />)
  return (
    <div className={hstack({gap: 2, textStyle: "button2", color: "fontGrey"})}>
      {rating}
      <div className={hstack({gap: 0})}>
        {blueStars}
        {greyStars}
      </div>
      {`(${reviewsNumber})`}
    </div>
  )
}
