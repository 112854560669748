import type {Image, Product, Video} from "@ri2/rc-db"
import {FC, useRef, useState} from "react"

import {useTranslation} from "@frontend/i18n"
import {Media} from "@frontend/routes/cases/[caseId]/components/media"
import {MediaGallery} from "@frontend/routes/cases/[caseId]/components/media-gallery"
import {trpc, useLookUpModelNumber} from "@frontend/utils/trpc"
import {useBooleanState} from "@frontend/utils/useBooleanState"
import {useEffectOnMount} from "@frontend/utils/useEffectOnMount"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {InputField} from "../input-field"
import {Button} from "../ui/button"

interface Props {
  caseId: string
  modelNumber: string
  onModelNumberChanged: (modelNumber: string) => void
  onFoundProduct: (product: Product | null) => void
}

export const Input: FC<Props> = ({
  caseId,
  modelNumber,
  onModelNumberChanged,
  onFoundProduct,
}) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)

  useEffectOnMount(() => {
    inputRef.current!.focus()
  })

  const lookUpModelNumber = useLookUpModelNumber(onFoundProduct)

  const [isLookingUp, setIsLookingUp] = useState(false)

  const onLookUp = (): void => {
    setIsLookingUp(true)
    lookUpModelNumber.mutate({modelNumber})
  }

  const [{images, videos}] = trpc.modelNumberLookupData.useSuspenseQuery({
    caseId,
  })

  const media: (Image | Video)[] = [...images, ...videos]

  const [selectedMediaUrl, setSelectedMediaUrl] = useState<string | null>(null)

  const {
    setFalse: dismissMediaGallery,
    setTrue: openMediaGallery,
    state: showMediaGallery,
  } = useBooleanState(false)

  const onOpenMediaGallery = (media: Image | Video): void => {
    openMediaGallery()
    setSelectedMediaUrl(getUrlFromMedia(media))
  }

  const initialImageIndex = selectedMediaUrl
    ? media.map(getUrlFromMedia).indexOf(selectedMediaUrl)
    : 0

  const t = useTranslation()

  return (
    <>
      <InputField
        value={modelNumber}
        placeholder={t("addModelNumberModal.input.placeholder")}
        onChange={onModelNumberChanged}
        onEnter={onLookUp}
        inputRef={inputRef}
        rightComponent={
          <Button
            variant="primary"
            title={t("addModelNumberModal.input.lookup")}
            onClick={onLookUp}
            disabled={isLookingUp || !modelNumber}
          />
        }
      />
      <div
        className={vstack({
          marginX: {
            base: "-modalPaddingMobile",
            desktop: "-modalPaddingDesktop",
          },
          marginBottom: {
            base: "-modalPaddingMobile",
            desktop: "-modalPaddingDesktop",
          },
          marginTop: 24,
          padding: {
            base: "modalPaddingMobile",
            desktop: "modalPaddingDesktop",
          },
          backgroundColor: "white",
          borderTopWidth: 1,
          borderTopColor: "border.brand.secondary",

          color: "rgba(89, 92, 96, 1)",
          fontSize: 18,
          fontWeight: 300,
          lineHeight: "26px",
          maxHeight: 300,
          overflowY: "auto",
          gap: 16,
          alignItems: "stretch",
        })}
      >
        {t("conversation.modelNumberLocationInfo")}
        {media.length > 0 && (
          <>
            <div
              className={css({
                width: 79,
                borderColor: "rgba(158, 178, 184, 1)",
                borderStyle: "dashed",
                borderWidth: 1,
                alignSelf: "flex-start",
              })}
            />
            <div>
              <div
                className={hstack({
                  flexWrap: "wrap",
                  gap: 15,
                })}
              >
                {media.map((media) => (
                  <Media
                    media={media}
                    onClick={() => {
                      onOpenMediaGallery(media)
                    }}
                    key={media.url}
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      {showMediaGallery && (
        <MediaGallery
          show
          title={t("addModelNumberModal.title.input")}
          onDismiss={dismissMediaGallery}
          media={media}
          initialImageIndex={initialImageIndex}
        />
      )}
    </>
  )
}

const isVideo = (media: Image | Video): media is Video =>
  !!(media as Video).thumbnailUrl

const getUrlFromMedia = (media: Image | Video): string =>
  isVideo(media) ? media.thumbnailUrl : media.url
