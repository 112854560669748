import {FC} from "react"
import {ReactSVG} from "react-svg"

import check from "@frontend/design/icons/check.svg"
import copy from "@frontend/design/icons/copy.svg"
import {useTranslation} from "@frontend/i18n"
import {useCopyText} from "@frontend/utils/useCopyText"
import {css} from "@styled-system/css"
import {iconsColor} from "@styled-system/patterns"

import {DarkButton} from "./DarkButton"

interface Props {
  url: string
}
export const CopyLinkButton: FC<Props> = ({url}) => {
  const t = useTranslation()
  const [isCopied, onClickToCopy] = useCopyText(url)

  return (
    <DarkButton
      icon={
        <ReactSVG
          src={isCopied ? check : copy}
          className={iconsColor({color: "white"})}
        />
      }
      title={isCopied ? t("case.copied") : t("case.copyLink")}
      onClick={onClickToCopy}
      css={css.raw({
        width: "100%",
        justifyContent: "center",
        desktop: {
          width: "fit-content",
          justifyContent: "unset",
        },
        minWidth: "max-content",
      })}
    />
  )
}
