import {AnimatePresence, motion} from "framer-motion"
import {FC} from "react"
import {ReactSVG} from "react-svg"

import {hoverAndPressedStyles} from "@frontend/components"
import {TooltipWrapper} from "@frontend/components/ui/tooltip"
import {css, cx} from "@styled-system/css"
import {hstack, iconsColor, vstack} from "@styled-system/patterns"

const COLOR = "token(colors.foreground.brand.primary)"

interface Props {
  icon: string
  title: string
  onClick: () => void
  isSidebarOpen: boolean
}

export const SidebarButton: FC<Props> = ({
  icon,
  title,
  isSidebarOpen,
  onClick,
}) => (
  <div
    className={hstack({
      gap: 16,
      justifyContent: "flex-start",
      width: "100%",
      cursor: "pointer",
    })}
  >
    <TooltipWrapper variant="dark" content={title} spaceFromTrigger={0}>
      <button
        onClick={onClick}
        aria-label={title}
        className={hstack({
          gap: 16,
        })}
      >
        <ReactSVG
          src={icon}
          className={cx(
            vstack({
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "8px",
              backgroundColor: "background.brand.primarySubtle",
              width: 44,
              minWidth: 44,
              height: 44,
              padding: 8,
            }),
            hoverAndPressedStyles({overlay: "black"}),
            iconsColor({color: COLOR}),
          )}
        />
        <AnimatePresence initial={false}>
          <motion.span
            animate={isSidebarOpen ? "open" : "collapsed"}
            variants={{
              open: {
                opacity: 1,
                transition: {duration: 0.1, delay: 0.3},
                display: "block",
              },
              collapsed: {
                opacity: 0,
                transition: {duration: 0.1},
                display: "none",
              },
            }}
            className={css({
              textStyle: "captionRegular",
              color: COLOR,
            })}
          >
            {title}
          </motion.span>
        </AnimatePresence>
      </button>
    </TooltipWrapper>
  </div>
)
