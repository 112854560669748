import {FC, useEffect} from "react"
import {useNavigate} from "react-router-dom"

import {makePath} from "./routing"

export const RedirectToLandingPage: FC = () => {
  const navigate = useNavigate()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    navigate(makePath())
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return null
}
