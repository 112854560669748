import type {DiyStep} from "@ri2/rc-db"
import {TFunction} from "i18next"

export const getStepTitle = (step: DiyStep, n: number, t: TFunction): string =>
  step.name
    ? t("cause.diy.guide.stepNumberAndName", {
        number: n,
        name: step.name,
      })
    : t("cause.diy.guide.stepNumber", {number: n})

export const getStepItemTitle = (
  step: DiyStep,
  n: number,
  t: TFunction,
): string =>
  step.name
    ? t("cause.diy.guide.stepItemNumberAndName", {
        number: n,
        name: step.name,
      })
    : t("cause.diy.guide.stepNumber", {number: n})
