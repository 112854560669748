export const ROOT = "/ri2"
export const DIY_SEGMENT = "diy"
export const DIY_VIDEOS_SEGMENT = "videos"
export const DIY_DOCUMENTS_SEGMENT = "documents"
export const DIY_STEPS = "steps"

export const DIFM_SEGMENT = "difm"

export type CaseTab = "conversation" | "causes"

export type PathOptions =
  | {
      name: "cases"
    }
  | {
      name: "cases"
      caseId: string
      tab?: CaseTab
      text?: string
    }
  | {
      name: "cases"
      caseId: string
      causeOnMessageId: number
    }
  | {
      name: "cases"
      caseId: string
      causeOnMessageId: number
      diy:
        | true
        | typeof DIY_STEPS
        | typeof DIY_VIDEOS_SEGMENT
        | typeof DIY_DOCUMENTS_SEGMENT
    }
  | {
      name: "cases"
      caseId: string
      causeOnMessageId: number
      difm: true
    }

export const makePath = (options?: PathOptions): string => {
  if (!options) return ROOT

  const segments = []
  if ("caseId" in options) {
    if ("tab" in options) {
      segments.push(`${options.caseId}?tab=${options.tab}`)
    } else if ("text" in options) {
      segments.push(`${options.caseId}?text=${options.text}`)
    } else {
      segments.push(options.caseId)
    }
  }

  if ("causeOnMessageId" in options) {
    segments.push(String(options.causeOnMessageId))
  }
  if ("diy" in options) {
    segments.push(DIY_SEGMENT)
    if (options.diy !== true) segments.push(options.diy)
  }
  if ("difm" in options) {
    segments.push(DIFM_SEGMENT)
  }

  return [ROOT, ...segments].join("/")
}
