import {cva} from "@styled-system/css"
import {SystemStyleObject} from "@styled-system/types"

export const grid2 = cva({
  base: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridColumnGap: 20,
  },
})

export const grid8 = cva({
  base: {
    display: "grid",
    gridTemplateColumns: "repeat(8, 1fr)",
    gridColumnGap: 20,
  },
})

export const grid9 = cva({
  base: {
    display: "grid",
    gridTemplateColumns: "repeat(9, 1fr)",
    gridColumnGap: 20,
  },
})

export const grid12 = cva({
  base: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridColumnGap: 20,
  },
})

const mainGridCss = {
  flex: 1,
  display: "grid",
  gridTemplateColumns: {
    base: "repeat(5, 1fr)",
    desktop: "repeat(6, minmax(0px, 1fr)) repeat(1, minmax(286px, 1fr))",
    largeDesktop: "repeat(12, 1fr)",
  },
  gridColumnGap: {
    base: 20,
    desktop: 20,
    largeDesktop: 20,
  },
  marginX: "auto",
  fontSize: 16,
} as const

export const mainGrid = cva({
  base: mainGridCss,
})

export const mainGridIfDesktop = cva({
  base: {
    display: "flex",
    flexDirection: "column",
    largeDesktop: mainGridCss,
  },
})

export const roundedContainerWithBorder = cva({
  base: {
    borderRadius: 12,
    borderWidth: 1,
    borderColor: "lineGrey",
    overflow: "hidden",
    backgroundColor: "white",
  },
})

export const header = cva({
  base: {
    marginBottom: 48,
  },
  variants: {
    layout: {
      flex: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      },
      grid: mainGridCss,
    },
  },
  defaultVariants: {
    layout: "flex",
  },
})

export const aiBlueGradientBackground = cva({
  base: {
    bgGradient: "linear-gradient(135deg, #00A3E0 0%, #0041C2 100%)",
  },
})

export const aiRedGradientBackground = cva({
  base: {
    bgGradient: "linear-gradient(134deg, #FF8784 -1.91%, #FF2C56 100%)",
  },
})

export const coloringIconsCss = (color: string): SystemStyleObject => ({
  "& [stroke]": {
    stroke: color,
  },
  "& [fill] [fill]:not([fill='none'])": {
    fill: color,
  },
})

export const iconsColor = cva({
  base: {},
  variants: {
    color: {
      lightBlue: {},
      lightYellow: {},
      lightRed: {},
      blue: {},
      white: {},
      black: {},
      green: {},
      red: {},
      darkGrey: {},
      darkBlue: {},
      royalBlue: {},
      yellow: {},
    },
  },
  compoundVariants: [
    {
      color: "blue",
      css: coloringIconsCss("blue"),
    },
    {
      color: "lightBlue",
      css: coloringIconsCss("lightBlue"),
    },
    {
      color: "lightYellow",
      css: coloringIconsCss("rgba(214, 65, 18, 1)"),
    },
    {
      color: "yellow",
      css: coloringIconsCss("yellow"),
    },
    {
      color: "lightRed",
      css: coloringIconsCss("lightRed"),
    },
    {
      color: "white",
      css: coloringIconsCss("white"),
    },
    {
      color: "black",
      css: coloringIconsCss("black"),
    },
    {
      color: "green",
      css: coloringIconsCss("fontGreen"),
    },
    {
      color: "red",
      css: coloringIconsCss("fontRed"),
    },
    {
      color: "darkGrey",
      css: coloringIconsCss("darkGrey"),
    },
    {
      color: "darkBlue",
      css: coloringIconsCss("darkBlue"),
    },
    {
      color: "royalBlue",
      css: coloringIconsCss("royalBlue"),
    },
  ],
})
