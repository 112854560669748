import {useEffect, useState} from "react"

const COPY_CONFIRMATION_TIMEOUT = 2000

export const useCopyText = (text: string): [boolean, () => void] => {
  const [isCopied, setIsCopied] = useState(false)
  const onClick = (): void => {
    window.navigator.clipboard.writeText(text)
    setIsCopied(true)
  }

  useEffect(() => {
    if (!isCopied) return

    const timeout = setTimeout(() => {
      setIsCopied(false)
    }, COPY_CONFIRMATION_TIMEOUT)

    return (): void => {
      clearTimeout(timeout)
    }
  }, [isCopied])

  return [isCopied, onClick]
}
