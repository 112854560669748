import {FC} from "react"

import diyEmptyIcon from "@frontend/design/icons/diy-empty.svg"
import {css, cx} from "@styled-system/css"
import {vstack} from "@styled-system/patterns"

interface Props {
  message: string
  className?: string
}

export const Empty: FC<Props> = ({message, className}) => (
  <div
    className={cx(
      vstack({
        gap: 16,
        justifyContent: "center",
        minHeight: 220,
        width: "100%",
      }),
      className,
    )}
  >
    <img src={diyEmptyIcon} alt="" />
    <p
      className={css({
        textStyle: "caption",
        textAlign: "center",
        color: "fontBlack",
      })}
    >
      {message}
    </p>
  </div>
)
