import {Dialog, Portal} from "@ark-ui/react"
import React, {FC, useEffect, useRef, useState} from "react"
import {ReactSVG} from "react-svg"

import {aiBlueGradientBackground, iconsColor} from "@frontend/design"
import close from "@frontend/design/icons/close.svg"
import star from "@frontend/design/icons/star.svg"
import {useTranslation} from "@frontend/i18n"
import {useSession} from "@frontend/session"
import {getAppRoot} from "@frontend/utils/getAppRoot"
import {useRequestBetaAccessMutation} from "@frontend/utils/trpc"
import {css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {Button, IconButton} from "./ui/button"

interface Props {
  onDismiss: () => void
  hasRequestedBetaAccess: boolean
}

export const SplashScreen: FC<Props> = ({
  onDismiss,
  hasRequestedBetaAccess,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setHasRendered] = useState(false)

  useEffect(() => {
    setHasRendered(true)
  }, [])

  const appRoot = getAppRoot()
  const appRootRef = useRef(appRoot)
  appRootRef.current = appRoot

  if (!appRoot) {
    return
  }

  return (
    <Dialog.Root
      open={true}
      closeOnEscapeKeyDown={false}
      closeOnInteractOutside={false}
      lazyMount
      onOpenChange={({open}) => {
        if (!open) {
          onDismiss()
        }
      }}
    >
      <Portal container={appRootRef}>
        <Dialog.Backdrop
          className={cx(
            css({
              position: "fixed",
              inset: 0,
              backgroundColor: "rgba(0, 0, 0, 0.86)",
              opacity: {
                base: 1,
              },
              backdropFilter: "blur(6.3px)",
              zIndex: "modalBackdrop",
            }),
          )}
        />

        <Dialog.Positioner
          onClick={onDismiss}
          className={cx(
            css({
              position: "fixed",
              inset: 0,
              zIndex: "splashScreen",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }),
          )}
        >
          <Dialog.Content
            onClick={(e) => e.stopPropagation()}
            className={cx(
              css({
                position: "relative",
                backgroundColor: "lightBlue",
                width: "343px",
                display: "flex",
                flexDirection: "column",
                textStyle: "body",
                height: "min-content",
                paddingTop: 16,
                paddingBottom: 16,
                minWidth: 0,
                borderRadius: 12,
                desktop: {
                  marginX: 20,
                  marginY: 20,
                  paddingY: 21,
                  width: "529px",
                  overflowY: "hidden",
                  height: "383px",
                },
              }),
            )}
          >
            <div
              className={css({
                position: "absolute",
                top: 16,
                right: 16,
              })}
            >
              <IconButton
                variant="tertiaryBrand"
                icon={
                  <ReactSVG
                    src={close}
                    className={iconsColor({color: "white"})}
                  />
                }
                onClick={onDismiss}
              />
            </div>
            <div
              className={cx(
                hstack({
                  width: "110px",
                  height: "105px",
                  marginTop: 35,
                  marginX: 116,
                  desktop: {
                    marginLeft: 205,
                    marginRight: 214,
                    marginTop: 64,
                  },
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: 23,
                }),
                aiBlueGradientBackground(),
              )}
            >
              <img src={star} width={52} height={52} />
            </div>
            {hasRequestedBetaAccess ? (
              <ThankYouForSigningUp />
            ) : (
              <SignUpForBetaAccess />
            )}
          </Dialog.Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  )
}

const SignUpForBetaAccess: FC = () => {
  const t = useTranslation()
  const {userRcId} = useSession()

  const [email, setEmail] = useState("")
  const requestBetaAccessMutation = useRequestBetaAccessMutation()

  const disabled =
    !email.trim() ||
    requestBetaAccessMutation.isLoading ||
    !validateEmail(email)
  const onSubmit = (): void => {
    requestBetaAccessMutation.mutate({userRcId, email})
  }

  const onSubmitForm = (e: React.FormEvent): void => {
    e.preventDefault()
    onSubmit()
  }
  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value)
  }
  return (
    <>
      <div
        className={vstack({
          alignItems: "center",
          gap: 0,
          marginTop: 35,
          marginX: 20,
          desktop: {
            marginX: 68,
            minWidth: 393,
            marginTop: 32,
            height: 73,
          },
        })}
      >
        <h1
          className={css({
            color: "#FCE4BD",
            textAlign: "center",
            letterSpacing: "0.07em",
          })}
        >
          {t("betaAccess.splashScreen.title")}
        </h1>
        <p
          className={css({
            color: "white",
            textAlign: "center",
            textStyle: "body",
            desktop: {
              textAlign: "center",
            },
          })}
        >
          {t("betaAccess.splashScreen.description")}
        </p>
      </div>
      <form
        onSubmit={onSubmitForm}
        className={hstack({
          flexDirection: "column",
          marginX: 16,
          marginTop: 35,
          desktop: {
            flexDirection: "row",
            marginLeft: 30,
            marginRight: 25,
            marginTop: 41,
            width: 474,
          },
        })}
      >
        <input
          type="email"
          placeholder={t("betaAccess.splashScreen.emailInputPlaceholder")}
          onChange={onChange}
          className={css({
            borderRadius: 10,
            paddingX: 14,
            paddingY: 13,
            height: 46,
            width: "100%",
            textStyle: "body",
            color: "fontBlack",
            outline: "none",
            desktop: {
              width: 345,
            },
          })}
        />
        <Button
          variant="primary"
          size="large"
          disabled={disabled}
          title={t("betaAccess.splashScreen.signUp")}
          css={css.raw({
            minHeight: 46,
            minWidth: "100%",
            desktop: {
              minWidth: 120,
            },
          })}
          onClick={onSubmit}
        />
        <input type="submit" hidden />
      </form>
    </>
  )
}

const ThankYouForSigningUp: FC = () => {
  const t = useTranslation()
  return (
    <div
      className={vstack({
        alignItems: "center",
        gap: 0,
        marginTop: 35,
        marginX: 20,
        desktop: {
          marginX: 68,
          minWidth: 393,
          marginTop: 32,
          height: 73,
        },
      })}
    >
      <h1
        className={css({
          color: "#FCE4BD",
          textAlign: "center",
          letterSpacing: "0.07em",
        })}
      >
        {t("betaAccess.splashScreen.thankYouTitle")}
      </h1>
      <p
        className={css({
          color: "white",
          textAlign: "center",
          textStyle: "body",
          desktop: {
            textAlign: "center",
          },
        })}
      >
        {t("betaAccess.splashScreen.thankYouDescription")}
      </p>
    </div>
  )
}

const validateEmail = (email: string): boolean => {
  const emailRegex = /\S+@\S+\.\S+/
  return emailRegex.test(email)
}
