import {FC} from "react"
import {useNavigate} from "react-router-dom"

import add from "@frontend/design/icons/add.svg"
import {useTranslation} from "@frontend/i18n"
import {makePath} from "@frontend/routing"

import {SidebarButton} from "./sidebar-button"

interface Props {
  isSidebarOpen: boolean
  afterClick: () => void
}

export const CreateNewCaseButton: FC<Props> = ({isSidebarOpen, afterClick}) => {
  const navigate = useNavigate()

  const onClick = (): void => {
    navigate(makePath())
    afterClick()
  }

  const t = useTranslation()

  return (
    <SidebarButton
      icon={add}
      title={t("appContainer.header.newCase")}
      onClick={onClick}
      isSidebarOpen={isSidebarOpen}
    />
  )
}
