import {FC, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import {ReactSVG} from "react-svg"

import {Button} from "@frontend/components"
import loginBackground from "@frontend/design/icons/login-bg-pattern.svg"
import logo from "@frontend/design/icons/logo.svg"
import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {Input} from "./input"
import {useLogin} from "./useLogin"
import {useIsSignedIn} from "../landing-page/useIsSignedIn"

export const Login: FC = () => {
  const navigate = useNavigate()
  const t = useTranslation()
  const {email, password, handleSubmit, error} = useLogin()
  const {isLoggedIn, isLoading} = useIsSignedIn()

  useEffect(() => {
    if (isLoggedIn && !isLoading) {
      navigate("/")
    }
  }, [isLoading, isLoggedIn, navigate])

  return (
    <div
      className={css({
        width: "100%",
        height: "100vh",
        display: "flex",
      })}
    >
      <div
        className={hstack({
          width: {
            base: "100%",
            desktop: "62%",
          },
          position: "relative",
          height: "100%",
          backgroundColor: "background.primary",
          justifyContent: "center",
        })}
      >
        <div
          className={vstack({
            height: "100%",
            width: {
              base: "100%",
              desktop: "min(543px, 100%)",
            },
            paddingTop: 122,
            paddingX: {
              base: "32px",
              largeDesktop: 0,
            },
            alignItems: "flex-start",
            gap: 0,
          })}
        >
          <img src={logo} alt="logo" />
          <h1
            className={css({
              textStyle: "header.l",
              color: "text.primary",
              marginTop: {
                base: 48,
                desktop: 93,
              },
            })}
          >
            {t("entryApp.login.title")}
          </h1>
          <form className={css({width: "100%"})} onSubmit={handleSubmit}>
            <fieldset className={css({border: "none"})}>
              <Input
                css={css.raw({marginTop: 31})}
                placeholder={t("entryApp.login.email")}
                type="email"
                {...email}
              />
            </fieldset>
            <fieldset className={css({border: "none"})}>
              <Input
                css={css.raw({
                  marginTop: {
                    base: 8,
                    desktop: 23,
                  },
                })}
                placeholder={t("entryApp.login.password")}
                type="password"
                {...password}
              />
              {error && (
                <p
                  className={css({
                    color: "text.error.primary",
                    marginTop: 8,
                  })}
                >
                  {t("entryApp.login.error")}
                </p>
              )}
            </fieldset>
            <Button
              variant="primary"
              onClick={() => {}}
              size="large"
              buttonType="submit"
              css={css.raw({marginTop: 31})}
              title={t("entryApp.login.signIn")}
              disabled={!email.value || !password.value}
            />
          </form>
          {/* Hide this button until the forgot password functionality is implemented */}
          <Button
            variant="tertiaryBrand"
            onClick={() => ({})}
            css={css.raw({
              marginTop: {
                base: 8,
                desktop: 31,
              },
              display: "none",
            })}
            size="large"
            title={t("entryApp.login.forgotPassword")}
          />
        </div>
      </div>
      <div
        className={css({
          width: {
            base: "0%",
            desktop: "38%",
          },
          height: "100%",
          overflow: "hidden",
          backgroundColor: "background.brand.secondary",
        })}
      >
        <ReactSVG
          src={loginBackground}
          className={css({
            width: "100%",
            height: "100vh",
            "& svg": {
              transform: "translateX(-40%)",
              width: "100vw",
              height: "100vh",
            },
          })}
        />
      </div>
    </div>
  )
}
