import {FC} from "react"

import {Tag} from "@frontend/components"
import shieldPositive from "@frontend/design/icons/shield-positive.svg"
import {useTranslation} from "@frontend/i18n"
import {RecommendationReason} from "@ri2/db/client"
import {css} from "@styled-system/css"

export const Reason: FC<{
  reason: RecommendationReason
}> = ({reason}) => {
  const t = useTranslation()
  return (
    <Tag
      title={t(`cause.nextStep.recomendationReason.${reason}`)}
      icon={shieldPositive}
      colorScheme="positivePrimary"
      className={css({
        alignSelf: "flex-start",
      })}
    />
  )
}
