import {useNavigate} from "react-router-dom"

import {API_BASE_URL} from "@frontend/env"

export const useSignOut = (): {signOut: () => Promise<void>} => {
  const navigate = useNavigate()
  const signOut = async (): Promise<void> => {
    const response = await fetch(`${API_BASE_URL}/logout`, {
      method: "POST",
      credentials: "include",
    })
    if (response?.ok) {
      navigate("/login")
    }
  }
  return {signOut}
}
