import {isEqual} from "lodash"
import {useRef} from "react"

export const useStabilizedValue = <T>(value: T): T => {
  const ref = useRef<T>(value)
  const previousValue = ref.current

  if (previousValue === value || isEqual(previousValue, value)) {
    return previousValue
  }

  ref.current = value
  return value
}
