import {FC} from "react"

import {css} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {Chip} from "../ui/chip"

interface OptionsProps {
  prompt: string
  selectedOptions: Record<string, boolean>
  onToggleOption: (option: string) => void
}

export const Options: FC<OptionsProps> = ({
  prompt,
  selectedOptions,
  onToggleOption,
}) => (
  <>
    <p
      className={css({
        textStyle: "body",
        color: "fontGrey",
      })}
    >
      {prompt}
    </p>
    <div
      className={hstack({
        flexWrap: "wrap",
        paddingY: 8,
      })}
    >
      {Object.keys(selectedOptions).map((option, index) => (
        <Chip
          type={selectedOptions[option] ? "chosen" : "unchosen"}
          title={option}
          onClick={() => {
            onToggleOption(option)
          }}
          className={css({
            flexShrink: 0,
          })}
          key={index}
        />
      ))}
    </div>
  </>
)
