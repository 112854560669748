import {FC} from "react"
import {ReactSVG} from "react-svg"

import {RecipeVariantProps, css, cva, cx} from "@styled-system/css"

export type TagColorScheme = RecipeVariantProps<typeof styles>
interface Props {
  title: string
  icon?: string
  noIconColoring?: boolean
  className?: string
}

const styles = cva({
  base: {
    textStyle: "captionRegular",
    borderRadius: 8,
    paddingX: 8,
    paddingY: 4,
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  variants: {
    colorScheme: {
      blue: {
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "lightBlue",
        "& span": {
          color: "transparent",
          bgGradient: "linear-gradient(135deg, #00A3E0 0%, #0041C2 100%)",
          backgroundClip: "text",
        },
      },
      yellow: {
        borderWidth: 1,
        borderColor: "yellow.400",
        color: "yellow.400",
      },
      lightBlue: {},
      lightGrayBlue: {},
      lightYellow: {},
      lightRed: {},
      orange: {},
      positivePrimary: {
        color: "text.brand.primary",
        backgroundColor: "background.brand.primary",
      },
      positive: {
        color: "text.brand.primary",
        backgroundColor: "background.brand.primarySubtle",
      },
      warning: {
        color: "text.brand.accent",
        backgroundColor: "background.warn",
      },
      error: {
        color: "text.error.primary",
        backgroundColor: "background.error",
      },
    },
    transparent: {
      true: {
        color: "white",
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      colorScheme: "lightBlue",
      transparent: true,
      css: {
        backgroundColor: "rgba(231, 240, 251, 0.1)",
        color: "white",
      },
    },
    {
      colorScheme: "lightBlue",
      transparent: false,
      css: {
        backgroundColor: "bgBlue",
        color: "lightBlue",
      },
    },
    {
      colorScheme: "lightGrayBlue",
      transparent: false,
      css: {
        backgroundColor: "#E5E6E6",
        color: "lightBlue",
      },
    },
    {
      colorScheme: "lightYellow",
      transparent: true,
      css: {
        backgroundColor: "rgba(255, 248, 168, 0.2)",
        color: "rgba(242, 183, 38, 1)",
      },
    },
    {
      colorScheme: "lightYellow",
      transparent: false,
      css: {
        backgroundColor: "rgba(251, 241, 231, 1)",
        color: "rgba(214, 65, 18, 1)",
      },
    },
    {
      colorScheme: "lightRed",
      transparent: true,
      css: {
        backgroundColor: "rgba(255, 204, 210, 0.19)",
        color: "rgba(255, 96, 112, 1)",
      },
    },
    {
      colorScheme: "lightRed",
      transparent: false,
      css: {
        backgroundColor: "bgRed",
        "& span": {
          color: "transparent",
          bgGradient: "linear-gradient(134deg, #FF8784 -1.91%, #FF2C56 100%)",
          backgroundClip: "text",
        },
      },
    },
    {
      colorScheme: "lightRed",
      transparent: false,
      css: {
        backgroundColor: "bgRed",
        "& span": {
          color: "transparent",
          bgGradient: "linear-gradient(134deg, #FF8784 -1.91%, #FF2C56 100%)",
          backgroundClip: "text",
        },
      },
    },
    {
      colorScheme: "orange",
      transparent: false,
      css: {
        backgroundColor: "transparent",
        color: "text.brand.accent",
        borderWidth: 1,
        borderColor: "border.brand.accent",
        borderStyle: "solid",
      },
    },
    {
      colorScheme: "positive",
      transparent: true,
      css: {
        backgroundColor: "background.tagTransparent",
        color: "base.white",
      },
    },
    {
      colorScheme: "warning",
      transparent: true,
      css: {
        backgroundColor: "background.tagTransparent",
        color: "text.warning.primary",
      },
    },
    {
      colorScheme: "error",
      transparent: true,
      css: {
        backgroundColor: "background.tagTransparent",
        color: "text.error.primary",
      },
    },
  ],
})

export const Tag: FC<Props & TagColorScheme> = ({
  title,
  icon,
  colorScheme,
  transparent = false,
  className,
}) => (
  <div className={cx(styles({colorScheme, transparent}), className)}>
    {icon && (
      <ReactSVG
        src={icon}
        className={cx(
          css({
            height: 18,
            width: 18,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }),
        )}
      />
    )}
    <span>{title}</span>
  </div>
)
