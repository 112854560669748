import {FC} from "react"

import {ReactionsWithForm} from "@frontend/components"
import {useTranslation} from "@frontend/i18n"
import {RemediationType} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {RecommendationBasedOn} from "./based-on"
import {Remediation} from "./remediation"
import {REMEDIATION_ORDER, RemediationProps} from "./types"

export const MobileRemediations: FC<RemediationProps> = ({
  getRemediation,
  recommendedRemediationType,
  caseModelId,
  causeOnMessageId,
  remediationHashId,
  recommendation,
  reasons,
  openModal,
}) => {
  const t = useTranslation()

  const ADDITIONAL_REMEDIATION_ORDER: RemediationType[] =
    recommendedRemediationType
      ? REMEDIATION_ORDER.filter((key) => key !== recommendedRemediationType)
      : REMEDIATION_ORDER

  const recommendedRemediation = recommendedRemediationType
    ? getRemediation(recommendedRemediationType)
    : null

  const headlineStyles = css({
    textStyle: "header.xs",
    color: "text.primary",
  })

  const reactionStyles = css({
    gridColumn: "span 3",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    minWidth: 283,
    height: 292,
    width: "100%",
  })
  return (
    <div
      className={css({
        display: {
          base: "flex",
          desktop: "none",
        },
        flexDirection: "column",
        paddingBottom: 16,
        paddingTop: 32,
        paddingX: 28,
      })}
    >
      <div
        className={cx(
          recommendedRemediation
            ? hstack({justifyContent: "space-between"})
            : hstack({justifyContent: "flex-end"}),
          css({
            desktop: {
              display: "none",
            },
            marginBottom: 16,
          }),
        )}
      >
        {!!recommendedRemediation && (
          <h4 className={headlineStyles}>
            {t("cause.nextStep.recommendation")}
          </h4>
        )}
        <ReactionsWithForm
          on={{
            on: "remediation",
            remediationHashId,
            type: "RECOMMENDATION",
          }}
          currentFeedback={recommendation}
        />
      </div>
      <div>
        <RecommendationBasedOn
          reasons={reasons}
          recommendation={recommendation}
          remediationHashId={remediationHashId}
          openModal={openModal}
        />
      </div>
      <div
        className={hstack({
          display: {base: "flex", largeDesktop: "contents"},
          paddingTop: 24,
          overflowX: "visible",
          maxWidth: "100vw",
          flexWrap: "nowrap",
          flexShrink: 0,
          paddingBottom: 16,
          gap: 16,
          desktopDown: {
            flexDirection: "column",
          },
          "& > div": {
            width: "100%",
          },
        })}
      >
        {recommendedRemediation && (
          <Remediation
            recommended
            caseId={caseModelId}
            causeOnMessageId={causeOnMessageId}
            showActionButton
            className={reactionStyles}
            {...recommendedRemediation}
          />
        )}
        {!!recommendedRemediation && (
          <div
            className={hstack({
              width: "100%",
              justifyContent: "flex-start",

              desktop: {
                display: "none",
              },
            })}
          >
            <h4
              className={cx(
                headlineStyles,
                css({
                  marginTop: 24,
                }),
              )}
            >
              {t("cause.nextStep.additionalOptions")}
            </h4>
          </div>
        )}
        {ADDITIONAL_REMEDIATION_ORDER.map((key) => {
          const currentRemediation = getRemediation(key)

          if (!currentRemediation) {
            return null
          }

          return (
            <Remediation
              key={key}
              recommended={key === recommendedRemediationType}
              caseId={caseModelId}
              causeOnMessageId={causeOnMessageId}
              showActionButton
              className={reactionStyles}
              {...currentRemediation}
            />
          )
        })}
      </div>
    </div>
  )
}
