import {useEffect, useState} from "react"

import {MessageMedia} from "@ri2/db/client"

export const useMultimediaSlider = (
  mediaItems: MessageMedia[],
  initialIndex: number,
): {
  nextMedia: () => void
  previousMedia: () => void
  currentMediaIndex: number
} => {
  const [currentMediaIndex, setCurrentMedia] = useState(initialIndex)

  useEffect(() => {
    setCurrentMedia(initialIndex)
  }, [initialIndex, mediaItems])

  const nextMedia = (): void => {
    if (currentMediaIndex < mediaItems.length - 1) {
      setCurrentMedia(currentMediaIndex + 1)
    } else if (currentMediaIndex === mediaItems.length - 1) {
      setCurrentMedia(0)
    }
  }

  const previousMedia = (): void => {
    if (currentMediaIndex > 0) {
      setCurrentMedia(currentMediaIndex - 1)
    } else if (currentMediaIndex === 0) {
      setCurrentMedia(mediaItems.length - 1)
    }
  }

  return {
    nextMedia,
    previousMedia,
    currentMediaIndex,
  }
}
