import {FC} from "react"

import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

interface MultipleChoiceOptionsProps {
  options: string[]
  onChooseOption: (option: string) => void
}

export const MultipleChoiceOptions: FC<MultipleChoiceOptionsProps> = ({
  options,
  onChooseOption,
}) => {
  const t = useTranslation()
  return (
    <>
      <span
        className={css({
          color: "text.tertiary",
          textStyle: "captionRegular",
        })}
      >
        {t("conversation.quickResponses")}
      </span>
      <div className={hstack({justifyContent: "right"})}>
        <div
          className={css({
            display: "flex",
            flexDirection: "row",
            gap: 8,
            flexWrap: "wrap",
            justifyContent: "flex-end",
          })}
        >
          {options.map((option) => (
            <button
              className={css({
                borderRadius: "24px 24px 4px 24px",
                borderWidth: 2,
                borderColor: "border.brand.primary",
                color: "text.brand.primary",
                paddingY: 10,
                paddingX: 16,
                backgroundColor: "background.brand.primarySubtle",
                textStyle: "bodyLargeMedium",
              })}
              onClick={(): void => {
                onChooseOption(option)
              }}
              key={option}
            >
              {option}
            </button>
          ))}
        </div>
      </div>
    </>
  )
}
