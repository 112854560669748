export const SHADOW_HOST_ID = "shadow-host"
export const APP_ROOT_ID = "app-root"

export const getAppRoot = (): HTMLElement => {
  const shadowHost = document.querySelector(`#${SHADOW_HOST_ID}`)!
  const shadowRoot = shadowHost.shadowRoot!
  const appRoot: HTMLElement = shadowRoot.querySelector(`#${APP_ROOT_ID}`)!

  return appRoot
}
