import {FC} from "react"

import consentIcon from "@frontend/design/icons/consent.svg"
import {useTranslation} from "@frontend/i18n"
import {ConsentModal} from "@frontend/routes/cases/[caseId]/components/consent-modal"
import {useBooleanState} from "@frontend/utils/useBooleanState"

import {SidebarButton} from "./sidebar-button"

interface Props {
  isSidebarOpen: boolean
  afterClick: () => void
}

export const ConsentButton: FC<Props> = ({isSidebarOpen, afterClick}) => {
  const {
    state: isShowingModal,
    setTrue: onShowModal,
    setFalse: onCloseModal,
  } = useBooleanState(false)

  const onClick = (): void => {
    onShowModal()
    afterClick()
  }

  const t = useTranslation()

  return (
    <>
      <ConsentModal onClose={onCloseModal} show={isShowingModal} />
      <SidebarButton
        icon={consentIcon}
        title={t("appContainer.sidebar.consent")}
        onClick={onClick}
        isSidebarOpen={isSidebarOpen}
      />
    </>
  )
}
