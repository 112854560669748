import {Player} from "@lottiefiles/react-lottie-player"
import {FC, PropsWithChildren} from "react"

import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

export const Overview: FC = () => {
  const t = useTranslation()
  return (
    <div
      className={hstack({
        width: "100%",
        minHeight: 552,
        backgroundColor: "background.brand.solidSubtle",
        paddingTop: 72,
        justifyContent: "center",
        alignItems: "flex-start",
        largeDesktopDown: {
          paddingBottom: 64,
        },
      })}
    >
      <div
        className={vstack({
          justifyContent: "left",
        })}
      >
        <span
          className={css({
            textStyle: "header.m",
            color: "text.primary.onSolid",
            paddingBottom: 34,
            width: "100%",
            maxWidth: 810,
            largeDesktopDown: {
              paddingX: 32,
            },
            desktopDown: {
              textStyle: "header.s",
            },
          })}
        >
          {t("entryApp.landingPage.overview.title")}
        </span>
        <div
          className={hstack({
            gap: 32,
            flexWrap: "wrap",
            paddingLeft: 32,
            paddingRight: 29,
            justifyContent: "center",
          })}
        >
          <OverviewCard
            title={t("entryApp.landingPage.overview.precisionDiagnosis.title")}
            description={t(
              "entryApp.landingPage.overview.precisionDiagnosis.description",
            )}
          >
            <Player
              autoplay
              loop
              src={
                "https://lottie.host/dee45cea-05cf-4d96-b44b-102529d25f67/rR4bz8swwv.json"
              }
            />
          </OverviewCard>
          <OverviewCard
            title={t("entryApp.landingPage.overview.repairRecomendation.title")}
            description={t(
              "entryApp.landingPage.overview.repairRecomendation.description",
            )}
          >
            <Player
              autoplay
              loop
              src={
                "https://lottie.host/1b67a2a0-520c-4003-9460-297fa08921a9/OHJyWZiuGn.json"
              }
            />
          </OverviewCard>
          <OverviewCard
            title={t("entryApp.landingPage.overview.stepByStepGuide.title")}
            description={t(
              "entryApp.landingPage.overview.stepByStepGuide.description",
            )}
          >
            <Player
              autoplay
              loop
              src={
                "https://lottie.host/d1f34e03-5af5-41fb-aeb5-2398214a11ca/AMJkEWXJ96.json"
              }
            />
          </OverviewCard>
        </div>
      </div>
    </div>
  )
}

const OverviewCard: FC<
  PropsWithChildren<{title: string; description: string}>
> = ({title, description, children}) => (
  <div className={vstack({gap: 16, maxWidth: 332})}>
    {children}
    <div className={css({width: "100%"})}>
      <h4
        className={css({
          textStyle: "bodyXLMedium",
          color: "text.primary.onSolid",
        })}
      >
        {title}
      </h4>
      <p
        className={css({
          textStyle: "bodyLargeLight",
          color: "text.secondary.onSolid",
          maxWidth: "100%",
        })}
      >
        {description}
      </p>
    </div>
  </div>
)
