import {FC, ReactNode} from "react"

import {Styles, css} from "@styled-system/css"

export const DarkButton: FC<{
  onClick: () => void
  title: string
  icon?: ReactNode
  css?: Styles
}> = ({icon, title, onClick, css: cssProp}) => (
  <button
    className={css(
      {
        position: "relative",
        borderRadius: 8,
        textStyle: "captionMedium",
        height: 36,
        display: "flex",
        paddingX: 12,
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.15)",
        color: "white",
        gap: 8,
      },
      cssProp,
    )}
    onClick={onClick}
  >
    {icon}
    {title}
  </button>
)
