import adhesive from "@frontend/design/icons/causes-part-category/adhesive.svg"
import agitator from "@frontend/design/icons/causes-part-category/agitator.svg"
import auger from "@frontend/design/icons/causes-part-category/auger.svg"
import axleRollerShaftWheel from "@frontend/design/icons/causes-part-category/axle_roller_shaft_wheel.svg"
import bearing from "@frontend/design/icons/causes-part-category/bearing.svg"
import belt from "@frontend/design/icons/causes-part-category/belt.svg"
import blade from "@frontend/design/icons/causes-part-category/blade.svg"
import blowerWheelAndFanBlade from "@frontend/design/icons/causes-part-category/blower_wheel_&_fan_blade.svg"
import bracketAndFlange from "@frontend/design/icons/causes-part-category/bracket_&_flange.svg"
import capLidAndCover from "@frontend/design/icons/causes-part-category/cap_lid_&_cover.svg"
import capacitor from "@frontend/design/icons/causes-part-category/capacitor.svg"
import chainAndBar from "@frontend/design/icons/causes-part-category/chain_&_bar.svg"
import circuitBoardAndTimer from "@frontend/design/icons/causes-part-category/circuit_board_&_timer.svg"
import cleanerAndDeodorizer from "@frontend/design/icons/causes-part-category/cleaner_&_deodorizer.svg"
import compressorAndSealedSystem from "@frontend/design/icons/causes-part-category/compressor_&_sealed_system.svg"
import cooktop from "@frontend/design/icons/causes-part-category/cooktop.svg"
import diodeMagnetronAndResistor from "@frontend/design/icons/causes-part-category/diode_magnetron_&_resistor.svg"
import dishrack from "@frontend/design/icons/causes-part-category/dishrack.svg"
import dispenser from "@frontend/design/icons/causes-part-category/dispenser.svg"
import door from "@frontend/design/icons/causes-part-category/door.svg"
import drawer from "@frontend/design/icons/causes-part-category/drawer.svg"
import dripBowlAndDripPan from "@frontend/design/icons/causes-part-category/drip_bowl_&_drip_pan.svg"
import drumAndTub from "@frontend/design/icons/causes-part-category/drum_&_tub.svg"
import ductAndVenting from "@frontend/design/icons/causes-part-category/duct_&_venting.svg"
import fastener from "@frontend/design/icons/causes-part-category/fastener.svg"
import filter from "@frontend/design/icons/causes-part-category/filter.svg"
import fuseThermalFuseAndBreaker from "@frontend/design/icons/causes-part-category/fuse_thermal_fuse_&_breaker.svg"
import gasBurnerAndControlValve from "@frontend/design/icons/causes-part-category/gas_burner_&_control_valve.svg"
import gasketAndSeal from "@frontend/design/icons/causes-part-category/gasket_&_seal.svg"
import glassTrayCouplerAndSupportRoller from "@frontend/design/icons/causes-part-category/glass_tray_coupler_&_support_roller.svg"
import grateAndGriddle from "@frontend/design/icons/causes-part-category/grate_&_griddle.svg"
import grilleAndKickplate from "@frontend/design/icons/causes-part-category/grille_&_kickplate.svg"
import handle from "@frontend/design/icons/causes-part-category/handle.svg"
import heatingElement from "@frontend/design/icons/causes-part-category/heating_element.svg"
import hinge from "@frontend/design/icons/causes-part-category/hinge.svg"
import hoseTubeAndFitting from "@frontend/design/icons/causes-part-category/hose_tube_&_fitting.svg"
import iceMaker from "@frontend/design/icons/causes-part-category/ice_maker.svg"
import igniter from "@frontend/design/icons/causes-part-category/igniter.svg"
import ignitionControls from "@frontend/design/icons/causes-part-category/ignition_controls.svg"
import insulation from "@frontend/design/icons/causes-part-category/insulation.svg"
import knobDialAndButton from "@frontend/design/icons/causes-part-category/knob_dial_&_button.svg"
import latch from "@frontend/design/icons/causes-part-category/latch.svg"
import legFootAndCaster from "@frontend/design/icons/causes-part-category/leg_foot_&_caster.svg"
import lightingAndLightBulb from "@frontend/design/icons/causes-part-category/lighting_&_light_bulb.svg"
import lubricant from "@frontend/design/icons/causes-part-category/lubricant.svg"
import manualsCareGuidesAndLiterature from "@frontend/design/icons/causes-part-category/manuals_care_guides_&_literature.svg"
import miscellaneous from "@frontend/design/icons/causes-part-category/miscellaneous.svg"
import motor from "@frontend/design/icons/causes-part-category/motor.svg"
import ovenRackAndBroilerPan from "@frontend/design/icons/causes-part-category/oven_rack_&_broiler_pan.svg"
import paint from "@frontend/design/icons/causes-part-category/paint.svg"
import panel from "@frontend/design/icons/causes-part-category/panel.svg"
import pedal from "@frontend/design/icons/causes-part-category/pedal.svg"
import powerCord from "@frontend/design/icons/causes-part-category/power_cord.svg"
import pulley from "@frontend/design/icons/causes-part-category/pulley.svg"
import pump from "@frontend/design/icons/causes-part-category/pump.svg"
import remoteControl from "@frontend/design/icons/causes-part-category/remote_control.svg"
import sensorAndThermistor from "@frontend/design/icons/causes-part-category/sensor_&_thermistor.svg"
import shelfAndShelfSupport from "@frontend/design/icons/causes-part-category/shelf_&_shelf_support.svg"
import spring from "@frontend/design/icons/causes-part-category/spring.svg"
import steering from "@frontend/design/icons/causes-part-category/steering.svg"
import suspensionAndShockAbsorption from "@frontend/design/icons/causes-part-category/suspension_&_shock_absorption.svg"
import switchIcon from "@frontend/design/icons/causes-part-category/switch.svg"
import tankAndContainer from "@frontend/design/icons/causes-part-category/tank_&_container.svg"
import thermostat from "@frontend/design/icons/causes-part-category/thermostat.svg"
import tool from "@frontend/design/icons/causes-part-category/tool.svg"
import touchpad from "@frontend/design/icons/causes-part-category/touchpad.svg"
import transformer from "@frontend/design/icons/causes-part-category/transformer.svg"
import transmissionBrakeAndClutch from "@frontend/design/icons/causes-part-category/transmission_brake_&_clutch.svg"
import trim from "@frontend/design/icons/causes-part-category/trim.svg"
import valveAndFloat from "@frontend/design/icons/causes-part-category/valve_&_float.svg"
import washArmAndWashArmSupport from "@frontend/design/icons/causes-part-category/wash_arm_&_wash_arm_support.svg"
import wireReceptacleAndWireConnector from "@frontend/design/icons/causes-part-category/wire_receptacle_&_wire_connector.svg"

export const icons: Record<string, string> = {
  adhesive,
  agitator,
  auger,
  axle_roller_shaft_wheel: axleRollerShaftWheel,
  bearing,
  belt,
  blade,
  "blower_wheel_&_fan_blade": blowerWheelAndFanBlade,
  "bracket_&_flange": bracketAndFlange,
  "cap_lid_&_cover": capLidAndCover,
  capacitor,
  "chain_&_bar": chainAndBar,
  "circuit_board_&_timer": circuitBoardAndTimer,
  "cleaner_&_deodorizer": cleanerAndDeodorizer,
  "compressor_&_sealed_system": compressorAndSealedSystem,
  cooktop,
  "diode_magnetron_&_resistor": diodeMagnetronAndResistor,
  dishrack,
  dispenser,
  door,
  drawer,
  "drip_bowl_&_drip_pan": dripBowlAndDripPan,
  "drum_&_tub": drumAndTub,
  "duct_&_venting": ductAndVenting,
  fastener,
  filter,
  "fuse_thermal_fuse_&_breaker": fuseThermalFuseAndBreaker,
  "gas_burner_&_control_valve": gasBurnerAndControlValve,
  "gasket_&_seal": gasketAndSeal,
  "glass_tray_coupler_&_support_roller": glassTrayCouplerAndSupportRoller,
  "grate_&_griddle": grateAndGriddle,
  "grille_&_kickplate": grilleAndKickplate,
  handle,
  heating_element: heatingElement,
  hinge,
  "hose_tube_&_fitting": hoseTubeAndFitting,
  ice_maker: iceMaker,
  igniter,
  ignition_controls: ignitionControls,
  insulation,
  "knob_dial_&_button": knobDialAndButton,
  latch,
  "leg_foot_&_caster": legFootAndCaster,
  "lighting_&_light_bulb": lightingAndLightBulb,
  lubricant,
  "manuals_care_guides_&_literature": manualsCareGuidesAndLiterature,
  miscellaneous,
  motor,
  "oven_rack_&_broiler_pan": ovenRackAndBroilerPan,
  paint,
  panel,
  pedal,
  power_cord: powerCord,
  pulley,
  pump,
  remote_control: remoteControl,
  "sensor_&_thermistor": sensorAndThermistor,
  "shelf_&_shelf_support": shelfAndShelfSupport,
  spring,
  steering,
  "suspension_&_shock_absorption": suspensionAndShockAbsorption,
  switch: switchIcon,
  "tank_&_container": tankAndContainer,
  thermostat,
  tool,
  touchpad,
  transformer,
  "transmission_brake_&_clutch": transmissionBrakeAndClutch,
  trim,
  "valve_&_float": valveAndFloat,
  "wash_arm_&_wash_arm_support": washArmAndWashArmSupport,
  "wire_receptacle_&_wire_connector": wireReceptacleAndWireConnector,
}
