import {FC} from "react"
import {Route, Routes} from "react-router-dom"

import {LandingPage} from "./landing-page"
import {Login} from "./login"

export const Router: FC<{open: () => void}> = ({open}) => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/" caseSensitive element={<LandingPage open={open} />} />
  </Routes>
)
