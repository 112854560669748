import {FC, ReactNode} from "react"
import {ReactSVG} from "react-svg"

import riIconInfoDark from "@frontend/design/icons/ri-icon-info-dark.svg"
import riIconInfoLight from "@frontend/design/icons/ri-icon-info-light.svg"
import riIconWarningDark from "@frontend/design/icons/ri-icon-warning-dark.svg"
import riIconWarningLight from "@frontend/design/icons/ri-icon-warning-light.svg"
import {css, cva, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

type Variant = "informational" | "warning"

const baseStyles = {
  paddingY: 12,
  paddingX: 16,
  display: "flex",
  justifyContent: "flex-start",
  gap: 8,
  alignItems: "flex-start",
  textStyle: "captionRegular",
}

const stylesOnNeutral = cva({
  base: baseStyles,
  variants: {
    variant: {
      informational: {
        backgroundColor: "background.brand.primary",
        color: "text.brand.primary",
      },
      warning: {
        border: "1px solid token(colors.border.errorSubtle)",
        backgroundColor: "background.error",
        color: "text.brand.primary",
      },
    },
  },
})

const stylesTransparent = cva({
  base: baseStyles,
  variants: {
    variant: {
      informational: {
        backgroundColor: "background.brand.solidSubtle",
        color: "text.primary.onSolid",
      },
      warning: {
        border: "1px solid token(colors.border.errorSubtle)",
        backgroundColor: "background.brand.solidSubtle",
        color: "text.primary.onSolid",
      },
    },
  },
})

interface Props {
  content: string | ReactNode
  variant?: Variant
  transparent?: boolean
  className?: string
}

export const InformationBanner: FC<Props> = ({
  content,
  variant = "informational",
  transparent,
  className,
}) => {
  const icon = InfoIcon[variant][transparent ? "true" : "false"]

  return (
    <div
      className={cx(
        hstack({
          gap: 16,
          width: "100%",
          padding: 16,
          borderRadius: 16,
          maxWidth: "100%",
        }),
        transparent ? stylesTransparent({variant}) : stylesOnNeutral({variant}),
        className,
      )}
    >
      <ReactSVG src={icon} />
      <p
        className={css({
          textStyle: "caption",
          alignSelf: "center",
          wordBreak: "break-word",
        })}
      >
        {content}
      </p>
    </div>
  )
}

const InfoIcon = {
  informational: {
    true: riIconInfoLight,
    false: riIconInfoDark,
  },
  warning: {
    true: riIconWarningLight,
    false: riIconWarningDark,
  },
}
