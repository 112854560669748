import {FC} from "react"
import {useNavigate} from "react-router-dom"
import {ReactSVG} from "react-svg"

import addIcon from "@frontend/design/icons/add.svg"
import {useTranslation} from "@frontend/i18n"
import {makePath} from "@frontend/routing"
import {Styles, css} from "@styled-system/css"

import {Button} from "./ui"

interface Props {
  css?: Styles
}

export const NewCaseButton: FC<Props> = ({css: cssProp}) => {
  const navigate = useNavigate()

  const onCreateNewCase = (): void => {
    navigate(makePath())
  }

  const t = useTranslation()

  return (
    <Button
      variant="tertiaryBrand"
      size="large"
      onClick={onCreateNewCase}
      leftElement={<ReactSVG src={addIcon} />}
      css={css.raw(cssProp)}
      title={t("appContainer.header.newCase")}
    />
  )
}
