import {FC} from "react"
import {Trans} from "react-i18next"
import {ReactSVG} from "react-svg"

import {IconButton} from "@frontend/components/ui/button"
import contactBlueGradient from "@frontend/design/icons/contact-blue-gradient.svg"
import globeBlueGradient from "@frontend/design/icons/globe-blue-gradient.svg"
import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {Rating} from "./rating"

export const ResultsItem: FC<{place: google.maps.places.PlaceResult}> = ({
  place,
}) => {
  const t = useTranslation()
  return (
    <div
      className={hstack({
        gap: 24,
        width: "100%",
        maxWidth: "100%",
        paddingY: 24,
        justifyContent: "space-between",
        overflowX: "hidden",
        paddingRight: 10,
      })}
    >
      <div
        className={vstack({
          alignItems: "flex-start",
          maxWidth: "calc(100% - 124px)",
          flexShrink: 1,
        })}
      >
        <h5
          className={css({
            lineHeight: "24px",
            fontSize: 16,
            color: "fontBlack",
            fontWeight: 500,
          })}
        >
          {place.name}
        </h5>
        <span>
          <Rating
            rating={place.rating}
            reviewsNumber={place.user_ratings_total ?? 0}
          />
        </span>
        <span
          className={css({
            textStyle: "body2",
          })}
        >
          {place.vicinity}
        </span>
        <OperatingHours openingHours={place.opening_hours} />
      </div>
      <div
        className={hstack({justifyContent: "flex-end", width: 100, gap: 20})}
      >
        {place.website && (
          <div className={vstack()}>
            <IconButton
              variant="tertiaryBrand"
              icon={<ReactSVG src={globeBlueGradient} />}
              onClick={() => window.open(place.website)}
              css={css.raw({
                backgroundColor: "background.brand.primarySubtle",
              })}
            />
            {t("cause.difm.website")}
          </div>
        )}
        {place.international_phone_number && (
          <div className={vstack()}>
            <IconButton
              variant="tertiaryBrand"
              icon={<ReactSVG src={contactBlueGradient} />}
              onClick={() =>
                window.open(`tel:${place.international_phone_number}`)
              }
              css={css.raw({
                backgroundColor: "background.brand.primarySubtle",
              })}
            />
            {t("cause.difm.call")}
          </div>
        )}
      </div>
    </div>
  )
}

const OperatingHours: FC<{
  openingHours: google.maps.places.PlaceOpeningHours | undefined
}> = ({openingHours}) => {
  const t = useTranslation()

  const isOpen = openingHours?.isOpen?.()

  const dayOfWeekNumber = new Date().getDay() - 1

  const closeTime = ((): string | null => {
    if (!openingHours?.periods) return null
    const timeStr = openingHours?.periods?.find(
      ({close}) => close?.day === dayOfWeekNumber,
    )?.close?.time

    if (!timeStr) return null

    const hour = timeStr.substring(0, 2)
    const minutes = timeStr.substring(2)

    return `${hour}:${minutes}`
  })()

  const openTime = ((): string | null => {
    if (!openingHours?.periods) return null
    const nextDayOpening = findNextOpeningDay(
      dayOfWeekNumber,
      openingHours.periods,
    ).open
    const timeStr = nextDayOpening.time
    const hour = timeStr.substring(0, 2)
    const minutes = timeStr.substring(2)
    return `${hour}:${minutes}`
  })()

  const time = isOpen ? closeTime : openTime

  return (
    <span className={css({textStyle: "body2"})}>
      <Trans
        t={t}
        i18nKey={
          isOpen && !closeTime
            ? `cause.difm.isOpen.24`
            : `cause.difm.isOpen.${isOpen}`
        }
        values={{time}}
      >
        {isOpen ? (
          <span className={css({color: "text.brand.accent"})} />
        ) : (
          <span className={css({color: "fontRed"})} />
        )}
      </Trans>
    </span>
  )
}

const findNextOpeningDay = (
  dayOfWeekNumber: number,
  periods: google.maps.places.PlaceOpeningHoursPeriod[],
): google.maps.places.PlaceOpeningHoursPeriod => {
  const nextDay = periods.find(({open}) => open?.day === dayOfWeekNumber)
  if (!nextDay) {
    return findNextOpeningDay(
      dayOfWeekNumber === 6 ? 0 : dayOfWeekNumber + 1,
      periods,
    )
  }

  return nextDay
}
