const MESSAGE_STATUS_DELINEATOR = "\u2301"

// Parses a stream response string that is expected to consist of status chunks
// followed by message chunks
// ⌁Analyzing Causes⌁⌁Deciding next steps⌁⌂Thank you for this. Goodbye
// Status Chunk      Status Chunk         Message Chunk        Message Chunk
export const parseStream = (stream: string): string | null => {
  // Give the last complete status
  //
  // ⌁Analyzing Causes⌁⌁Deciding next step⌁ -> Deciding next step
  // ⌁Analyzing Causes⌁⌁Deciding            -> Analyzing Causes

  const statusChunks = stream
    .split(MESSAGE_STATUS_DELINEATOR)
    .filter((s) => !!s)

  statusChunks.reverse()
  const lastChar = stream[stream.length - 1]

  if (lastChar === MESSAGE_STATUS_DELINEATOR) {
    // The last chunk is a complete status
    return statusChunks[0] || null
  } else {
    // The last chunk is an incomplete status so use second to last status
    return statusChunks[1] || null
  }
}
