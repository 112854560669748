import {z} from "zod"

import {assertOrNotFound} from "@frontend/utils/notFound"
import {useSuspenseQueries} from "@frontend/utils/trpc"
import {useStabilizedValue} from "@frontend/utils/useStabilizedValue"
import {useTypedParams} from "@frontend/utils/useTypedParams"
import {id} from "@frontend/utils/zod"
import type {RemediationPageData} from "@ri2/app/server/routers/getRemediationPageData"

import {CaseIdParams} from "../../params"

export const CauseIdParams = z
  .object({
    causeOnMessageId: id,
  })
  .merge(CaseIdParams)

export const useDataFromParams = (): {
  causeOnMessageId: number
  caseId: string
  remediationPageData: RemediationPageData
} => {
  const {causeOnMessageId, caseId} = useTypedParams(CauseIdParams)

  const [remediationPageData] = useSuspenseQueries((trpc) => [
    trpc.remediationPageData({
      causeOnMessageId,
    }),
  ])

  assertOrNotFound(remediationPageData)

  return useStabilizedValue({
    causeOnMessageId,
    caseId,
    remediationPageData,
  })
}
