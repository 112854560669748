import * as Sentry from "@sentry/react"
import {FC, PropsWithChildren, ReactElement} from "react"

import {ErrorPage} from "./error-page"
import {LocationObserver} from "./location-observer"

export const GenericErrorBoundary: FC<PropsWithChildren> = ({children}) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => {
      scope.setTags({error_boundary: "generic"})
    }}
    fallback={({resetError}): ReactElement => (
      <>
        <ErrorPage errorType="generic" />
        <LocationObserver onChangeLocation={resetError} />
      </>
    )}
  >
    {children}
  </Sentry.ErrorBoundary>
)
