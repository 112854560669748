import {useBreadcrumbs} from "@frontend/app-container/context"
import {useTranslation} from "@frontend/i18n"
import {assertOrNotFound} from "@frontend/utils/notFound"
import {useSuspenseQueries} from "@frontend/utils/trpc"

import {makeBreadcrumbs} from "./breadcrumbs"

export const useSetBreadcrumbs = ({
  caseId,
  causeOnMessageId,
}: {
  caseId: string
  causeOnMessageId: number
}): void => {
  const t = useTranslation()

  const [remediationPageData] = useSuspenseQueries((trpc) => [
    trpc.remediationPageData({
      causeOnMessageId,
    }),
  ])

  assertOrNotFound(remediationPageData)

  useBreadcrumbs(
    makeBreadcrumbs({
      caseId,
      caseName: remediationPageData.caseName,
      cause: {
        id: causeOnMessageId,
        name: remediationPageData.remediation.cause.name,
      },
      remediationType: "doItYourself",
      t,
    }),
  )
}
