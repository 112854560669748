import {Feedback, RecommendationReason, RemediationType} from "@ri2/db/client"

import {RemediationTypeProps} from "./remediation"

export const REMEDIATION_ORDER: RemediationType[] = [
  "diyRemediation",
  "difmRemediation",
  "replaceRemediation",
]

export interface RemediationsProps {
  getRemediation: (key: RemediationType) => RemediationTypeProps | undefined
  causeOnMessageId: number
  caseModelId: string
  recommendedRemediationType: RemediationType | null
  remediationHashId: number
  recommendation: Feedback | undefined
  reasons: RecommendationReason[]
  remediationType: RemediationType
}
export interface RemediationProps
  extends Omit<RemediationsProps, "remediationType"> {
  openModal: () => void
}
