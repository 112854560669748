import {FC, useEffect, useState} from "react"

import {CaseEvent, Message} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {MultipleChoiceOptions} from "./MultipleChoicesOptions"
import {NewMessageButton} from "./NewMessageButton"

// Number of pixels before the quick responses are fully invisible
const PIXEL_THRESHOLD_FOR_HIDING_QUICK_RESPONSE = 70

export const ConversationActions: FC<{
  events: CaseEvent[]
  onChooseMultipleChoiceOption: (option: string) => void
  hasNewMessage: boolean
  scrollToBottom: (scrollToTopOfLatestMessage?: boolean) => void
  scrollUpSize: number
}> = ({
  events,
  onChooseMultipleChoiceOption,
  hasNewMessage,
  scrollToBottom,
  scrollUpSize,
}) => {
  const [opacity, setOpacity] = useState(0)
  const lastAiMessage = ((): Message | null => {
    const lastEvent = events[events.length - 1]
    if (lastEvent?.eventType === "message" && lastEvent.type === "ai") {
      return lastEvent
    }
    return null
  })()
  const multipleChoiceOptions =
    getMultipleChoiceOptionsFromMessage(lastAiMessage)

  const hasMultipleChoiceOptions = !!multipleChoiceOptions?.length

  useEffect(() => {
    if (scrollUpSize <= PIXEL_THRESHOLD_FOR_HIDING_QUICK_RESPONSE) {
      const contentBehindPercentage =
        (100 * scrollUpSize) / PIXEL_THRESHOLD_FOR_HIDING_QUICK_RESPONSE
      // The opacity is the percentage of the content behind the threshold
      // the opacity goes from opaque (100) to transparent (0) that's why we subtract the percentage to 100 in order to be more transparent
      setOpacity(100 - contentBehindPercentage)
    } else if (opacity !== 0) {
      setOpacity(0)
    }
  }, [opacity, scrollUpSize])

  const isMultipleChoicesFadeOut = opacity === 0

  return (
    <>
      <div
        className={vstack({
          width: "100%",
          alignItems: "flex-end",
          position: "sticky",
          gap: 6,
          bottom: {
            base: -1,
            desktop: 0,
          },
          left: 0,
          right: 0,
        })}
      >
        {hasNewMessage && isMultipleChoicesFadeOut && (
          <div
            className={cx(
              hstack({
                justifyContent: "center",
                width: "100%",
              }),
              hasMultipleChoiceOptions
                ? css({
                    position: "absolute",
                    bottom: 32,
                  })
                : css({
                    marginBottom: 32,
                  }),
            )}
          >
            <NewMessageButton scrollToBottom={() => scrollToBottom(false)} />
          </div>
        )}
        {isMultipleChoicesFadeOut && (
          <div
            className={css({
              height: 32,
              position: "absolute",
              bottom: -1,
              width: "100%",
              bgGradient: "conversationFade",
            })}
          />
        )}
        {hasMultipleChoiceOptions && (
          <div
            style={{
              opacity: `${opacity}%`,
              padding: "0px 0px 16px 0px",
              pointerEvents: !isMultipleChoicesFadeOut ? "auto" : "none",
            }}
            className={vstack({
              alignItems: "flex-end",
              gap: 6,
            })}
          >
            <MultipleChoiceOptions
              options={multipleChoiceOptions}
              onChooseOption={onChooseMultipleChoiceOption}
            />
          </div>
        )}
      </div>
    </>
  )
}

const getMultipleChoiceOptionsFromMessage = (
  message: Message | null,
): string[] | undefined =>
  message && message.type === "ai"
    ? message.content?.multipleChoiceAnswers?.map((answer) =>
        typeof answer === "string" ? answer : answer.text,
      )
    : undefined
