import type {Part as PartModel} from "@ri2/rc-db"
import pluralize from "pluralize"
import {FC} from "react"
import {ReactSVG} from "react-svg"

import {Button, Tag} from "@frontend/components"
import {AddModelNumberModal} from "@frontend/components/add-model-number-modal"
import {InformationBanner} from "@frontend/components/ui/information-banner"
import chevron from "@frontend/design/icons/chevron-right.svg"
import modelNumber from "@frontend/design/icons/model-number.svg"
import shieldIcon from "@frontend/design/icons/shield-positive-light.svg"
import stopSignIcon from "@frontend/design/icons/stop-sign.svg"
import {useTranslation} from "@frontend/i18n"
import {useBooleanState} from "@frontend/utils/useBooleanState"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {DataQualityTag} from "./components/data-quality-tag"
import {Part} from "./components/part"
import {useDataFromParams} from "./params"

export const Parts: FC<{partIcon?: string}> = ({partIcon}) => {
  const {
    remediation: {
      cause,
      diyContent: {partsByPartType: parts},
    },
  } = useDataFromParams()

  const t = useTranslation()

  return (
    <div
      className={vstack({
        alignItems: "stretch",
        gap: 16,
        position: "relative",
        justifyContent: "space-between",
      })}
    >
      <div
        className={vstack({
          alignItems: "stretch",
          flexShrink: 1,
          flexGrow: 1,
          overflow: "auto",
          gap: 0,
        })}
      >
        <div
          className={hstack({
            gap: 4,
            paddingBottom: 12,
            alignItems: "center",
            justifyContent: "space-between",
          })}
        >
          <span
            className={css({
              textStyle: "captionMedium",
              color: "text.primary.onSolid",
            })}
          >
            {t("cause.diy.parts.title")}
          </span>
          {parts.source.type === "match" && cause.remediationRequiresParts && (
            <DataQualityTag source={parts.source} mode="part" transparent />
          )}
        </div>
        <div
          className={vstack({
            borderTopColor: "border.brand.secondarySolid",
            borderTopWidth: 1,
            alignItems: "stretch",
          })}
        >
          <Content partsIcon={partIcon} />
        </div>
      </div>
    </div>
  )
}

const Content: FC<{partsIcon?: string}> = ({partsIcon}) => {
  const {
    remediation: {
      cause,
      diyContent: {partsByPartType},
    },
    enrichedProductInfo,
  } = useDataFromParams()

  const parts = partsByPartType.partTypes.flatMap(({parts}) => parts)

  if (!cause.remediationRequiresParts) {
    return <NoPartsRequired />
  }

  if (!enrichedProductInfo.modelNumber) {
    return <ModelNumberPrompt />
  }

  if (partsByPartType.source.type !== "match" || parts.length === 0) {
    return <ModelNotInDatabasePrompt />
  }

  return <PartsList parts={parts} partsIcon={partsIcon} />
}

interface PartsListProps {
  parts: PartModel[]
  partsIcon?: string
}

const PartsList: FC<PartsListProps> = ({parts, partsIcon}) => {
  const partType = parts[0].partType?.name
  const t = useTranslation()

  return (
    <div
      className={vstack({
        flex: 1,
        gap: {
          base: 16,
          desktop: 0,
        },
        flexWrap: "nowrap",
        overflowX: "auto",
        alignItems: "stretch",
        justifyContent: "center",
        paddingBottom: 0,
      })}
    >
      {parts.length > 1 && partType && (
        <div
          className={vstack({
            gap: 0,
            alignItems: "flex-start",
            textStyle: "bodyMedium",
            lineHeight: "20px",
            color: "text.primary.onSolid",
            marginTop: 16,
          })}
        >
          <div>{pluralize(partType)}</div>
          <div
            className={css({
              color: "text.secondary.onSolid",
              textStyle: "captionRegular",
            })}
          >
            {t("cause.diy.parts.multipleAvailable")}
          </div>
        </div>
      )}
      {parts.map((part, index) => (
        <Part
          part={part}
          mode={parts.length > 1 ? "list" : "single"}
          className={css({
            flexShrink: 0,
            borderBottomColor: "border.brand.secondarySolid",
            borderBottomWidth: index === parts.length - 1 ? 0 : 1,
          })}
          partsIcon={partsIcon}
          key={index}
        />
      ))}
      {parts.length > 1 && (
        <InformationBanner
          variant="informational"
          transparent
          content={t("cause.diy.parts.multiple")}
        />
      )}
    </div>
  )
}

const NoPartsRequired: FC = () => {
  const t = useTranslation()

  return (
    <div
      className={vstack({
        paddingY: 20,
        gap: 8,
        alignItems: "stretch",
      })}
    >
      <Tag
        title={t("cause.diy.empty.noPartsRequiredTag")}
        icon={shieldIcon}
        colorScheme="positive"
        transparent
        className={css({
          alignSelf: "flex-start",
        })}
      />
      <p
        className={css({
          textStyle: "new16",
          color: "rgba(255, 255, 255, 0.5)",
        })}
      >
        {t("cause.diy.empty.noPartsRequired")}
      </p>
    </div>
  )
}

const ModelNumberPrompt: FC = () => {
  const t = useTranslation()

  return (
    <div
      className={vstack({
        paddingTop: 16,
        paddingBottom: 24,
        gap: 8,
        alignItems: "flex-start",
      })}
    >
      <img src={modelNumber} alt="" />
      <p
        className={css({
          textStyle: "bodyLight",
          color: "text.secondary.onSolid",
        })}
      >
        {t("cause.diy.empty.noModelNumber")}
      </p>
      <AddModelNumberButton />
    </div>
  )
}

const ModelNotInDatabasePrompt: FC = () => {
  const t = useTranslation()

  return (
    <div
      className={vstack({
        paddingTop: 16,
        paddingBottom: 24,
        gap: 8,
        alignItems: "flex-start",
      })}
    >
      <img src={stopSignIcon} alt="" />
      <p
        className={css({
          textStyle: "bodyLight",
          color: "text.secondary.onSolid",
        })}
      >
        {t("cause.diy.empty.modelNotInDatabase")}
      </p>
      <AddModelNumberButton />
    </div>
  )
}

const AddModelNumberButton: FC = () => {
  const {caseId} = useDataFromParams()

  const {
    setTrue: onShowAddModelNumberModal,
    setFalse: onHideAddModelNumberModal,
    state: showAddModelNumberModal,
  } = useBooleanState(false)

  const t = useTranslation()

  return (
    <>
      <Button
        variant="primary"
        title={t("cause.diy.empty.addModelNumber")}
        rightElement={<ReactSVG src={chevron} />}
        onClick={onShowAddModelNumberModal}
      />
      {showAddModelNumberModal && (
        <AddModelNumberModal
          caseId={caseId}
          onClose={onHideAddModelNumberModal}
        />
      )}
    </>
  )
}
