import * as Sentry from "@sentry/react"
import {FC} from "react"
import {Route, Routes} from "react-router-dom"

import {RedirectToLandingPage} from "./redirect-to-landing-page"
import DifmPage from "./routes/cases/[caseId]/causeOnMessages/[causeOnMessageId]/difm/page"
import {DocumentsPage} from "./routes/cases/[caseId]/causeOnMessages/[causeOnMessageId]/diy/documents/page"
import {DiyLayout} from "./routes/cases/[caseId]/causeOnMessages/[causeOnMessageId]/diy/layout"
import {StepsPage} from "./routes/cases/[caseId]/causeOnMessages/[causeOnMessageId]/diy/steps/page"
import VideosPage from "./routes/cases/[caseId]/causeOnMessages/[causeOnMessageId]/diy/videos/page"
import CausePage from "./routes/cases/[caseId]/causeOnMessages/[causeOnMessageId]/page"
import {CaseLayout} from "./routes/cases/[caseId]/layout"
import {CasePage} from "./routes/cases/[caseId]/page"
import {RootLayoutWithOutlet} from "./routes/layout"
import {LandingPage} from "./routes/page"

export const Router: FC = () => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

  return (
    <SentryRoutes>
      <Route element={<RootLayoutWithOutlet />}>
        <Route index element={<RedirectToLandingPage />} />
        <Route path="ri2">
          <Route index element={<LandingPage />} />
          <Route path=":caseId" element={<CaseLayout />}>
            <Route index element={<CasePage />} />
            <Route path=":causeOnMessageId">
              <Route index element={<CausePage />} />
              <Route path="difm">
                <Route index element={<DifmPage />} />
              </Route>
              <Route path="diy" element={<DiyLayout />}>
                <Route index element={<StepsPage />} />
                <Route path="steps" element={<StepsPage />} />
                <Route path="videos" element={<VideosPage />} />
                <Route path="documents" element={<DocumentsPage />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </SentryRoutes>
  )
}
