import {FC, PropsWithChildren, ReactNode} from "react"

import {RecipeVariantProps, Styles, css, cva} from "@styled-system/css"

import {ButtonActionProps} from "./buttonProps"
import {ButtonVariantsProps, buttonColors, disabledStyles} from "./styles"
import {useHandleClick} from "./useHandleClick"

const baseButtonStyles = cva({
  base: {
    borderRadius: "9999px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: 6,
    cursor: "pointer",
    fontWeight: 500,
    lineHeight: "20px",
  },
  variants: {
    size: {
      small: {
        paddingY: 8,
        paddingX: 10,
        fontSize: 13,
      },
      large: {
        paddingY: 10,
        paddingX: 16,
        fontSize: 16,
      },
    },
    fullWidth: {
      true: {
        width: "100%",
      },
      false: {
        width: "auto",
      },
    },
  },
  defaultVariants: {
    size: "small",
    fullWidth: false,
  },
})

type ButtonStylesProps = RecipeVariantProps<typeof baseButtonStyles> &
  ButtonVariantsProps

type Props = ButtonStylesProps &
  ButtonActionProps & {
    leftElement?: ReactNode
    rightElement?: ReactNode
    title?: string
    id?: string
    disabled?: boolean
    css?: Styles
    buttonType?: HTMLButtonElement["type"]
  }

export const Button: FC<PropsWithChildren<Props>> = ({
  variant,
  size,
  fullWidth,
  children,
  leftElement,
  rightElement,
  disabled,
  id,
  title,
  buttonType,
  css: cssProp,
  ...actionProps
}) => {
  const handleClick = useHandleClick({
    navigateTo:
      "navigateTo" in actionProps ? actionProps.navigateTo : undefined,
    external: "external" in actionProps ? actionProps.external : undefined,
    onClick: actionProps.onClick,
  })
  return (
    <button
      onClick={handleClick}
      id={id}
      disabled={disabled}
      type={buttonType}
      className={css(
        buttonColors.raw({variant}),
        baseButtonStyles.raw({size, fullWidth}),
        disabled && disabledStyles.raw({variant}),
        cssProp,
      )}
    >
      {leftElement}
      {title ?? children}
      {rightElement}
    </button>
  )
}
