import {Tooltip as ArkTooltip} from "@ark-ui/react"
import {FC, PropsWithChildren, useEffect, useRef, useState} from "react"

import {useBooleanState} from "@frontend/utils/useBooleanState"
import {css} from "@styled-system/css"
import {token} from "@styled-system/tokens"

export interface TooltipProps {
  content?: string
  positioning?: "top" | "bottom"
  variant?: keyof typeof variants
  spaceFromTrigger?: number
  disabled?: boolean
}

const variants = {
  light: {
    backgroundColor: "bgBlue",
    backgroundColorToken: token("colors.bgBlue"),
    color: "lightBlue",
  },
  dark: {
    backgroundColor: "background.primaryOnSolid",
    backgroundColorToken: token("colors.background.primaryOnSolid"),
    color: "white",
  },
}

const X_AXIS_MARGIN = 20
const TOP_SPACE_FROM_TRIGGER = 16
const BOTTOM_SPACE_FROM_TRIGGER = 8

export const TooltipWrapper: FC<PropsWithChildren<TooltipProps>> = ({
  children,
  content,
  positioning = "top",
  variant = "light",
  spaceFromTrigger,
  disabled,
}) => {
  const refTrigger = useRef<HTMLDivElement>(null)
  const refContent = useRef<HTMLDivElement>(null)
  const {
    setTrue: setIsHovered,
    setFalse: setIsNotHovered,
    state: isHovered,
  } = useBooleanState(false)
  const [tooltipPosition, setTooltipPosition] = useState<{
    top: string | number
    left: number | string
    right: string | number
    arrowLeft?: number | string
  }>({top: 0, left: 0, right: "auto", arrowLeft: "50%"})

  useEffect(() => {
    if (refTrigger.current && refContent.current && content) {
      const triggerRect = refTrigger.current.getBoundingClientRect()
      const viewportWidth =
        refTrigger.current.ownerDocument.documentElement.clientWidth
      const tooltipWidth = refContent.current.clientWidth ?? 80
      const tooltipLeft = refContent.current.getBoundingClientRect().left
      const halfTooltipWidth = tooltipWidth / 2
      const halfTriggerWidth = triggerRect.width / 2

      const spaceFromTriggerValue =
        typeof spaceFromTrigger === "number"
          ? spaceFromTrigger
          : positioning === "top"
            ? TOP_SPACE_FROM_TRIGGER
            : BOTTOM_SPACE_FROM_TRIGGER

      const top =
        positioning === "top"
          ? triggerRect.top - (triggerRect.height + spaceFromTriggerValue)
          : triggerRect.bottom + spaceFromTriggerValue

      let arrowLeft: string | number = "50%"

      let right: string | number = "auto"

      let left: string | number =
        (triggerRect.left ?? 0) - halfTooltipWidth + halfTriggerWidth

      if (left < 0) {
        left = X_AXIS_MARGIN
        arrowLeft = triggerRect.left - X_AXIS_MARGIN + halfTriggerWidth
      }

      if (left + tooltipWidth > viewportWidth - X_AXIS_MARGIN) {
        arrowLeft = triggerRect.left - tooltipLeft + halfTriggerWidth
        left = "auto"
        right = X_AXIS_MARGIN
      }

      setTooltipPosition({top, left, right, arrowLeft})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, content, positioning, isHovered])

  if (!content || disabled) {
    return <>{children}</>
  }

  const {backgroundColor, color, backgroundColorToken} = variants[variant]

  return (
    <ArkTooltip.Root openDelay={100} closeDelay={100}>
      {({isOpen = true}) => (
        <>
          <ArkTooltip.Trigger asChild>
            <div
              className={css({position: "relative"})}
              ref={refTrigger}
              onMouseEnter={setIsHovered}
              onMouseLeave={setIsNotHovered}
            >
              {children}
            </div>
          </ArkTooltip.Trigger>
          <ArkTooltip.Positioner
            style={{
              top: tooltipPosition.top,
              left: tooltipPosition.left,
              right: tooltipPosition.right,
              position: "fixed",
              //The visibility hidden mess with the cursor in some places, so use this trick to hide the tooltip
              // display: none is not suitable here because of the ref's to calculate the position
              transform: isOpen ? "scale(1)" : "scale(0)",
            }}
            className={css({
              zIndex: "tooltipPositioner",
            })}
          >
            <div
              className={css({
                position: "relative",
                backgroundColor,
                color,
                borderRadius: 8,
                paddingX: 12,
                paddingY: 8,
                minWidth: 40,
                fontSize: 12,
                fontWeight: 600,
                lineHeight: "18px",
              })}
              ref={refContent}
            >
              <ArkTooltip.Arrow
                className={css({
                  top: positioning === "top" ? "100%" : "auto",
                  bottom:
                    positioning === "bottom" ? "calc(100% - 8px)" : "auto",
                  transform: "translateX(-50%) translateY(-50%)",
                  zIndex: "tooltipArrow",
                })}
                style={{width: 8, height: 8, left: tooltipPosition.arrowLeft}}
              >
                <ArkTooltip.ArrowTip
                  style={{
                    width: 8,
                    height: 8,
                    backgroundColor: backgroundColorToken,
                  }}
                />
              </ArkTooltip.Arrow>
              {content}
            </div>
          </ArkTooltip.Positioner>
        </>
      )}
    </ArkTooltip.Root>
  )
}
