import {FC, useEffect} from "react"
import {useNavigate} from "react-router-dom"

import {Banner} from "./banner"
import {Footer} from "./footer"
import {HowItWorks} from "./how-it-works"
import {Overview} from "./overview"
import {StepsSection} from "./steps-section"
import {useIsSignedIn} from "./useIsSignedIn"

export const LandingPage: FC<{open: () => void}> = ({open}) => {
  const navigate = useNavigate()
  const {isLoading, isLoggedIn} = useIsSignedIn()

  useEffect(() => {
    if (!isLoading && !isLoggedIn) {
      navigate("/login")
    }
  }, [navigate, isLoading, isLoggedIn])

  if (isLoggedIn && !isLoading) {
    return (
      <div>
        <Banner open={open} />
        <Overview />
        <HowItWorks />
        <StepsSection />
        <Footer open={open} />
      </div>
    )
  }
}
