import {FC} from "react"
import {Trans} from "react-i18next"
import {useNavigate} from "react-router-dom"

import {useSidebar} from "@frontend/app-container/context"
import {mainGrid} from "@frontend/design"
import {useTranslation} from "@frontend/i18n"
import {BaseLayout} from "@frontend/routes/cases/[caseId]/components/base-layout"
import {EventLayout} from "@frontend/routes/cases/[caseId]/components/event-layout"
import {makePath} from "@frontend/routing"
import {css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {TextChip} from "../ui"

interface Props {
  errorType: "generic" | "notFound"
}

export const ErrorPage: FC<Props> = ({errorType}) => {
  const navigate = useNavigate()

  const onGoHome = (): void => {
    navigate(makePath())
  }

  const {onToggleSidebar} = useSidebar()

  const t = useTranslation()

  return (
    <BaseLayout>
      <div
        className={cx(
          mainGrid(),
          css({
            height: "100%",
            overflow: "hidden",
          }),
        )}
      >
        <div
          className={css({
            gridColumnStart: 1,
            gridColumnEnd: 8,
            paddingX: {base: 32, desktop: 48},
            paddingY: {base: 16, desktop: 48},
            overflowY: "auto",
          })}
        >
          <EventLayout isThinkingOrAI timestamp={new Date()}>
            <div
              className={vstack({
                gap: 16,
                alignItems: "flex-start",
              })}
            >
              <div
                className={vstack({
                  alignItems: "stretch",
                  gap: {base: 16, desktop: 32},
                })}
              >
                <p
                  className={css({
                    textStyle: {base: "h5", desktop: "h3"},
                  })}
                >
                  <Trans
                    t={t}
                    i18nKey={
                      errorType === "generic"
                        ? "errors.generic"
                        : "errors.notFound"
                    }
                  >
                    <span className={css({color: "lightBlue"})} />
                  </Trans>
                </p>
                <div
                  className={hstack({
                    gap: 12,
                    flexWrap: "wrap",
                  })}
                >
                  <TextChip onClick={onGoHome} title={t("errors.goHome")} />
                  <TextChip
                    onClick={onToggleSidebar}
                    title={t("errors.browseCases")}
                  />
                </div>
              </div>
            </div>
          </EventLayout>
        </div>
      </div>
    </BaseLayout>
  )
}
