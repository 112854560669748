import {FC} from "react"
import {Link} from "react-router-dom"

import {useTranslation} from "@frontend/i18n"
import {makePath} from "@frontend/routing"
import {useIsDesktop} from "@frontend/utils/useIsDesktop"
import {CaseSummary as CaseSummaryModel} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"

import {CaseState} from "./case-state"

interface Props {
  caseSummary: CaseSummaryModel
  onNavigate?: () => void
  isCurrentCase?: boolean
}

export const CaseSummary: FC<Props> = ({
  caseSummary: {id, name, resolved, date},
  onNavigate,
  isCurrentCase,
}) => {
  const isDesktop = useIsDesktop()
  const t = useTranslation()

  return (
    <Link
      to={makePath({
        name: "cases",
        caseId: id,
        ...(!isDesktop ? {tab: "conversation"} : {}),
      })}
      onClick={onNavigate}
      className={cx(
        css(
          {
            display: "flex",
            flexDirection: "column",
            gap: 2,
            paddingY: 8,
            paddingX: 24,
          },
          isCurrentCase && {
            backgroundColor: "lightGrey",
          },
        ),
      )}
    >
      <p
        className={css({
          textStyle: "body2",
        })}
      >
        {name ?? t("appContainer.sidebar.cases.untitledName")}
      </p>
      <CaseState resolved={resolved} date={date} />
    </Link>
  )
}
