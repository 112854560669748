import {FC} from "react"

import {useBooleanState} from "@frontend/utils/useBooleanState"
import {css} from "@styled-system/css"

import {DesktopRemediations} from "./desktop-remediations"
import {LargeDesktopRemediations} from "./large-desktop-remediations"
import {LearnMoreBasedOnModal} from "./learn-more-based-on-modal"
import {MobileRemediations} from "./mobile-remediations"
import {RemediationsProps} from "./types"

export {type RemediationTypeProps} from "./remediation"

export const Remediations: FC<RemediationsProps> = (props) => {
  const {
    state: isOpenModal,
    setFalse: closeModal,
    setTrue: openModal,
  } = useBooleanState(false)
  return (
    <div
      className={css({
        position: "relative",
        flexGrow: 1,
        largeDesktopDown: {
          display: "contents",
        },
      })}
    >
      <LargeDesktopRemediations {...props} openModal={openModal} />
      <MobileRemediations {...props} openModal={openModal} />
      <DesktopRemediations {...props} openModal={openModal} />
      <LearnMoreBasedOnModal
        reasons={props.reasons}
        remediationType={props.remediationType}
        onDismiss={closeModal}
        show={isOpenModal}
      />
    </div>
  )
}
