import {cva} from "@styled-system/css"

export const hoverAndPressedStyles = cva({
  base: {
    position: "relative",
    overflow: "hidden",
    _enabled: {
      cursor: "pointer",
    },
  },
  variants: {
    overlay: {
      white: {
        _hover: {
          _after: {
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "rgba(255, 255, 255, 0.08)",
            top: 0,
            left: 0,
            content: "''",
            zIndex: 1,
          },
        },
        _active: {
          _after: {
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "rgba(255, 255, 255, 0.1)",
            top: 0,
            left: 0,
            content: "''",
            zIndex: 1,
          },
        },
      },
      black: {
        _hover: {
          _after: {
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "rgba(0, 0, 0, 0.08)",
            top: 0,
            left: 0,
            content: "''",
            zIndex: 1,
          },
        },
        _active: {
          _after: {
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "rgba(0, 0, 0, 0.1)",
            top: 0,
            left: 0,
            content: "''",
            zIndex: 1,
          },
        },
      },
      lightGreyCard: {
        _hover: {
          background: "lightGrey",
          transition: "background 0.1s",
        },
        _active: {
          transition: "background 0.1s",
          background: "lightGrey",
        },
      },
      disabled: {},
    },
  },
})
