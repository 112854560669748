import {FC} from "react"
import {ReactSVG} from "react-svg"

import dislikeIcon from "@frontend/design/icons/dislike.svg"
import likeIcon from "@frontend/design/icons/like.svg"
import {FeedbackRating} from "@ri2/db/client"
import {css, cva} from "@styled-system/css"

import {TooltipWrapper} from "../ui/tooltip"

interface ReactionProps {
  rating: FeedbackRating
  selected: boolean
  onClick: () => void
  tooltip: string
  className?: string
  transparent?: boolean
  tooltipPlacement?: "top" | "bottom"
  tooltipVariant?: "light" | "dark"
}

const baseStyles = {
  display: "flex",
  alignItems: "center",
  width: 32,
  height: 32,
  borderRadius: "50%",
  justifyContent: "center",
  cursor: "pointer",
}

const solidStyles = cva({
  base: {...baseStyles},
  variants: {
    state: {
      POSITIVE: {
        backgroundColor: "#E5F6EB",
        border: "1px solid #84D5A1",
        "& *": {
          fill: "#23B558",
        },
      },
      NEGATIVE: {
        backgroundColor: "#FFE5E8",
        border: "1px solid #FFADB8",
        "& *": {
          fill: "#FF4F68",
        },
      },
      default: {
        backgroundColor: "#E5E6E6",
        "&:hover": {
          backgroundColor: "#ECECEC",
          "& *": {
            fill: "#000000",
          },
          border: "1px solid #E2E2E2",
        },
        "& *": {
          fill: "#454545",
        },
      },
    },
  },
})

const transparentStyles = cva({
  base: {...baseStyles},
  variants: {
    state: {
      POSITIVE: {
        backgroundColor: "#95EDB433",
        border: "1px solid #3CB166",
        "& *": {
          fill: "#82F4AB",
        },
      },
      NEGATIVE: {
        backgroundColor: "#FC4E7626",
        border: "1px solid #BD4052",
        "& *": {
          fill: "#F9677C",
        },
      },
      default: {
        backgroundColor: "rgba(255, 255, 255, 0.20)",
        "& *": {
          fill: "#FFFFFF",
        },
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.15)",
          border: "1px solid rgba(255, 255, 255, 0.10)",
        },
      },
    },
  },
})

const reactionStyles = (
  isTransparent: boolean,
  state: FeedbackRating | "default",
): string => {
  if (isTransparent) {
    return transparentStyles({state})
  }
  return solidStyles({state})
}

export const Reaction: FC<ReactionProps> = ({
  rating,
  selected,
  onClick,
  tooltip,
  tooltipPlacement = "bottom",
  tooltipVariant,
  transparent,
}) => (
  <TooltipWrapper
    content={tooltip}
    positioning={tooltipPlacement}
    variant={tooltipVariant}
  >
    <button
      onClick={onClick}
      aria-label={tooltip}
      className={reactionStyles(
        !!transparent,
        rating && selected ? rating : "default",
      )}
    >
      <ReactSVG
        src={rating === "POSITIVE" ? likeIcon : dislikeIcon}
        role="graphics-document"
        className={css({width: 20, height: 20})}
      />
    </button>
  </TooltipWrapper>
)
