import {useEffect} from "react"

export const useCloseOnEsc = (callback: () => void): void => {
  useEffect(() => {
    const handleEsc = (e: KeyboardEvent): void => {
      if (e.key === "Escape") {
        callback()
      }
    }
    window.addEventListener("keydown", handleEsc)
    return (): void => {
      window.removeEventListener("keydown", handleEsc)
    }
  }, [callback])
}
