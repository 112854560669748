import {FC} from "react"

import playButton from "@frontend/design/icons/play-button.svg"
import {MessageMedia, isMessageVideo} from "@ri2/db/client"
import {css} from "@styled-system/css"

interface Props {
  media: MessageMedia
  onClick: () => void
}

export const Media: FC<Props> = ({media, onClick}) => {
  if (!media.url) {
    return null
  }

  const isVideo = isMessageVideo(media)
  const imageUrl = isVideo ? media.thumbnailUrl : media.url

  return (
    <div
      className={css({
        position: "relative",
        cursor: "pointer",
        overflow: "hidden",
        borderRadius: 8,
        borderWidth: 1,
        borderColor: "rgba(233, 234, 236, 1)",
        transition: "box-shadow 0.1s ease-in",
        "&:hover": {
          boxShadow:
            "0px 9px 20px rgba(28, 37, 72, 0.14), 0px 3px 3px rgba(28, 37, 72, 0.06)",
        },
      })}
      onClick={onClick}
    >
      {isVideo && (
        <img
          src={playButton}
          alt={""}
          className={css({
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
            zIndex: 1,
          })}
        />
      )}
      <img
        src={imageUrl}
        alt={media.title ?? ""}
        className={css({
          height: "auto",
          maxHeight: 180,
          width: "auto",
          cursor: "pointer",
          largeDesktop: {
            height: 180,
          },
        })}
      />
      {media.title && (
        <div
          className={css({
            position: "absolute",
            bottom: 4,
            left: 4,
            maxWidth: "calc(100% - 8px)",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            borderRadius: 4,
            color: "white",
            paddingY: 2,
            paddingX: 8,
            textStyle: "captionRegular",
          })}
        >
          {media.title}
        </div>
      )}
    </div>
  )
}
