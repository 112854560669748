import {FC, useState} from "react"
import {ReactSVG} from "react-svg"

import {Button, Modal} from "@frontend/components"
import {InformationBanner} from "@frontend/components/ui/information-banner"
import question from "@frontend/design/icons/question.svg"
import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"
import {hstack, iconsColor, vstack} from "@styled-system/patterns"

export const EmptyCasesList: FC = () => {
  const t = useTranslation()
  const [isShowReasonModal, setShowReasonModal] = useState(false)

  const onCloseReasonModal = (): void => setShowReasonModal(false)
  const onOpenReasonModal = (): void => setShowReasonModal(true)
  return (
    <>
      <div className={vstack({gap: 24})}>
        <div className={css({paddingX: 10})}>
          <InformationBanner
            content={t("appContainer.sidebar.emptyCasesMessage")}
            variant="informational"
          />
        </div>
        <button
          onClick={onOpenReasonModal}
          className={css({
            textStyle: "caption",
            color: "fontGrey",
            textAlign: "center",
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: "lightGrey",
            paddingBottom: 4,
          })}
        >
          {t("appContainer.sidebar.previousCaseNotFoundMsg")}
        </button>
      </div>

      <Modal
        title={t("appContainer.sidebar.noPreviousCasesModal.title")}
        message={t("appContainer.sidebar.noPreviousCasesModal.description")}
        show={isShowReasonModal}
        onDismiss={onCloseReasonModal}
        width={610}
        icon={
          <div
            className={css({
              borderRadius: 8,
              backgroundColor: "background.brand.primarySubtle",
              padding: 6,
            })}
          >
            <ReactSVG
              src={question}
              className={iconsColor({color: "foreground.brand.primary"})}
            />
          </div>
        }
      >
        <div className={hstack()}>
          <Button
            variant="primary"
            size="large"
            onClick={onCloseReasonModal}
            title={t("appContainer.sidebar.noPreviousCasesModal.action")}
          />
        </div>
      </Modal>
    </>
  )
}
