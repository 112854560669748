const fallbackLanguage = "en"
const languages = [fallbackLanguage]
const defaultNS = "translation"

/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const getOptions = (lng = fallbackLanguage, ns = defaultNS) => ({
  supportedLngs: languages,
  fallbackLng: fallbackLanguage,
  lng,
  fallbackNS: defaultNS,
  defaultNS,
  ns,
})
/* eslint-enable @typescript-eslint/explicit-function-return-type */
