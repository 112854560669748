import {createContext, useContext, useEffect, useLayoutEffect} from "react"
import {useNavigate} from "react-router-dom"

import {useStabilizedValue} from "@frontend/utils/useStabilizedValue"
import type {UiCase} from "@ri2/app/server/routers/getCasePageData"

export interface Breadcrumb {
  name: string
  mobileName?: string
  path: string
  hidden?: boolean
  queryParams?: string
}

interface AppContainerContextValue {
  breadcrumbs: Breadcrumb[]
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void

  setCase: (caseModel?: UiCase) => void

  onToggleSidebar: () => void
  isSidebarExpanded: boolean
}

export const AppContainerContext = createContext<AppContainerContextValue>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  undefined as any,
)

export const useNavigateUpBreadcrumbs = (): (() => void) => {
  const {breadcrumbs} = useContext(AppContainerContext)
  const navigate = useNavigate()
  const breadcrumb = breadcrumbs[breadcrumbs.length - 2]
  return () =>
    navigate(
      `${breadcrumb.path}${breadcrumb.queryParams ? `?${breadcrumb.queryParams}` : ""}`,
    )
}

export const useBreadcrumbs = (breadcrumbs: Breadcrumb[]): void => {
  const {setBreadcrumbs} = useContext(AppContainerContext)

  const stabilizedBreadcrumbs = useStabilizedValue(breadcrumbs)

  useEffect(() => {
    setBreadcrumbs(stabilizedBreadcrumbs)
  }, [stabilizedBreadcrumbs, setBreadcrumbs])
}

export const useCase = (caseModel?: UiCase): void => {
  const stabilizedOptions = useStabilizedValue(caseModel)

  const {setCase: setContextualActionOptions} = useContext(AppContainerContext)

  /* eslint-disable react-hooks/exhaustive-deps */
  useLayoutEffect(() => {
    setContextualActionOptions(stabilizedOptions)
  }, [stabilizedOptions])
  /* eslint-enable react-hooks/exhaustive-deps */
}

export const useIsSidebarExpanded = (): boolean => {
  const {isSidebarExpanded} = useContext(AppContainerContext)

  return isSidebarExpanded
}

export const useSidebar = (): {
  isSidebarExpanded: boolean
  onToggleSidebar: () => void
} => {
  const {onToggleSidebar, isSidebarExpanded} = useContext(AppContainerContext)

  return {
    onToggleSidebar,
    isSidebarExpanded,
  }
}
