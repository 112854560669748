import type {Product} from "@ri2/rc-db"
import {FC, Suspense, useState} from "react"

import thinkingAnimation from "@frontend/design/animations/thinking.svg"
import {useTranslation} from "@frontend/i18n"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {Input} from "./input"
import {Matched} from "./matched"
import {Unmatched} from "./unmatched"
import {BaseModal, CloseButton} from "../modal"

type State =
  | {
      type: "input"
      modelNumber: string
    }
  | {
      type: "matched"
      modelNumber: string
      product: Product
    }
  | {
      type: "unmatched"
      modelNumber: string
    }

interface Props {
  onClose: () => void
  caseId: string
}

export const AddModelNumberModal: FC<Props> = ({
  caseId,
  onClose: onCloseProp,
}) => {
  const [state, setState] = useState<State>({type: "input", modelNumber: ""})

  const t = useTranslation()

  const title = t(
    state.type === "input"
      ? "addModelNumberModal.title.input"
      : "addModelNumberModal.title.other",
  )

  const onClose = (): void => {
    setState({type: "input", modelNumber: ""})
    onCloseProp()
  }

  return (
    <BaseModal
      show
      onDismiss={() => {
        onClose()
      }}
      width={710}
      containerCss={css.raw({
        backgroundColor: "background.brand.primary",
        padding: 24,
        paddingTop: 16,
        paddingBottom: 24,
      })}
    >
      <div
        className={cx(
          hstack({
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
            height: 44,
            marginBottom: 16,
          }),
          state.type === "input" &&
            css({
              paddingX: 8,
            }),
        )}
      >
        <h3
          className={css({
            textStyle: "bodyLargeMedium",
            color: "text.primary",
          })}
        >
          {title}
        </h3>
        <CloseButton
          onClick={onClose}
          css={css.raw({
            padding: 10,
            marginTop: 0,
            marginRight: 0,
          })}
        />
      </div>
      <Suspense
        fallback={
          <div
            className={hstack({
              alignItems: "center",
              justifyContent: "center",
              paddingY: 24,
              color: "text.tertiary",
              textStyle: "bodyLargeRegular",
              minHeight: 278,
            })}
          >
            {t("general.loading")}
            <img
              src={thinkingAnimation}
              alt=""
              className={css({
                width: 26,
                height: 26,
              })}
            />
          </div>
        }
      >
        {state.type === "input" && (
          <Input
            caseId={caseId}
            modelNumber={state.modelNumber}
            onModelNumberChanged={(modelNumber) => {
              setState({type: "input", modelNumber})
            }}
            onFoundProduct={(product) => {
              if (product) {
                setState({
                  type: "matched",
                  modelNumber: state.modelNumber,
                  product,
                })
              } else {
                setState({
                  type: "unmatched",
                  modelNumber: state.modelNumber,
                })
              }
            }}
          />
        )}
        {state.type === "matched" && (
          <Matched
            caseId={caseId}
            modelNumber={state.modelNumber}
            product={state.product}
            onEdit={() => {
              setState({type: "input", modelNumber: state.modelNumber})
            }}
            onClose={onClose}
          />
        )}
        {state.type === "unmatched" && (
          <Unmatched
            caseId={caseId}
            modelNumber={state.modelNumber}
            onEdit={() => {
              setState({type: "input", modelNumber: ""})
            }}
            onClose={onClose}
          />
        )}
      </Suspense>
    </BaseModal>
  )
}
