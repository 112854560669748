import {FC} from "react"
import {ReactSVG} from "react-svg"

import {
  aiBlueGradientBackground,
  aiRedGradientBackground,
  iconsColor,
} from "@frontend/design"
import star from "@frontend/design/icons/star.svg"
import {css, cx} from "@styled-system/css"

interface Props {
  size?: "small" | "large"
  variant?: "red" | "blue"
  className?: string
  starColor?: "white" | "darkBlue"
}

export const Logo: FC<Props> = ({
  size = "large",
  variant = "blue",
  className,
  starColor = "white",
}) => {
  const colorVariants = {
    blue: aiBlueGradientBackground(),
    red: aiRedGradientBackground(),
  }
  return (
    <div
      className={cx(
        css(
          {
            borderRadius: 8,
            padding: 4,
            width: 24,
            minWidth: 24,
            height: 24,
          },
          size === "large"
            ? {
                desktop: {
                  padding: 8,
                  width: 36,
                  minWidth: 36,
                  height: 36,
                },
              }
            : undefined,
        ),
        colorVariants[variant],
        className,
      )}
    >
      <ReactSVG
        src={star}
        className={cx(
          css(
            {
              width: "100%",
              height: "100%",
            },
            size === "large"
              ? {
                  desktop: {
                    width: 20,
                    height: 20,
                  },
                }
              : undefined,
          ),
          iconsColor({color: starColor}),
        )}
      />
    </div>
  )
}
