import {API_BASE_URL} from "@frontend/env"
import {useBooleanState} from "@frontend/utils/useBooleanState"
import {useEffectOnMount} from "@frontend/utils/useEffectOnMount"
import type {MeResponse} from "@ri2/app/app/api/me/route"

export const useIsSignedIn = (): {isLoading: boolean; isLoggedIn: boolean} => {
  const {state: isLoading, setFalse: setNotLoading} = useBooleanState(true)
  const {state: isLoggedIn, setTrue: setIsLoggedIn} = useBooleanState(false)

  const fetchIsSignedIn = async (): Promise<void> => {
    try {
      const response = await fetch(`${API_BASE_URL}/me`, {
        credentials: "include",
      })
      const {id}: MeResponse = await response.json()
      if (id) {
        setIsLoggedIn()
      }
      setNotLoading()
    } catch (error) {
      console.error("Error fetching is signed in", error)
    }
  }
  useEffectOnMount(() => {
    fetchIsSignedIn()
  })

  return {isLoading, isLoggedIn}
}
