import {shuffle, times} from "lodash"

import {Token} from "@styled-system/tokens"

const iconBackgroundColors: Token[] = [
  "colors.confirmedIconsBackground.100",
  "colors.confirmedIconsBackground.200",
  "colors.confirmedIconsBackground.300",
  "colors.confirmedIconsBackground.400",
  "colors.confirmedIconsBackground.500",
  "colors.confirmedIconsBackground.600",
  "colors.confirmedIconsBackground.700",
  "colors.confirmedIconsBackground.800",
  "colors.confirmedIconsBackground.900",
]

export const generateIconBackgrounds = (numberOfCauses: number): Token[] => {
  const backgroundSets = Math.ceil(numberOfCauses / iconBackgroundColors.length)
  const backgrounds: Token[] = []

  times(backgroundSets, () => {
    backgrounds.push(...shuffle(iconBackgroundColors))
  })

  return backgrounds
}
