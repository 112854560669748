import {FC} from "react"

import {css} from "@styled-system/css"

import {ResultsItem} from "./results-item"

export const Results: FC<{places: google.maps.places.PlaceResult[]}> = ({
  places,
}) => (
  <div
    className={css({
      maxHeight: {
        desktop: "90%",
      },
      overflowY: {
        desktop: "scroll",
      },
      width: "100%",
      "& > div": {
        borderBottomWidth: 1,
        borderBottomColor: "lineGrey",
        borderBottomStyle: "solid",
        paddingBottom: 24,
      },
      "& > div:last-child": {
        borderBottomWidth: 0,
      },
    })}
  >
    {places.map((place) => (
      <ResultsItem key={place.place_id} place={place} />
    ))}
  </div>
)
