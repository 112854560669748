import {FC, PropsWithChildren} from "react"

import {css} from "@styled-system/css"

export const DesktopAndLargeDesktop: FC<PropsWithChildren> = ({children}) => (
  <div
    className={css({
      display: {
        base: "none",
        desktop: "contents",
      },
    })}
  >
    {children}
  </div>
)

export const DesktopOnly: FC<PropsWithChildren> = ({children}) => (
  <div
    className={css({
      display: {
        base: "none",
        desktop: "contents",
        largeDesktop: "none",
      },
    })}
  >
    {children}
  </div>
)

export const MobileOnly: FC<PropsWithChildren> = ({children}) => (
  <div
    className={css({
      display: {
        base: "contents",
        desktop: "none",
      },
    })}
  >
    {children}
  </div>
)
