import {FC} from "react"
import {ReactSVG} from "react-svg"

import riLogo from "@frontend/design/icons/ri-icon.svg"
import {useTranslation} from "@frontend/i18n"
import {FeedbackRating} from "@ri2/db/client"
import {css} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {Reactions} from "../reactions"

interface EvaluationOverallProps {
  rating?: FeedbackRating
  onRate: (rating: FeedbackRating) => void
}

export const EvaluationOverall: FC<EvaluationOverallProps> = ({
  rating,
  onRate,
}) => {
  const t = useTranslation()

  return (
    <div
      className={hstack({
        borderRadius: 12,
        borderWidth: 1,
        borderColor: "lineGrey",
        padding: 16,
        backgroundColor: "white",
        textStyle: "body",
        color: "fontBlack",
        gap: 16,
      })}
    >
      <ReactSVG
        src={riLogo}
        className={css({
          "& svg": {
            width: 24,
            height: 24,
          },
        })}
      />
      <p
        className={css({
          flexGrow: 1,
        })}
      >
        {t("feedback.general.overallRating")}
      </p>
      <Reactions rating={rating} onRate={onRate} />
    </div>
  )
}
