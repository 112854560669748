import {Breadcrumb} from "@frontend/app-container/context"
import {makePath} from "@frontend/routing"

export const makeBreadcrumbs = ({
  caseId,
  caseName,
  queryParams,
}: {
  caseId: string
  caseName: string
  queryParams?: string
}): Breadcrumb[] => [
  {
    name: caseName,
    queryParams,
    path: makePath({
      name: "cases",
      caseId,
    }),
  },
]
