import {FC, useEffect, useState} from "react"
import {useLocation} from "react-router-dom"

interface Props {
  onChangeLocation: () => void
}

export const LocationObserver: FC<Props> = ({onChangeLocation}) => {
  const [hasRendered, setHasRendered] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (hasRendered) {
      onChangeLocation()
    }

    setHasRendered(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return null
}
