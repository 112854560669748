import {FC} from "react"
import {ReactSVG} from "react-svg"

import missingPartImage from "@frontend/design/icons/missing-part.svg"
import {cx} from "@styled-system/css"
import {hstack, iconsColor} from "@styled-system/patterns"
import {ColorToken, Token, token} from "@styled-system/tokens"

import {icons} from "./icons"

interface Props {
  color: Token
  iconColor: ColorToken
  className?: string
  iconName: string | null
}

export const CauseIcon: FC<Props> = ({
  color,
  className,
  iconName,
  iconColor,
}) => {
  const icon = iconName ? icons[iconName] || missingPartImage : missingPartImage
  return (
    <div
      className={cx(
        hstack({
          width: 56,
          height: 56,
          borderRadius: "50%",
          justifyContent: "center",
          alignItems: "center",
        }),
        className,
      )}
      style={{
        backgroundColor: token.var(color),
      }}
    >
      <ReactSVG src={icon} className={iconsColor({color: iconColor})} />
    </div>
  )
}
