import {FC} from "react"

import {css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {RecommendationBasedOn} from "./based-on"
import {Remediation} from "./remediation"
import {REMEDIATION_ORDER, RemediationProps} from "./types"

export const LargeDesktopRemediations: FC<RemediationProps> = ({
  getRemediation,
  causeOnMessageId,
  caseModelId,
  recommendedRemediationType,
  remediationHashId,
  recommendation,
  reasons,
  openModal,
}) => (
  <div
    className={vstack({
      backgroundColor: "background.secondary",
      flex: 1,
      gap: 0,
      alignItems: "stretch",
      paddingTop: 24,
      paddingBottom: 16,
      paddingX: 36,
      height: "100%",
      position: "relative",
      width: "100%",
      largeDesktopDown: {
        display: "none",
      },
    })}
  >
    <div
      className={hstack({
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        gap: 16,
        paddingX: 13,
        paddingTop: 24,
      })}
    >
      <div
        className={css({
          display: {
            base: "none",
            largeDesktop: "contents",
          },
        })}
      >
        {REMEDIATION_ORDER.map((key) => {
          const currentRemediation = getRemediation(key)

          if (!currentRemediation) {
            return null
          }

          const isRecommended = key === recommendedRemediationType

          return (
            <Remediation
              key={key}
              recommended={isRecommended}
              caseId={caseModelId}
              causeOnMessageId={causeOnMessageId}
              showActionButton
              className={cx(
                css({
                  flexGrow: 1,
                  flexShrink: 1,
                  flexBasis: 0,
                  maxWidth: 350,
                  height: "auto",
                }),
                isRecommended
                  ? css({
                      "@media (max-height: 800px)": {
                        height: 390,
                        maxHeight: 390,
                        minHeight: "auto",
                      },
                      minHeight: 532,
                    })
                  : css({
                      "@media (max-height: 800px)": {
                        height: 330,
                        maxHeight: 330,
                        minHeight: "auto",
                      },
                      minHeight: 470,
                    }),
              )}
              {...currentRemediation}
            />
          )
        })}
      </div>
    </div>
    <div
      className={hstack({
        justifyContent: "space-between",
      })}
    >
      <div />
      <RecommendationBasedOn
        reasons={reasons}
        recommendation={recommendation}
        remediationHashId={remediationHashId}
        openModal={openModal}
      />
    </div>
  </div>
)
