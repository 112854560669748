import {useNavigate} from "react-router-dom"

export const useHandleClick = ({
  onClick,
  external,
  navigateTo,
}: {
  onClick?: () => void
  navigateTo?: string
  external?: boolean
}): (() => void) => {
  const navigate = useNavigate()

  const handleClick = (): void => {
    if (navigateTo) {
      if (external) {
        window.open(navigateTo, "_blank")
      } else {
        navigate(navigateTo)
      }
    }

    onClick?.()
  }

  return handleClick
}
