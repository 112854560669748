import {TFunction} from "i18next"
import pluralize from "pluralize"
import {FC} from "react"

import {Tag} from "@frontend/components"
import shieldErrorLight from "@frontend/design/icons/shield-error-light.svg"
import shieldError from "@frontend/design/icons/shield-error.svg"
import shieldPositiveLight from "@frontend/design/icons/shield-positive-light.svg"
import shieldPositive from "@frontend/design/icons/shield-positive.svg"
import shieldWarningLight from "@frontend/design/icons/shield-warning-light.svg"
import shieldWarning from "@frontend/design/icons/shield-warning.svg"
import {useTranslation} from "@frontend/i18n"
import {Source} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"

type ColorScheme = "positive" | "warning" | "error"

type Mode = "default" | "part"

interface Props {
  source: Source
  mode?: Mode
  overrideColorScheme?: ColorScheme
  overrideTitle?: string
  className?: string
  transparent?: boolean
}

export const DataQualityTag: FC<Props> = ({
  source,
  mode = "default",
  overrideColorScheme = "positive",
  overrideTitle,
  className,
  transparent,
}) => {
  const t = useTranslation()

  const title = overrideTitle ?? getTitleFromSource(source, mode, t)

  const colorScheme =
    overrideColorScheme ?? COLOR_SCHEMES_FOR_SOURCE_TYPES[source.type]

  const icon = transparent
    ? ICONS_FOR_TRANSPARENT_COLOR_SCHEMES[colorScheme]
    : ICONS_FOR_COLOR_SCHEMES[colorScheme]

  return (
    <Tag
      title={title}
      icon={icon}
      colorScheme={colorScheme}
      transparent={transparent}
      className={cx(css({alignSelf: "flex-start"}), className)}
    />
  )
}

const getTitleFromSource = (
  source: Source,
  mode: Mode,
  t: TFunction,
): string => {
  switch (source.type) {
    case "match":
      return mode === "part"
        ? t("cause.diy.dataQuality.source.matchPart")
        : t("cause.diy.dataQuality.source.match")
    case "brand":
      return t("cause.diy.dataQuality.source.brand", {
        brandName: source.brandName,
        productType: pluralize(source.productType.toLowerCase()),
      })
    case "productTypeAndAdditionalDetails":
    case "productType":
      return t("cause.diy.dataQuality.source.productType")
  }
}

const COLOR_SCHEMES_FOR_SOURCE_TYPES: Record<Source["type"], ColorScheme> = {
  match: "positive",
  brand: "warning",
  productTypeAndAdditionalDetails: "error",
  productType: "error",
}

const ICONS_FOR_COLOR_SCHEMES: Record<ColorScheme, string> = {
  positive: shieldPositive,
  warning: shieldWarning,
  error: shieldError,
}

const ICONS_FOR_TRANSPARENT_COLOR_SCHEMES: Record<ColorScheme, string> = {
  positive: shieldPositiveLight,
  warning: shieldWarningLight,
  error: shieldErrorLight,
}
