import {FC} from "react"

import closeIcon from "@frontend/design/icons/close.svg"
import {useTranslation} from "@frontend/i18n"
import {Styles, css} from "@styled-system/css"

interface Props {
  onClick: () => void
  css?: Styles
}

export const CloseButton: FC<Props> = ({onClick, css: cssProp}) => {
  const t = useTranslation()

  return (
    <button
      onClick={onClick}
      className={css(
        {
          padding: 12,
          marginTop: -12,
          marginRight: -12,
        },
        cssProp,
      )}
    >
      <img src={closeIcon} alt={t("general.close")} />
    </button>
  )
}
