import {createContext} from "react"

interface SessionContextValue {
  userRcId: string
}

export const SessionContext = createContext<SessionContextValue>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  undefined as any,
)
