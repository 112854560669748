import {FC} from "react"
import {ReactSVG} from "react-svg"

import {useHandleClick} from "@frontend/components/ui/button/useHandleClick"
import {iconsColor} from "@frontend/design"
import download from "@frontend/design/icons/download.svg"
import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"

import {DarkButton} from "./DarkButton"

export const DownloadButton: FC<{imageUrl: string}> = ({imageUrl}) => {
  const t = useTranslation()
  const onClick = useHandleClick({
    external: true,
    navigateTo: imageUrl,
  })

  return (
    <DarkButton
      icon={
        <ReactSVG src={download} className={iconsColor({color: "white"})} />
      }
      onClick={onClick}
      title={t("conversation.imageGallery.downloadImage")}
      css={css.raw({
        width: "100%",
        justifyContent: "center",
        desktop: {
          width: "fit-content",
          justifyContent: "unset",
        },
      })}
    />
  )
}
