import {CaseTab} from "@frontend/routing"
import {
  CASE_MOBILE_TAB_KEY,
  usePersistedValue,
} from "@frontend/utils/usePersistedValue"

export const useLastConversationTab = (): {
  lastConversationTab: CaseTab
  setValue: (value: CaseTab) => void
} => {
  const {getValue, setValue} = usePersistedValue(CASE_MOBILE_TAB_KEY)

  const setValueAndPersist = (value: CaseTab): void => {
    setValue(value)
  }

  return {
    lastConversationTab: getValue() as CaseTab,
    setValue: setValueAndPersist,
  }
}
