import {FC, useCallback, useEffect, useRef, useState} from "react"
import {ReactSVG} from "react-svg"

import {IconButton} from "@frontend/components/ui/button"
import close from "@frontend/design/icons/close.svg"
import grayMapMarker from "@frontend/design/icons/gray-map-marker.svg"
import nearMe from "@frontend/design/icons/nearMe.svg"
import {useTranslation} from "@frontend/i18n"
import {useBooleanState} from "@frontend/utils/useBooleanState"
import {useCloseWhenClickOutsideOrEsc} from "@ri2/app/util/useCloseWhenClickOutsideOrEsc"
import {css, cx} from "@styled-system/css"
import {hstack, iconsColor} from "@styled-system/patterns"

const CLICK_OUTSIDE_CLASS = "place-item"

export const AddressInput: FC<{
  setInput: (zipCode: string) => void
  resetResults: () => void
}> = ({setInput, resetResults}) => {
  const t = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const [predictions, setPredictions] = useState<
    google.maps.places.QueryAutocompletePrediction[]
  >([])
  // const { setTrue: , } = useBooleanState(false)
  const [search, setSearchValue] = useState("")
  const {
    state: isDisplayingSuggestions,
    setTrue: displaySuggestions,
    setFalse: hideSuggestions,
  } = useBooleanState(false)

  useCloseWhenClickOutsideOrEsc(CLICK_OUTSIDE_CLASS, hideSuggestions)

  const displaySuggestionsCb = (
    predictions: google.maps.places.QueryAutocompletePrediction[] | null,
    status: google.maps.places.PlacesServiceStatus,
  ): void => {
    if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
      setPredictions([])
      return
    }

    setPredictions(predictions)
  }

  useEffect(() => {
    const service = new google.maps.places.AutocompleteService()

    service.getPlacePredictions(
      {
        input: search,
        componentRestrictions: {country: ["us", "ca"]},
      },
      displaySuggestionsCb,
    )
  }, [search, isDisplayingSuggestions])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.currentTarget.value)
    displaySuggestions()
  }

  const preventOutsideClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault()
    e.stopPropagation()
  }

  const resetInput = (): void => {
    setSearchValue("")
    resetResults()
    inputRef.current?.focus()
  }

  const onSuggestionClick = useCallback(
    (description: string): (() => void) =>
      (): void => {
        setInput(description)
        setSearchValue(description)
        hideSuggestions()
      },
    [hideSuggestions, setInput],
  )

  const onUseCurrentLocation = (): void => {
    // The reason of get the needed string this way is to avoid the
    // "Type instantiation is excessively deep and possibly infinite" error
    const value = t("cause.difm", {returnObjects: true}) as {
      usingCurrentLocation: string
    }
    setSearchValue(value.usingCurrentLocation)
    const geocoder = new google.maps.Geocoder()
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latlng = new google.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude,
        )
        geocoder.geocode({location: latlng}, (results, status) => {
          if (status === "OK") {
            setInput(results?.[0].formatted_address ?? "")
            setSearchValue(results?.[0].formatted_address ?? "")
            hideSuggestions()
          }
        })
      },
      () => {
        setSearchValue("")
      },
    )
  }

  return (
    <>
      <div
        className={css({
          borderColor: "lineGrey",
          borderWidth: 1,
          borderStyle: "solid",
          borderRadius: 8,
          paddingY: 8,
          paddingRight: 32,
          paddingLeft: 8,
          width: "100%",
          height: 40,
          backgroundColor: "white",
          zIndex: "mapSearchInputWrapper",
          position: "relative",
        })}
      >
        <input
          onChange={onChange}
          onClick={preventOutsideClick}
          placeholder={t("cause.difm.placeholder")}
          type="text"
          className={cx(
            css({outline: "none", border: "none", width: "100%", height: 24}),
            CLICK_OUTSIDE_CLASS,
          )}
          ref={inputRef}
          value={search}
          onFocus={displaySuggestions}
        />
        {search && (
          <IconButton
            variant="unstyled"
            icon={
              <ReactSVG
                src={close}
                className={cx(
                  css({
                    "& svg": {
                      width: 14,
                      height: 14,
                    },
                  }),
                  iconsColor({color: "black"}),
                )}
              />
            }
            ariaLabel=""
            onClick={resetInput}
            css={css.raw({
              position: "absolute",
              right: "10px",
              top: "7px",
              height: 24,
              width: 24,
              minWidth: 24,
              padding: 5,
            })}
          />
        )}
      </div>
      {isDisplayingSuggestions && (
        <div
          className={cx(
            css({
              zIndex: 1,
              position: "absolute",
              top: 0,
              height: "auto",
              paddingTop: 40,
              left: 16,
              right: 16,
              desktop: {
                left: 0,
                right: 44,
              },
              borderColor: "lineGrey",
              borderWidth: 1,
              borderStyle: "solid",
              borderRadius: 8,
              borderTop: "none",
              backgroundColor: "white",
              overflowX: "hidden",
            }),
            CLICK_OUTSIDE_CLASS,
          )}
        >
          {predictions?.map((prediction) => (
            <SuggestionItem
              key={prediction?.place_id}
              onClick={onSuggestionClick(prediction.description)}
              content={prediction.description}
            />
          ))}
          <SuggestionItem
            onClick={onUseCurrentLocation}
            content={t("cause.difm.nearMe")}
            icon={nearMe}
          />
        </div>
      )}
    </>
  )
}

const SuggestionItem: FC<{
  onClick: () => void
  content: string
  icon?: string
}> = ({onClick, content, icon}) => (
  <div
    onClick={onClick}
    className={cx(
      css({
        cursor: "pointer",
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "lineGrey",
        paddingY: 10,
        paddingLeft: 8,
        paddingRight: 16,
        width: "100%",
        _hover: {backgroundColor: "lightGrey"},
      }),
      hstack({gap: 8}),
    )}
  >
    <img src={icon ?? grayMapMarker} alt="" />
    <span
      className={css({
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        display: "block",
        color: icon ? "text.brand.accent" : "fontBlack",
      })}
    >
      {content}
    </span>
  </div>
)
