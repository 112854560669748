import {FC} from "react"

import {Tag} from "@frontend/components"
import {useTranslation} from "@frontend/i18n"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

export const Title: FC<{
  isRecommended: boolean
  title: string
  className?: string
}> = ({title, isRecommended, className}) => {
  const t = useTranslation()

  return (
    <div
      className={cx(
        hstack({
          gap: {
            base: 12,
            desktop: 16,
          },
        }),
        className,
      )}
    >
      <h2
        className={css({
          textStyle: "h4",
          color: "fontBlack",
          paddingLeft: {
            base: 16,
            largeDesktop: 0,
          },
        })}
      >
        {title}
      </h2>
      {isRecommended && <Tag title={t("cause.nextStep.recommended")} />}
    </div>
  )
}
