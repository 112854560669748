import {FC, PropsWithChildren} from "react"

import {cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

export const BaseLayout: FC<PropsWithChildren> = ({children}) => (
  <div
    className={cx(
      hstack({
        height: "100%",
        flex: 1,
        alignItems: "stretch",
        gap: 0,
        paddingRight: 0,
        overflowX: "hidden",
      }),
    )}
  >
    {children}
  </div>
)
