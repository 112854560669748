import {FC} from "react"
import {ReactSVG} from "react-svg"

import {Button} from "@frontend/components"
import arrowDown from "@frontend/design/icons/arrow-down-bold.svg"
import {useTranslation} from "@frontend/i18n"

export const NewMessageButton: FC<{
  scrollToBottom: () => void
}> = ({scrollToBottom}) => {
  const t = useTranslation()
  return (
    <Button
      variant="primary"
      size="large"
      onClick={scrollToBottom}
      title={t("conversation.viewNewResponses")}
      leftElement={<ReactSVG src={arrowDown} />}
    />
  )
}
