import * as Sentry from "@sentry/react"
import React, {useEffect} from "react"
import ReactDOM from "react-dom/client"
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"

import {App} from "./App.tsx"
import fontCss from "./design/fonts/fonts.css?inline"
import {LandingPage} from "./entry-app/index.tsx"
import {API_BASE_URL, SENTRY_ENVIRONMENT} from "./env.ts"

let rootContainer: HTMLDivElement | undefined
let styleElement: HTMLStyleElement | undefined
let root: ReactDOM.Root | undefined

export const open = (): void => {
  if (root) {
    return
  }

  if (!rootContainer) {
    rootContainer = document.createElement("div")

    rootContainer.id = "ri2-root"
    document.body.appendChild(rootContainer)
  }

  // In testing, font imports seeem to only work if they are in the
  // parent document, not inside the shadow DOM, so we have to add it
  // here instead of keeping it in <App /> with the rest of the
  // styles.
  if (!styleElement) {
    styleElement = document.createElement("style")
    styleElement.textContent = fontCss
    document.head.appendChild(styleElement)
  }

  // Prevent the host page scrolling while our modal is open
  document.body.style.top = `-${window.scrollY}px`
  document.body.style.position = "fixed"

  if (SENTRY_ENVIRONMENT !== "development") {
    Sentry.init({
      dsn: "https://73f798bd169776b6bc7bc39ae2f6496e@o4506792914321408.ingest.sentry.io/4506813849927680",
      environment: SENTRY_ENVIRONMENT,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0,
      tracePropagationTargets: [API_BASE_URL],
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      // Filtering
      beforeSend: (event, _hint) => {
        if (!event?.tags?.error_boundary) return null

        return event
      },
    })
  }

  root = ReactDOM.createRoot(rootContainer)
  root.render(
    <React.StrictMode>
      <App onExit={close} />
    </React.StrictMode>,
  )
}

export const close = (): void => {
  if (!root) {
    return
  }

  root.unmount()
  root = undefined

  // Re-enable scrolling of the host page
  const scrollY = document.body.style.top
  document.body.style.position = ""
  document.body.style.top = ""
  window.scrollTo(0, parseInt(scrollY ?? "0") * -1)

  Sentry.close()
}

const entryAppElement = document.getElementById("ri2-entry-app")

if (entryAppElement) {
  const landingPageRoot = ReactDOM.createRoot(entryAppElement)

  landingPageRoot.render(
    <React.StrictMode>
      <LandingPage open={open} />
    </React.StrictMode>,
  )
}
