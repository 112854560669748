import {FC} from "react"

import {useTranslation} from "@frontend/i18n"
import type {ResolveEvent as ResolveEventModel} from "@ri2/db/client"
import {css} from "@styled-system/css"

import {EventLayout} from "./event-layout"

interface Props {
  resolveEvent: ResolveEventModel
}

export const ResolveEvent: FC<Props> = ({
  resolveEvent: {resolved, createdAt},
}) => {
  const t = useTranslation()

  if (!resolved) {
    return null
  }

  return (
    <EventLayout isThinkingOrAI timestamp={createdAt}>
      <div
        className={css({
          width: "100%",
          display: "flex",
          minWidth: 0,
          flexDirection: "column",
          gap: 16,
          color: "fontBlack",
          textStyle: "h5",
          fontSize: {base: 18, desktop: 16},
        })}
      >
        {t("conversation.resolved", {date: createdAt})}
      </div>
    </EventLayout>
  )
}
