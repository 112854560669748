import {useEffect, useRef} from "react"
import {useLocation, useNavigate, useSearchParams} from "react-router-dom"
import {z} from "zod"

export const InitialCaseState = z.object({
  text: z.string(),
})

export type InitialCaseState = z.infer<typeof InitialCaseState>

export const useSendInitialMessageIfInNavigationState = (
  sendMessage: (text: string) => void,
): void => {
  const [params] = useSearchParams()
  const {pathname} = useLocation() // move this to search queries
  const navigate = useNavigate()

  const isInitialRender = useRef(true)

  const text = params.get("text")

  useEffect(() => {
    // Prevent this from running 2x because of React's strict mode
    if (!isInitialRender.current) return
    isInitialRender.current = false

    if (!text) return

    const initialCaseState = InitialCaseState.safeParse({text})

    if (initialCaseState.success === false) {
      console.error("Could not parse navigation state", {
        state: initialCaseState,
        error: initialCaseState.error,
      })
      return
    }

    sendMessage(initialCaseState.data.text)

    navigate(pathname, {state: undefined})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
