import {FC} from "react"

import {useSession} from "@frontend/session"
import {trpc} from "@frontend/utils/trpc"

import {SplashScreen} from "./splash-screen"

interface Props {
  onExit: () => void
}

export const BetaAccessBlock: FC<Props> = ({onExit}) => {
  const {userRcId} = useSession()
  const [hasRequestedBetaAccess] = trpc.hasRequestedBetaAccess.useSuspenseQuery(
    {
      userRcId,
    },
  )

  return (
    <>
      <SplashScreen
        onDismiss={onExit}
        hasRequestedBetaAccess={hasRequestedBetaAccess}
      />
    </>
  )
}
