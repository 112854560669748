import {FC, PropsWithChildren, useState} from "react"

import {trpc} from "@frontend/utils/trpc"
import {useEffectOnMount} from "@frontend/utils/useEffectOnMount"

import {SessionContext} from "./context"

const LOCAL_STORAGE_USER_ID_KEY = "userId"
export const LOCAL_STORAGE_HAS_DISMISSED_CAUSES_EXPERIMENTAL_DISCLAIMER_KEY =
  "hasDismissedCausesExperimentalDisclaimer"

export const SessionProvider: FC<PropsWithChildren> = ({children}) => {
  const [userRcId, setUserRcId] = useState<string | null>(null)
  const trpcUtils = trpc.useUtils()

  useEffectOnMount(() => {
    getUserRcId(trpcUtils).then(setUserRcId)
  })

  if (!userRcId) {
    return null
  }

  return (
    <SessionContext.Provider
      value={{
        userRcId,
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

const getUserRcId = async (
  trpcUtils: ReturnType<typeof trpc.useUtils>,
): Promise<string> => {
  const userRcIdFromHost = window.ri2!.getUserId!()
  const localStorageUserId = localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY)

  // If we have a user id from the host site AND a user id we
  // generated in localstorage, merge the two users, and use the user
  // id from the host site going forward.
  if (userRcIdFromHost && localStorageUserId) {
    await trpcUtils.client.mergeUser.mutate({
      fromRcId: localStorageUserId,
      toRcId: userRcIdFromHost,
    })

    localStorage.removeItem(LOCAL_STORAGE_USER_ID_KEY)

    return userRcIdFromHost
  }

  if (userRcIdFromHost) {
    return userRcIdFromHost
  }

  if (localStorageUserId) {
    return localStorageUserId
  }

  // If we don't have a user ID from the host, or saved in
  // localstorage, generate a new one and save it.
  const newUserId = crypto.randomUUID()
  localStorage.setItem(LOCAL_STORAGE_USER_ID_KEY, newUserId)

  return newUserId
}
