import {FC} from "react"
import {ReactSVG} from "react-svg"

import {useBreadcrumbs} from "@frontend/app-container/context"
import backgroundPattern from "@frontend/design/icons/background-pattern.svg"
import {useSendInitialMessageIfInNavigationState} from "@frontend/routes/util/initialCaseState"
import type {UiCauses} from "@ri2/app/server/routers/getCasePageData"
import {Case, CaseEvent, getCaseEvents, getCaseResolved} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {Conversation} from "./components/Conversation"
import {Causes} from "./components/causes"
import {MobileCaseLayout} from "./components/mobile-case-layout"
import {useDataFromParams} from "./params"
import {makeBreadcrumbs} from "./util/breadcrumbs"
import {useSendMessage} from "./util/useSendMessage"

export const CasePage: FC = () => {
  const {
    userRcId,
    casePageData: {caseModel, causes},
  } = useDataFromParams()

  useBreadcrumbs(
    makeBreadcrumbs({caseId: caseModel.id, caseName: caseModel.name}),
  )

  const events = getCaseEvents(caseModel)

  const {
    sendMessage,
    abortSendMessage,
    isReceivingMessage,
    receivingMessageStatus,
  } = useSendMessage(userRcId, caseModel)

  useSendInitialMessageIfInNavigationState((message: string): void => {
    setTimeout(() => {
      sendMessage(message)
    }, 0)
  })

  const causesCount = [...causes.confirmed, ...causes.moreAndVeryLikely].length

  return (
    <div
      className={css({
        desktop: {
          marginX: 8,
        },
        largeDesktop: {
          marginRight: 16,
        },
        width: "100%",
      })}
    >
      <div
        className={cx(
          hstack({
            alignItems: "stretch",
            gap: 0,
          }),
          css({
            height: "100%",
            minWidth: "100%",
            overflow: "hidden",
            transition: "margin-left 0.5s ease-in-out",
            desktopOnly: {
              maxWidth: "calc(100vw - 80px)",
            },
          }),
        )}
      >
        <DesktopPage
          caseModel={caseModel}
          events={events}
          sendMessage={sendMessage}
          abortSendMessage={abortSendMessage}
          isReceivingMessage={isReceivingMessage}
          causes={causes}
          receivingMessageStatus={receivingMessageStatus}
          title={caseModel.name}
          causesCount={causesCount}
        />
        <MobilePage
          caseModel={caseModel}
          events={events}
          sendMessage={sendMessage}
          abortSendMessage={abortSendMessage}
          isReceivingMessage={isReceivingMessage}
          causes={causes}
          receivingMessageStatus={receivingMessageStatus}
          title={caseModel.name}
          causesCount={causesCount}
        />
      </div>
    </div>
  )
}

interface Props {
  caseModel: Case
  events: CaseEvent[]
  sendMessage: (message: string) => void
  abortSendMessage: () => void
  isReceivingMessage: boolean
  causes: UiCauses
  receivingMessageStatus: string | null
  title: string
  causesCount: number
}

const DesktopPage: FC<Props> = ({
  caseModel,
  events,
  sendMessage,
  abortSendMessage,
  isReceivingMessage,
  causes,
  receivingMessageStatus,
  title,
}) => (
  <div
    className={css({
      display: {
        base: "none",
        desktop: "contents",
      },
    })}
  >
    <Conversation
      caseId={caseModel.id}
      resolved={getCaseResolved(caseModel)}
      events={events}
      sendMessage={sendMessage}
      abortSendMessage={abortSendMessage}
      isReceivingMessage={isReceivingMessage}
      receivingMessageStatus={receivingMessageStatus}
      title={title}
      className={css({
        backgroundColor: "background.brand.primary",
        borderTopLeftRadius: 17,
        desktop: {
          gridColumnStart: 1,
          gridColumnEnd: 7,
        },
        largeDesktop: {
          gridColumnStart: 1,
          gridColumnEnd: 8,
        },
      })}
    />
    <div
      className={css({
        padding: {
          desktop: "0px 0px 0px 20px",
          largeDesktop: "0px 7px 0px 24px",
        },
        width: "max(35%, 350px)",
        backgroundColor: "background.secondary",
        borderTopRightRadius: 17,
        position: "relative",
        overflowX: "visible",

        desktop: {
          gridColumnStart: 7,
          gridColumnEnd: 8,
        },

        largeDesktop: {
          gridColumnStart: 8,
          gridColumnEnd: 13,
        },
      })}
    >
      <div
        className={css({
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: "100%",
          borderTopRightRadius: 17,
          overflow: "hidden",
          "& svg": {
            width: "747px",
            height: "540px",
          },
        })}
      >
        <ReactSVG src={backgroundPattern} />
      </div>
      <div
        className={css({
          position: "relative",
          zIndex: "causes.container",
        })}
      >
        <Causes caseModel={caseModel} causes={causes} />
      </div>
    </div>
  </div>
)

const MobilePage: FC<Props> = ({
  caseModel,
  events,
  sendMessage,
  abortSendMessage,
  isReceivingMessage,
  receivingMessageStatus,
  causes,
  title,
  causesCount,
}) => (
  <MobileCaseLayout
    causesCount={causesCount}
    conversation={
      <Conversation
        caseId={caseModel.id}
        resolved={getCaseResolved(caseModel)}
        events={events}
        sendMessage={sendMessage}
        abortSendMessage={abortSendMessage}
        isReceivingMessage={isReceivingMessage}
        receivingMessageStatus={receivingMessageStatus}
        title={title}
        className={css({
          flex: 1,
        })}
      />
    }
    causesList={
      <Causes
        caseModel={caseModel}
        causes={causes}
        className={css({
          flex: 1,
          paddingBottom: 16,
        })}
      />
    }
  />
)
