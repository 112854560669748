import {FC, PropsWithChildren} from "react"
import {Outlet} from "react-router-dom"

import {css} from "@styled-system/css"

export const RootLayoutWithOutlet: FC = () => (
  <RootLayout>
    <Outlet />
  </RootLayout>
)

export const RootLayout: FC<PropsWithChildren> = ({children}) => (
  <div
    className={css({
      height: "100%",
      display: "flex",
      flexDirection: "column",
    })}
  >
    {children}
  </div>
)
