import {FC} from "react"
import {ReactSVG} from "react-svg"

import diy from "@frontend/entry-app/assets/images/diy.svg"
import pattern from "@frontend/entry-app/assets/patterns/landing-page-diy-steps-pattern.svg"
import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

export const StepsSection: FC = () => {
  const t = useTranslation()
  return (
    <div
      className={vstack({
        width: "100%",
        height: "auto",
        backgroundColor: "token(colors.copperTaupe.500)",
        paddingY: {
          base: 64,
          desktop: 110,
        },
        position: "relative",
        overflow: "hidden",
      })}
    >
      <div
        className={hstack({
          position: "absolute",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          zIndex: "bgPattern.base",
          gap: "10%",
        })}
      >
        <ReactSVG
          src={pattern}
          className={css({
            flexGrow: 1,
            transform: "scale(1.2)",
          })}
        />
        <ReactSVG
          src={pattern}
          className={css({
            flexGrow: 1,
            transform: "scale(1.2)",
          })}
        />
        <ReactSVG
          src={pattern}
          className={css({
            flexGrow: 1,
            transform: "scale(1.2)",
          })}
        />
      </div>
      <p
        className={css({
          maxWidth: 790,
          textStyle: "header.m",
          color: "text.primary.onSolid",
          textAlign: {
            base: "left",
            desktop: "center",
          },
          paddingBottom: 49,
          position: "relative",
          zIndex: "bgPattern.middle",
          paddingX: 32,
          desktopDown: {
            textStyle: "header.s",
          },
        })}
      >
        {t("entryApp.landingPage.stepsSectionTitle")}
      </p>
      <ReactSVG
        src={diy}
        className={css({
          position: "relative",
          zIndex: "bgPattern.middle",
          paddingX: 32,
          width: "100%",
          maxWidth: 907,
          "& svg": {
            width: "100%",
            height: "auto",
          },
        })}
      />
    </div>
  )
}
