import {z} from "zod"

import {useSession} from "@frontend/session"
import {useSuspenseQueries} from "@frontend/utils/trpc"
import {useStabilizedValue} from "@frontend/utils/useStabilizedValue"
import {useTypedParams} from "@frontend/utils/useTypedParams"
import type {CasePageData} from "@ri2/app/server/routers/getCasePageData"

export const CaseIdParams = z.object({
  caseId: z.string(),
})

export const useDataFromParams = (): {
  userRcId: string
  casePageData: CasePageData
} => {
  const {caseId} = useTypedParams(CaseIdParams)

  const {userRcId} = useSession()

  const [casePageData] = useSuspenseQueries((trpc) => [
    trpc.casePageData({id: caseId}),
  ])

  return useStabilizedValue({
    userRcId,
    casePageData,
  })
}
