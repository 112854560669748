import * as Sentry from "@sentry/react"
import {QueryClient, QueryClientProvider} from "@tanstack/react-query"
import {TRPCClientError, httpBatchLink} from "@trpc/react-query"
import {FC, useEffect, useState} from "react"
import {HashRouter} from "react-router-dom"
import superjson from "superjson"

import {Router} from "./Router"
import {AppContainer} from "./app-container"
import {BetaAccessBlock} from "./components/beta-access-block"
import {GenericErrorBoundary} from "./components/error/generic-error-boundary"
import {NotFoundErrorBoundary} from "./components/error/not-found-error-boundary"
import {API_BASE_URL} from "./env"
import {RootLayout} from "./routes/layout"
import {LandingPage} from "./routes/page"
import {SessionProvider} from "./session/provider"
import {trpc, useHasBetaAccess} from "./utils/trpc"

const QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      refetchOnWindowFocus: false,
      retry: (failureCount, error): boolean => {
        if (!(error instanceof TRPCClientError)) {
          return false
        }

        if (error.data?.code === "NOT_FOUND") {
          return false
        }

        return failureCount < 3
      },
    },
  },
})

const TRPC_CLIENT = trpc.createClient({
  links: [
    httpBatchLink({
      url: `${API_BASE_URL}/trpc`,
    }),
  ],
  transformer: superjson,
})

const getIsInitialized = (): boolean => !!window.ri2?.getUserId

const useIsInitialized = (): boolean => {
  const [isInitialized, setIsInitialized] = useState(getIsInitialized())

  useEffect(() => {
    if (isInitialized) return

    const interval = setInterval(() => {
      if (getIsInitialized()) {
        setIsInitialized(true)
        clearInterval(interval)
      }
    }, 100)

    return () => {
      clearInterval(interval)
    }
  })

  return isInitialized
}

interface Props {
  onExit: () => void
}

export const App: FC<Props> = ({onExit: onExitApp}) => {
  const isInitialized = useIsInitialized()

  if (!isInitialized) {
    return null
  }

  const onExit = (): void => {
    onExitApp()
    const newUrl = window.location.href.split("#")[0]
    window.history.pushState(null, "", newUrl)
  }

  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTags({error_boundary: "root"})
      }}
    >
      <trpc.Provider client={TRPC_CLIENT} queryClient={QUERY_CLIENT}>
        <QueryClientProvider client={QUERY_CLIENT}>
          <HashRouter>
            <SessionProvider>
              <AppContainer onExit={onExit}>
                <GenericErrorBoundary>
                  <NotFoundErrorBoundary>
                    <ContentOrBetaAccessBlock onExit={onExit} />
                  </NotFoundErrorBoundary>
                </GenericErrorBoundary>
              </AppContainer>
            </SessionProvider>
          </HashRouter>
        </QueryClientProvider>
      </trpc.Provider>
    </Sentry.ErrorBoundary>
  )
}

interface ContentOrBetaAccessBlockProps {
  onExit: () => void
}

const ContentOrBetaAccessBlock: FC<ContentOrBetaAccessBlockProps> = ({
  onExit,
}) => {
  const hasBetaAccess = useHasBetaAccess()

  switch (hasBetaAccess) {
    case "pending":
      return null
    case "hasAccess":
      return <Router />
    case "noAccess":
      return (
        <RootLayout>
          <LandingPage />
          <BetaAccessBlock onExit={onExit} />
        </RootLayout>
      )
  }
}
