import {useSuspenseQueries} from "@frontend/utils/trpc"
import {useStabilizedValue} from "@frontend/utils/useStabilizedValue"
import {useTypedParams} from "@frontend/utils/useTypedParams"
import type {
  EnrichedProductInfo,
  RemediationWithHashIdAndFeedback,
} from "@ri2/db/client"

import {CauseIdParams} from "../params"

export const useDataFromParams = (): {
  causeOnMessageId: number
  caseId: string
  remediation: RemediationWithHashIdAndFeedback
  enrichedProductInfo: EnrichedProductInfo
} => {
  const {causeOnMessageId, caseId} = useTypedParams(CauseIdParams)

  const [{remediation, enrichedProductInfo}] = useSuspenseQueries((trpc) => [
    trpc.remediationPageData({
      causeOnMessageId,
    }),
  ])

  return useStabilizedValue({
    causeOnMessageId,
    caseId,
    remediation,
    enrichedProductInfo,
  })
}
