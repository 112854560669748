import { getPatternStyles, patternFns } from '../helpers.mjs';
import { css } from '../css/index.mjs';

const iconsColorConfig = {
transform:(props) => {
  const { color, ...rest } = props;
  return {
    "& [stroke]": {
      stroke: color
    },
    "& [fill] [fill]:not([fill='none'])": {
      fill: color
    },
    ...rest
  };
}}

export const getIconsColorStyle = (styles = {}) => {
  const _styles = getPatternStyles(iconsColorConfig, styles)
  return iconsColorConfig.transform(_styles, patternFns)
}

export const iconsColor = (styles) => css(getIconsColorStyle(styles))
iconsColor.raw = getIconsColorStyle