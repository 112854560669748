import {FC, useRef} from "react"

import {useEffectOnMount} from "@frontend/utils/useEffectOnMount"
import {css, cx} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {RecommendationBasedOn} from "./based-on"
import {Remediation} from "./remediation"
import {REMEDIATION_ORDER, RemediationProps} from "./types"

const RECOMMENDED_CLASS = "recommended"

export const DesktopRemediations: FC<RemediationProps> = ({
  getRemediation,
  recommendedRemediationType,
  caseModelId,
  causeOnMessageId,
  reasons,
  recommendation,
  remediationHashId,
  openModal,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffectOnMount(() => {
    if (scrollRef.current) {
      const recommendedRemediation = scrollRef.current?.querySelector(
        `.${RECOMMENDED_CLASS}`,
      )

      recommendedRemediation?.scrollIntoView({
        behavior: "instant",
        block: "start",
        inline: "center",
      })
    }
  })

  return (
    <div
      className={css({
        display: {
          base: "none",
          desktop: "flex",
          largeDesktop: "none",
        },
        flexDirection: "column",
        paddingTop: 20,
        maxWidth: "calc(100vw - 100px)",
        height: "100%",
      })}
    >
      <div
        className={hstack({
          overflowX: "auto",
          flexWrap: "nowrap",
          flexShrink: 0,
          paddingBottom: 36,
          paddingX: 36,
          paddingTop: 16,
          gap: 16,
          desktopDown: {
            flexDirection: "column",
          },
          "& > div": {
            width: "33%",
            minWidth: 265,
          },
        })}
        ref={scrollRef}
      >
        {REMEDIATION_ORDER.map((key) => {
          const currentRemediation = getRemediation(key)

          if (!currentRemediation) {
            return null
          }

          const isRecommended = key === recommendedRemediationType

          return (
            <Remediation
              key={key}
              recommended={isRecommended}
              caseId={caseModelId}
              causeOnMessageId={causeOnMessageId}
              showActionButton
              className={cx(
                css(
                  {
                    gridColumn: "span 3",
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: 0,
                    height: "auto",
                    minHeight: 470,
                  },
                  isRecommended && {minHeight: 532},
                ),
                isRecommended && RECOMMENDED_CLASS,
              )}
              {...currentRemediation}
            />
          )
        })}
      </div>
      <div
        className={css({
          marginTop: "auto",
          paddingX: 36,
          paddingBottom: 8,
        })}
      >
        <RecommendationBasedOn
          reasons={reasons}
          recommendation={recommendation}
          remediationHashId={remediationHashId}
          openModal={openModal}
        />
      </div>
    </div>
  )
}
