export const findMap = <TOriginal, TMapped>(
  elements: TOriginal[],
  f: (element: TOriginal) => TMapped | undefined,
): TMapped | undefined => {
  for (const element of elements) {
    const result = f(element)
    if (result !== undefined) {
      return result
    }
  }
  return undefined
}

export const findLastMap = <TOriginal, TMapped>(
  elements: TOriginal[],
  f: (element: TOriginal) => TMapped | undefined,
): TMapped | undefined => {
  for (let i = elements.length - 1; i >= 0; i--) {
    const element = elements[i]
    const result = f(element)
    if (result !== undefined) {
      return result
    }
  }
  return undefined
}
