import {i18n} from "i18next"

import {CalendarDate, Cents, dateFromCalendarDate} from "@ri2/db/client"

export const addFormatters = (i18next: i18n): void => {
  i18next.services.formatter?.add(
    "calendarDate",
    (value: CalendarDate, language, options): string =>
      new Intl.DateTimeFormat(language, options).format(
        dateFromCalendarDate(value),
      ),
  )

  i18next.services.formatter?.add(
    "usd",
    (value: Cents, language, options = {}): string =>
      new Intl.NumberFormat(language, {
        ...options,
        style: "currency",
        currency: "USD",
      }).format(value / 100),
  )
}
