import {FC} from "react"

import {cva} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

const toggleButtonStyle = cva({
  base: {
    display: "flex",
    alignItems: "center",
    gap: 4,
    padding: "3px 8px 3px 8px",
    borderRadius: 8,
    textStyle: "captionMedium",
    cursor: "pointer",
  },
  variants: {
    selected: {
      true: {
        backgroundColor: "background.primary",

        color: "text.primary",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "border.secondary.200",
        borderRadius: "999px",
        "& span": {
          color: "text.secondary",
          backgroundColor: "background.tertiary",
        },
      },
      false: {
        borderWidth: 1,
        borderColor: "transparent",
        color: "text.tertiary",
        "& span": {
          color: "text.tertiary",
          backgroundColor: "background.quaternary",
        },
      },
    },
  },
})

export const ToggleButton: FC<{
  selected: boolean
  title: string
  onSelected: () => void
  count: number
}> = ({selected, title, onSelected, count}) => (
  <button
    className={toggleButtonStyle({
      selected,
    })}
    onClick={onSelected}
  >
    {title}
    <span
      className={hstack({
        borderRadius: 4,
        height: 20,
        padding: 2,
        textStyle: "captionMedium",
        justifyContent: "center",
        alignItems: "center",
      })}
    >
      {count}
    </span>
  </button>
)
