import {FC} from "react"

import {useBreadcrumbs} from "@frontend/app-container/context"
import {useTranslation} from "@frontend/i18n"
import {RemediationType} from "@ri2/db/client"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {Remediations} from "./components/remediations"
import {RemediationTypeProps} from "./components/remediations/remediation"
import {Sidebar} from "./components/sidebar"
import {useDataFromParams} from "./params"
import {makeBreadcrumbs} from "./util/breadcrumbs"
import {CauseIcon} from "../../components/causes/cause-icon"
import {useLastConversationTab} from "../../util/useLastConversationTab"

const CausePage: FC = () => {
  const {
    caseId,
    causeOnMessageId,
    remediationPageData: {caseName, remediation},
  } = useDataFromParams()

  const {lastConversationTab} = useLastConversationTab()
  const queryParams = new URLSearchParams({tab: lastConversationTab}).toString()

  const t = useTranslation()

  useBreadcrumbs(
    makeBreadcrumbs({
      queryParams,
      caseId,
      caseName,
      cause: {
        id: causeOnMessageId,
        name: remediation.cause.name,
      },
      t,
    }),
  )

  const recommendation = remediation.feedback.RECOMMENDATION
  const remediationHashId = remediation.hashId

  const getRemediation = (
    key: RemediationType,
  ): RemediationTypeProps | undefined => {
    if (key === "diyRemediation") {
      return {
        type: "doItYourself",
        remediation,
      }
    }
    if (key === "difmRemediation") {
      return {
        type: "doItForMe",
        remediation,
      }
    }
    if (key === "replaceRemediation") {
      return {
        type: "replace",
        remediation,
      }
    }
  }

  return (
    <div
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        largeDesktopOnly: {
          display: "contents",
        },
        desktopOnly: {
          margin: 16,
          boxSizing: "content-box",
        },
      })}
    >
      <div
        className={hstack({
          alignItems: "stretch",
          gap: 0,
          width: "100%",
          overflow: "hidden",
          marginBottom: 16,
          borderRadius: {
            base: 0,
            desktop: 16,
          },
          largeDesktopDown: {
            flexDirection: "column",
            overflow: "auto",
            height: "100%",
            flex: 1,
            backgroundColor: "#F5F5F5",
            marginBottom: 0,
            paddingBottom: 16,
          },
          largeDesktopOnly: {
            marginRight: 16,
            marginTop: 6,
          },
        })}
      >
        <div
          className={css({
            minHeight: "8px",
            width: "100%",
            backgroundColor: "background.primary",
            display: {
              base: "block",
              desktop: "none",
            },
          })}
        />
        <Sidebar
          className={css({
            width: "100%",
            maxWidth: "100%",
            largeDesktop: {
              maxWidth: "333px",
            },
            overflowY: "auto",
            minHeight: {
              base: "fit-content",
              largeDesktop: "100%",
            },
          })}
        >
          <div
            className={vstack({
              alignItems: "stretch",
              gap: "16px",
              color: "white",
            })}
          >
            <CauseIcon
              color="colors.background.brand.solidSubtle"
              className={css({flexShrink: 0, flexGrow: 0})}
              iconName={remediation.cause.iconName}
              iconColor="foreground.primaryOnSolid"
            />
            <h2
              className={css({
                textStyle: "header.s",
              })}
            >
              {remediation.cause.name}
            </h2>
            <p
              className={css({
                textStyle: "bodyLargeLight",
                color: "text.secondary.onSolid",
              })}
            >
              {remediation.cause.description}
            </p>
          </div>
        </Sidebar>
        <Remediations
          getRemediation={getRemediation}
          causeOnMessageId={causeOnMessageId}
          caseModelId={caseId}
          recommendedRemediationType={remediation.recommendation.type}
          recommendation={recommendation}
          remediationHashId={remediationHashId}
          reasons={remediation.recommendation.reasons}
          remediationType={remediation.recommendation.type}
        />
      </div>
    </div>
  )
}

export default CausePage
