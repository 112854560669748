import {FC} from "react"
import {Trans} from "react-i18next"
import {ReactSVG} from "react-svg"

import likelyCauses from "@frontend/entry-app/assets/images/likely-causes.svg"
import potentialCauses from "@frontend/entry-app/assets/images/potentials-causes.svg"
import recommend from "@frontend/entry-app/assets/images/recommend.svg"
import {useTranslation} from "@frontend/i18n"
import {Styles, css, cx} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

export const HowItWorks: FC = () => (
  <div
    className={css({
      width: "100%",
    })}
  >
    <Step number="01" image={potentialCauses} />

    <Step number="02" image={likelyCauses} />

    <Step number="03" image={recommend} />
  </div>
)

const Step: FC<{
  number: StepNumbers
  image: string
}> = ({image, number}) => (
  <div
    className={hstack({
      width: "100%",
      paddingTop: {
        base: 64,
        largeDesktop: 127,
      },
      paddingBottom: 195,
      justifyContent: "flex-end",
      gap: 82,
      paddingLeft: 32,
      largeDesktopDown: {
        paddingX: 32,
        flexDirection: "column-reverse",
      },
      desktopDown: {
        gap: 24,
      },
    })}
  >
    <StepInfo number={number} />
    <ReactSVG
      src={image}
      className={css({
        flexShrink: 1,
        width: "100%",
        minWidth: "50vw",
        maxWidth: 865,
        "& svg": {
          width: "100%",
          maxWidth: 865,
          height: "auto",
        },
      })}
    />
  </div>
)

type StepNumbers = "01" | "02" | "03"

const StepInfo: FC<{number: StepNumbers}> = ({number}) => {
  const t = useTranslation()

  const stepStyles: {
    [K in StepNumbers]: {
      headerStyles: Styles
      descriptionStyles: Styles
    }
  } = {
    "01": {
      headerStyles: css.raw({
        _before: {
          height: {
            base: 200,
            largeDesktop: 249,
          },
        },
      }),
      descriptionStyles: css.raw({
        marginTop: {
          base: 80,
          largeDesktop: 130,
        },
      }),
    },
    "02": {
      headerStyles: css.raw({
        paddingRight: 42,
        alignItems: "flex-start",
        _before: {
          height: {
            base: 300,
            largeDesktop: 330,
          },
        },
      }),
      descriptionStyles: css.raw({
        marginTop: {
          base: 80,
          largeDesktop: 66,
        },
      }),
    },
    "03": {
      headerStyles: css.raw({
        _before: {
          height: {
            base: 247,
            largeDesktop: 302,
          },
        },
      }),
      descriptionStyles: css.raw({
        marginTop: {
          base: 80,
          largeDesktop: 157,
        },
      }),
    },
  }

  const styles = stepStyles[number]

  return (
    <div
      className={cx(
        vstack({
          width: "min(416px, 100%)",
          flexShrink: 0,
          gap: 0,
          alignItems: "flex-start",
          position: "relative",
          _before: {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: -1,
            width: 1,
            backgroundColor: "background.brand.accent",
          },
        }),
        css(styles.headerStyles),
      )}
    >
      <span
        className={css({
          textStyle: "header.m",
          color: "text.brand.accent",
        })}
      >
        {number}
      </span>
      <p className={css({marginTop: 20, textStyle: "header.m"})}>
        <Trans
          t={t}
          i18nKey={`entryApp.landingPage.steps.${number}.title` as const}
        >
          <span
            className={css({
              color: "text.primary.onSolid",
              backgroundColor: "background.brand.accent",
            })}
          />
        </Trans>
      </p>
      <p
        className={css(
          {
            paddingLeft: 12,
            textStyle: "bodyLight",
            color: "text.brand.accent",
            maxWidth: 300,
          },
          styles.descriptionStyles,
        )}
      >
        {t(`entryApp.landingPage.steps.${number}.description`)}
      </p>
    </div>
  )
}
